<template>
  <Base :errorMessage="errorMessage" :successMessage="successMessage" @resultSuccess="resultSuccess" >
    <section class="admin-coaches-new">
      <div class="header">
        <h1>スタッフアカウント登録</h1>
      </div>
      <form @submit.prevent="saveCoach">
        <CoachInput :coach="coach" :editMode="false" @changeCoach="changeCoach"/>
        <div class="button-block">
          <button type="submit" class="approval-button" >登録</button>
        </div>
      </form>
    </section>
  </Base>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import CoachInput from '@/components/admin/coaches/InputComponent.vue';
import {Coach} from "@/domains/coach/Coach";
import {ResponseInterface} from "@/http_clients/ResponseInterface";

export default defineComponent({
  name: 'CoachNew',
  components: {
    Base,
    CoachInput,
  },
  data() {
    return {
      errorMessage: null as string | null,
      successMessage: null as string | null,
      coach: new Coach(null, null, null, null, null, null, null, null, null) as Coach,
    }
  },
  methods: {
    changeCoach(coach: Coach) {
      this.coach = coach;
    },
    async saveCoach() {
      const response: ResponseInterface = await this.coach.insert();
      if (response.result) {
        this.successMessage = "登録を完了しました。";
      } else {
        if (response.status == 422) {
          this.errorMessage = "ユーザーIDは既に使用されています。";
        } else {
          this.errorMessage = "スタッフの登録処理に失敗しました。";
        }
      }
    },
    resultSuccess() {
      this.$router.push('/admin/coaches');
    },
  }
});
</script>

<style lang="scss" scoped>
.admin-coaches-new {
  max-width: 600px;
}

.button-block {
  margin-top: 60px;

  display: flex;
  justify-content: center;
}
</style>
