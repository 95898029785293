<template>
  <Base >
    <section class="training-menu-index">
      <div class="header">
        <h1>トレーニングメニュー一覧</h1>
        <div class="button-block">
          <router-link class="new-button" :to="{ name: 'AdminTrainingMenuNew'}">新規登録</router-link>
        </div>
      </div>

      <div class="list-block" v-if="trainingMenus.isPresent()">
        <table>
          <thead>
          <tr>
            <th>
              フロア
            </th>
            <th>
              トレーニングメニュー
            </th>
            <th>
              トレーニング時間
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="trainingMenu in trainingMenus.list" :key="trainingMenu.id.getId()">
            <td>
              {{ trainingMenu.floorGroupName }}
            </td>
            <td>
              <router-link :to="{ name: 'AdminTrainingMenuEdit', params: {trainingMenuId: trainingMenu.id.getId()}}">
                {{ trainingMenu.name }}
              </router-link>
            </td>
            <td>
              {{ trainingMenu.trainingMinutes }}分
            </td>
          </tr>
          </tbody>
        </table>
        <Pager :totalPage="trainingMenus.totalPage" :currentPage="trainingMenus.currentPage" @selectPage="changePage" class="pager"></Pager>
      </div>
      <div v-else>
        データが登録されていません。
      </div>
    </section>
  </Base>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import Pager from "@/components/common/PagerComponent.vue";
import TrainingMenusClient from "@/http_clients/admin/TrainingMenusClient";
import {TrainingMenus} from "@/domains/training_menu/TrainingMenus";

export default defineComponent({
  name: 'TrainingMenuIndex',
  components: {
    Base,
    Pager
  },
  data() {
    return {
      trainingMenus: new TrainingMenus([]) as TrainingMenus,
    }
  },
  watch: {
    async $route() {
      let page = null;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page as string);
      }
      const trainingMenusClient = new TrainingMenusClient();
      this.trainingMenus = await trainingMenusClient.getList(page);
    }
  },
  async created() {
    let page = null;
    if (this.$route.query.page) {
      page = parseInt(this.$route.query.page as string);
    }
    const trainingMenusClient = new TrainingMenusClient();
    this.trainingMenus = await trainingMenusClient.getList(page);
  },
  methods: {
    async changePage(page: number) {
      this.$router.push({query: { page: page } });
    }
  }
});
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.button-block {
  display: flex;
  align-items: flex-end;
}

.new-button {
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 40px;
  text-decoration: none;
  color: #000000;
}

.pager {
  width: 100%;
  margin-top: 20px;
}
</style>
