<template>
  <Base :loading="loading" :errorMessage="errorMessage" :confirmMessage="confirmMessage" :successMessage="successMessage"
        @resultConfirm="resultConfirm" @resultSuccess="resultSuccess" >
    <section class="index">
      <div v-for="lessonReservation in lessonReservations.list" :key="lessonReservation.id.getId()" class="lesson-reservation-card"
           @click="clickLessonReservation(lessonReservation)">
        <div class="lesson-schedule">
          {{lessonReservation.displayStartDate()}}<br />
          {{lessonReservation.startTime}}～{{lessonReservation.endTime}}
        </div>
        <div class="lesson-data">
          <span class="label">フロア</span>：
          <span>{{lessonReservation.floorName}}</span>
        </div>
        <div class="lesson-data">
          <span class="label">スタッフ</span>：
          <span>{{lessonReservation.coachName}}</span>
        </div>
        <div class="lesson-data">
          <span class="label">メニュー</span>：
          <span>
            {{lessonReservation.trainingMenu1Name}}<br />
            {{lessonReservation.trainingMenu2Name}}
          </span>
        </div>
      </div>
      <div class="button-block">
        <router-link :to="{ name: 'LessonReservationIndex'}">予約を追加する</router-link>
      </div>
    </section>

    <div class="lesson-reservation-background" v-if="selectLessonReservation != null" @click="cancel()">
      <div class="lesson-reservation-dialog" @click="clickNothing">
        <div class="lesson-schedule">
          {{selectLessonReservation.displayStartDate()}}<br />
          {{selectLessonReservation.startTime}}～{{selectLessonReservation.endTime}}
        </div>
        <div class="lesson-data">
          <span class="label">フロア</span>：
          <span>{{selectLessonReservation.floorName}}</span>
        </div>
        <div class="lesson-data">
          <span class="label">スタッフ</span>：
          <span>{{selectLessonReservation.coachName}}</span>
        </div>
        <div class="lesson-data">
          <span class="label">メニュー</span>：
          <span>
            {{selectLessonReservation.trainingMenu1Name}}<br />
            {{selectLessonReservation.trainingMenu2Name}}
          </span>
        </div>
        <div class="lesson-data">
          <span class="label">備考</span>：
        </div>
        <div class="lesson-textarea-data" v-if="selectLessonReservation.note != null">
          {{selectLessonReservation.note}}
        </div>
        <div class="dialog-button-block">
          <button type="button" @click="editLessonReservation()" v-if="selectLessonReservation.checkStudentEditReservation()" >編集</button>
          <button type="button" @click="cancel()" >閉じる</button>
        </div>
      </div>
    </div>
  </Base>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Base from '@/components/common/BaseComponent.vue';
import LessonReservationsClient from "@/http_clients/LessonReservationsClient";
import {LessonReservations} from "@/domains/lesson_reservation/LessonReservations";
import {LessonReservation} from "@/domains/lesson_reservation/LessonReservation";
import {ResponseInterface} from "@/http_clients/ResponseInterface";

export default defineComponent({
  name: 'Index',
  components: {
    Base,
  },
  data() {
    return {
      loading: false as boolean,
      lessonReservations: new LessonReservations([]) as LessonReservations,
      selectLessonReservation: null as LessonReservation | null,
      tempDialogLessonReservation: null as LessonReservation | null,
      confirmMessage: null as string | null,
      successMessage: null as string | null,
      errorMessage: null as string | null,
    }
  },
  async created() {
    const lessonReservationsClient = new LessonReservationsClient();
    this.lessonReservations = await lessonReservationsClient.getStudentSchedules();
  },
  methods: {
    clickLessonReservation(lessonReservation: LessonReservation) {
      this.selectLessonReservation = lessonReservation;
    },
    editLessonReservation() {
      this.$router.push({name: 'LessonReservationIndex', query: { lesson_reservation_id: this.selectLessonReservation?.id?.getId(), target_date: this.selectLessonReservation?.startDate } });
    },
    deleteLessonReservation() {
      this.tempDialogLessonReservation = this.selectLessonReservation;
      this.selectLessonReservation = null;
      this.confirmMessage = "予約を削除してもよろしいですか？";
    },
    async resultConfirm(result: boolean) {
      if (result) {
        if (this.tempDialogLessonReservation == null) {
          this.confirmMessage = null;
          this.errorMessage = "予約の削除に失敗しました。";
          return;
        }
        const response: ResponseInterface = await this.tempDialogLessonReservation.deleteByStudent();
        this.confirmMessage = null;
        if (response.result) {
          this.tempDialogLessonReservation = null;
          this.successMessage = "削除を完了しました。";
        } else {
          this.errorMessage = "予約の削除に失敗しました。";
        }
      } else {
        this.tempDialogLessonReservation = null;
        this.confirmMessage = null;
      }
    },
    async resultSuccess() {
      this.successMessage = null;
      const lessonReservationsClient = new LessonReservationsClient();
      this.lessonReservations = await lessonReservationsClient.getStudentSchedules();
    },
    cancel() {
      this.selectLessonReservation = null;
    },
    clickNothing(e: PointerEvent) {
      e.stopPropagation();
    },
  }
});

</script>

<style lang="scss" scoped>
.index {
  margin-bottom: 100px;
}
.lesson-reservation-card {
  margin: 5px;
  border: 1px solid #000000;
  border-radius: 5px;
  padding: 5px;
  cursor: pointer;
}

.lesson-schedule {
  font-size: 16px;
  padding-left: 2em;
  text-indent: -2em;
  margin-bottom: 5px;
}

.lesson-data {
  padding-left: 10px;
  display: flex;

  .label {
    flex: 0 0 60px;
  }
}

.lesson-textarea-data {
  max-height: 120px;
  margin-left: 10px;
  width: 90%;
  overflow-y: auto;
  border: 1px solid #000000;
  padding: 5px;
}

.button-block {
  position: fixed;
  bottom: 0;
  margin-bottom: 40px;
  width: 100%;
  text-align: center;

  a {
    background-color: #ffffff;
    border: 1px solid #000000;
    border-radius: 5px;
    cursor: pointer;
    padding: 5px 40px;
    text-decoration: none;
    color: #000000;
  }
}

.lesson-reservation-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lesson-reservation-dialog {
  background-color: #ffffff;
  flex: 0 0 80%;
  border-radius: 5px;
  padding: 5px;
}

.dialog-button-block {
  margin: 20px 0;
  text-align: center;
  input {
    padding: 5px 20px;
    margin: 10px;
  }
}
</style>
