<template>
  <Base :errorMessage="errorMessage" :successMessage="successMessage" @resultSuccess="resultSuccess" @setLoginStudent="setLoginStudent" >
    <section class="lesson-reservations-new">
      <form @submit.prevent="confirmLessonReservation">
        <LessonReservationsInput :lessonReservation="lessonReservation" @changeLessonReservation="changeLessonReservation"/>
        <div class="button-block">
          <button type="submit" class="approval-button" >確認</button>
        </div>
      </form>
    </section>

    <div class="lesson-reservation-background" v-if="showConfirmDialog" @click="cancel()">
      <div class="lesson-reservation-dialog" @click="clickNothing">
        <div class="message">
          予約を登録してよろしいでしょうか？
        </div>
        <div class="lesson-data" v-if="!lessonReservation.member">
          <span class="label">氏名</span>：
          <span>{{lessonReservation.studentName}}</span>
        </div>
        <div class="lesson-data" v-if="!lessonReservation.member">
          <span class="label">電話番号</span>：
          <span>{{lessonReservation.studentTel}}</span>
        </div>
        <div class="lesson-data" v-if="!lessonReservation.member">
          <span class="label">年齢</span>：
          <span>{{lessonReservation.studentAge}}</span>
        </div>
        <div class="lesson-data">
          <span class="label">フロア</span>：
          <span>{{lessonReservation.floorName}}</span>
        </div>
        <div class="lesson-data">
          <span class="label">日付</span>：
          <span>{{lessonReservation.startDate}}</span>
        </div>
        <div class="lesson-data">
          <span class="label">時間</span>：
          <span>{{lessonReservation.startTime}}～{{lessonReservation.endTime}}</span>
        </div>
        <div class="lesson-data">
          <span class="label">スタッフ</span>：
          <span>{{lessonReservation.coachName}}</span>
        </div>
        <div class="lesson-data">
          <span class="label">メニュー</span>：
          <span>
            {{lessonReservation.trainingMenu1Name}}<br />
            {{lessonReservation.trainingMenu2Name}}
          </span>
        </div>
        <div class="lesson-data">
          <span class="label">備考</span>：
        </div>
        <div class="lesson-textarea-data" v-if="lessonReservation.note != null && lessonReservation.note !== ''">
          {{lessonReservation.note}}
        </div>
        <div class="button-block">
          <button type="button" class="approval-button" @click="saveLessonReservation()" >予約する</button>
        </div>
      </div>
    </div>
  </Base>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Base from '@/components/common/BaseComponent.vue';
import LessonReservationsInput from '@/components/lesson_reservations/InputComponent.vue';
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import {LessonReservation} from "@/domains/lesson_reservation/LessonReservation";
import FloorId from "@/domains/floor/value_object/FloorId";
import {Student} from "@/domains/student/Student";

export default defineComponent({
  name: 'LessonReservationNew',
  components: {
    Base,
    LessonReservationsInput,
  },
  data() {
    return {
      errorMessage: null as string | null,
      successMessage: null as string | null,
      lessonReservation: new LessonReservation(null, true,null, null,null,null,null,null,null,
          null,null,null,null,null,null,null,null,null,
          null,null, null,null,null) as LessonReservation,
      showConfirmDialog: false as boolean,
    }
  },
  created() {
    let startDate = null;
    if (this.$route.query.target_date) {
      startDate = this.$route.query.target_date as string;
    }

    let floorId = null;
    if (this.$route.query.floor_id) {
      floorId = new FloorId(parseInt(this.$route.query.floor_id as string));
    }

    let startTime = null;
    if (this.$route.query.schedule_time) {
      startTime = this.$route.query.schedule_time as string;
    }

    this.lessonReservation = new LessonReservation(
        null,
        false,
        null,
        null,
        null,
        null,
        null,
        null,
        floorId,
        null,
        null,
        startDate,
        startTime,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null);
  },
  methods: {
    changeLessonReservation(lessonReservation: LessonReservation) {
      this.lessonReservation = lessonReservation;
    },

    confirmLessonReservation() {
      if (this.lessonReservation.member && this.lessonReservation.studentId == null) {
        this.errorMessage = "会員番号が間違っています。";
        return;
      }

      if (this.lessonReservation.trainingMenuId1 != null && this.lessonReservation.trainingMenuId2 != null) {
        if (this.lessonReservation.trainingMenuId1.getId() == this.lessonReservation.trainingMenuId2.getId()) {
          this.errorMessage = "同じメニューを選択しないでください。";
          return;
        }
      }
      this.showConfirmDialog = true;
    },

    async saveLessonReservation() {

      const response: ResponseInterface = await this.lessonReservation.insertByStudent();
      this.showConfirmDialog = false;
      if (response.result) {
        this.successMessage = "登録を完了しました。";
      } else {
        if (response.status == 400) {
          if (response.errorCode == 'exist_coach_schedule') {
            this.errorMessage = "既にスタッフに予定が登録されています。";
          } else if (response.errorCode == 'exist_lesson_reservation') {
            this.errorMessage = "フロアが空いていません。";
          } else if (response.errorCode == 'exist_student_schedule') {
            this.errorMessage = "既に同じ日時で予定が登録されているため、登録することが出来ません。";
          }
        } else {
          this.errorMessage = "予約の登録に失敗しました。";
        }
      }
    },
    resultSuccess() {
      this.$router.push({name: 'Index'});
    },
    setLoginStudent(student: Student) {
      if (student == null) {
        return;
      }
      this.lessonReservation = new LessonReservation(
          this.lessonReservation.id,
          true,
          student.userId,
          student.id,
          null,
          null,
          null,
          null,
          this.lessonReservation.floorId,
          this.lessonReservation.floorName,
          this.lessonReservation.floorGroupId,
          this.lessonReservation.startDate,
          this.lessonReservation.startTime,
          this.lessonReservation.endTime,
          this.lessonReservation.coachId,
          this.lessonReservation.coachName,
          this.lessonReservation.trainingMenuId1,
          this.lessonReservation.trainingMenu1Name,
          this.lessonReservation.trainingMenu1Color,
          this.lessonReservation.trainingMenuId2,
          this.lessonReservation.trainingMenu2Name,
          this.lessonReservation.trainingMenu2Color,
          this.lessonReservation.note);

    },
    cancel() {
      this.showConfirmDialog = false;
    },
    clickNothing(e: PointerEvent) {
      e.stopPropagation();
    },
  }
});
</script>

<style lang="scss" scoped>
.lesson-reservations-new {
  max-width: 800px;
  padding-bottom: 50px;
  margin: 0 auto;
}

.button-block {
  margin-top: 60px;
  display: flex;
  justify-content: center;
}

.lesson-reservation-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.lesson-reservation-dialog {
  background-color: #ffffff;
  flex: 0 0 80%;
  border-radius: 5px;
  padding: 5px;

  .message {
    margin: 10px 10px;
  }
}

.lesson-data {
  padding-left: 10px;
  margin-bottom: 5px;
  display: flex;

  .label {
    flex: 0 0 60px;
  }
}

.lesson-textarea-data {
  max-height: 120px;
  margin-left: 10px;
  width: 90%;
  overflow-y: auto;
  border: 1px solid #000000;
  padding: 5px;
  white-space: pre-wrap;
}
</style>
