
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import CoachesClient from "@/http_clients/admin/CoachesClient";
import {Coaches} from "@/domains/coach/Coaches";
import Pager from "@/components/common/PagerComponent.vue";

export default defineComponent({
  name: 'CoachIndex',
  components: {
    Base,
    Pager
  },
  data() {
    return {
      errorMessage: '' as string,
      coaches: new Coaches([]) as Coaches,
    }
  },
  watch: {
    async $route() {
      let page = null;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page as string);
      }
      const coachesClient = new CoachesClient();
      this.coaches = await coachesClient.getList(page);
    }
  },
  async created() {
    let page = null;
    if (this.$route.query.page) {
      page = parseInt(this.$route.query.page as string);
    }
    const coachesClient = new CoachesClient();
    this.coaches = await coachesClient.getList(page);
  },
  methods: {
    async changePage(page: number) {
      this.$router.push({query: { page: page } });
    }
  }
});
