import {TrainingMenu} from "@/domains/training_menu/TrainingMenu";
import TrainingMenuId from "@/domains/training_menu/value_object/TrainingMenuId";
import moment from "moment/moment";

export class TrainingMenus {
  public list: TrainingMenu[];
  public totalPage: number;
  public currentPage: number;

  constructor(list: TrainingMenu[], totalPage = 1, currentPage= 1) {
    this.list = list;
    this.totalPage = totalPage;
    this.currentPage = currentPage;
  }

  public isPresent(): boolean {
    return !this.isEmpty();
  }

  public isEmpty(): boolean {
    return this.list.length == 0;
  }

  public find(trainingMenuId: TrainingMenuId): TrainingMenu | null {
    for (let i = 0; i < this.list.length; i++) {
      const trainingMenu = this.list[i];
      if (trainingMenu.id == null) {
        continue;
      }
      if (trainingMenu.id.getId() == trainingMenuId.getId()) {
        return trainingMenu;
      }
    }
    return null;
  }

  public filterEndTimeOver(startTime: string): TrainingMenus {
    const trainingMenus = this.list.filter((trainingMenu) => {

      const endTimeMoment = moment(startTime, 'HH:mm').add(trainingMenu.trainingMinutes, 'minute');
      const closedTimeMoment = moment('22:00', 'HH:mm');

      return endTimeMoment.isSameOrBefore(closedTimeMoment);
    });
    return new TrainingMenus(trainingMenus);
  }
}
