import HttpClientBase from '../HttpClientBase';
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import {AxiosResponse} from "axios";
import {CoachSchedules} from "@/domains/coach_schedule/CoachSchedules";
import CoachScheduleId from "@/domains/coach_schedule/value_object/CoachScheduleId";
import {CoachSchedule} from "@/domains/coach_schedule/CoachSchedule";
import CoachId from "@/domains/coach/value_object/CoachId";
import moment from 'moment';
import FloorGroupId from "@/domains/floor_group/value_object/FloorGroupId";

export default class CoachSchedulesClient extends HttpClientBase {

  public async getList(page= null as number | null): Promise<CoachSchedules> {
    const param = this.getAccessTokenParam();
    if (page != null) {
      param["page"] = page.toString();
    }
    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/admin/coach_schedules', param);

    const coachSchedules: CoachSchedule[] = [];
    const totalPage = response.data.meta.total_page;
    const currentPage = response.data.meta.current_page;

    response.data.coach_schedules.forEach((coachScheduleResponse) => {

      let coachId = null;
      if (coachScheduleResponse.coach_id != null) {
        coachId = new CoachId(coachScheduleResponse.coach_id);
      }
      let floorGroupId = null;
      if (coachScheduleResponse.floor_group_id != null) {
        floorGroupId = new FloorGroupId(coachScheduleResponse.floor_group_id);
      }

      coachSchedules.push(new CoachSchedule(
        new CoachScheduleId(coachScheduleResponse.id),
        coachId,
        coachScheduleResponse.coach_name,
        coachScheduleResponse.title,
        coachScheduleResponse.start_date,
        moment(coachScheduleResponse.start_time).format("HH:mm"),
        moment(coachScheduleResponse.end_time).format("HH:mm"),
        coachScheduleResponse.end_repeat_date,
        coachScheduleResponse.holiday,
        coachScheduleResponse.repeat,
        coachScheduleResponse.repeat_monday,
        coachScheduleResponse.repeat_tuesday,
        coachScheduleResponse.repeat_wednesday,
        coachScheduleResponse.repeat_thursday,
        coachScheduleResponse.repeat_friday,
        coachScheduleResponse.repeat_saturday,
        coachScheduleResponse.repeat_sunday,
        coachScheduleResponse.note,
        floorGroupId,
      ));
    });

    return new CoachSchedules(coachSchedules, totalPage, currentPage);
  }

  public async getData(coachScheduleId: CoachScheduleId): Promise<CoachSchedule> {
    const param = this.getAccessTokenParam();
    const response: AxiosResponse<ShowResponseInterface> = await this.getRequest('/admin/coach_schedules/' + coachScheduleId.getId(), param);

    const coachScheduleResponse = response.data.coach_schedule;

    let coachId = null;
    if (coachScheduleResponse.coach_id != null) {
      coachId = new CoachId(coachScheduleResponse.coach_id);
    }

    let floorGroupId = null;
    if (coachScheduleResponse.floor_group_id != null) {
      floorGroupId = new FloorGroupId(coachScheduleResponse.floor_group_id);
    }

    let startTime = null;
    let endTime = null;

    if (!coachScheduleResponse.holiday) {
      startTime = moment(coachScheduleResponse.start_time).format("HH:mm");
      endTime = moment(coachScheduleResponse.end_time).format("HH:mm");
    }

    return new CoachSchedule(
      new CoachScheduleId(coachScheduleResponse.id),
      coachId,
      coachScheduleResponse.coach_name,
      coachScheduleResponse.title,
      coachScheduleResponse.start_date,
      startTime,
      endTime,
      coachScheduleResponse.end_repeat_date,
      coachScheduleResponse.holiday,
      coachScheduleResponse.repeat,
      coachScheduleResponse.repeat_monday,
      coachScheduleResponse.repeat_tuesday,
      coachScheduleResponse.repeat_wednesday,
      coachScheduleResponse.repeat_thursday,
      coachScheduleResponse.repeat_friday,
      coachScheduleResponse.repeat_saturday,
      coachScheduleResponse.repeat_sunday,
      coachScheduleResponse.note,
      floorGroupId,
    );
  }

  public async getTargetDateSchedules(targetDate= null as string | null): Promise<CoachSchedules> {
    const param = this.getAccessTokenParam();
    if (targetDate != null) {
      param["target_date"] = targetDate;
    }
    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/admin/coach_schedules/target_date_schedules', param);

    const coachSchedules: CoachSchedule[] = [];

    response.data.coach_schedules.forEach((coachScheduleResponse) => {

      let coachId = null;
      if (coachScheduleResponse.coach_id != null) {
        coachId = new CoachId(coachScheduleResponse.coach_id);
      }

      let floorGroupId = null;
      if (coachScheduleResponse.floor_group_id != null) {
        floorGroupId = new FloorGroupId(coachScheduleResponse.floor_group_id);
      }

      coachSchedules.push(new CoachSchedule(
        new CoachScheduleId(coachScheduleResponse.id),
        coachId,
        coachScheduleResponse.coach_name,
        coachScheduleResponse.title,
        coachScheduleResponse.start_date,
        moment(coachScheduleResponse.start_time).format("HH:mm"),
        moment(coachScheduleResponse.end_time).format("HH:mm"),
        coachScheduleResponse.end_repeat_date,
        coachScheduleResponse.holiday,
        coachScheduleResponse.repeat,
        coachScheduleResponse.repeat_monday,
        coachScheduleResponse.repeat_tuesday,
        coachScheduleResponse.repeat_wednesday,
        coachScheduleResponse.repeat_thursday,
        coachScheduleResponse.repeat_friday,
        coachScheduleResponse.repeat_saturday,
        coachScheduleResponse.repeat_sunday,
        coachScheduleResponse.note,
        floorGroupId,
      ));
    });

    return new CoachSchedules(coachSchedules);
  }

  public async getTargetDateHolidaySchedules(targetDate= null as string | null): Promise<CoachSchedules> {
    const param = this.getAccessTokenParam();
    if (targetDate != null) {
      param["target_date"] = targetDate;
    }
    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/admin/coach_schedules/target_date_holiday_schedules', param);

    const coachSchedules: CoachSchedule[] = [];

    response.data.coach_schedules.forEach((coachScheduleResponse) => {

      let coachId = null;
      if (coachScheduleResponse.coach_id != null) {
        coachId = new CoachId(coachScheduleResponse.coach_id);
      }

      let floorGroupId = null;
      if (coachScheduleResponse.floor_group_id != null) {
        floorGroupId = new FloorGroupId(coachScheduleResponse.floor_group_id);
      }

      coachSchedules.push(new CoachSchedule(
        new CoachScheduleId(coachScheduleResponse.id),
        coachId,
        coachScheduleResponse.coach_name,
        coachScheduleResponse.title,
        coachScheduleResponse.start_date,
        moment(coachScheduleResponse.start_time).format("HH:mm"),
        moment(coachScheduleResponse.end_time).format("HH:mm"),
        coachScheduleResponse.end_repeat_date,
        coachScheduleResponse.holiday,
        coachScheduleResponse.repeat,
        coachScheduleResponse.repeat_monday,
        coachScheduleResponse.repeat_tuesday,
        coachScheduleResponse.repeat_wednesday,
        coachScheduleResponse.repeat_thursday,
        coachScheduleResponse.repeat_friday,
        coachScheduleResponse.repeat_saturday,
        coachScheduleResponse.repeat_sunday,
        coachScheduleResponse.note,
        floorGroupId,
      ));
    });

    return new CoachSchedules(coachSchedules);
  }

  public async getTargetDateNotHolidaySchedules(targetDate= null as string | null): Promise<CoachSchedules> {
    const param = this.getAccessTokenParam();
    if (targetDate != null) {
      param["target_date"] = targetDate;
    }
    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/admin/coach_schedules/target_date_not_holiday_schedules', param);

    const coachSchedules: CoachSchedule[] = [];

    response.data.coach_schedules.forEach((coachScheduleResponse) => {

      let coachId = null;
      if (coachScheduleResponse.coach_id != null) {
        coachId = new CoachId(coachScheduleResponse.coach_id);
      }

      let floorGroupId = null;
      if (coachScheduleResponse.floor_group_id != null) {
        floorGroupId = new FloorGroupId(coachScheduleResponse.floor_group_id);
      }

      coachSchedules.push(new CoachSchedule(
        new CoachScheduleId(coachScheduleResponse.id),
        coachId,
        coachScheduleResponse.coach_name,
        coachScheduleResponse.title,
        coachScheduleResponse.start_date,
        moment(coachScheduleResponse.start_time).format("HH:mm"),
        moment(coachScheduleResponse.end_time).format("HH:mm"),
        coachScheduleResponse.end_repeat_date,
        coachScheduleResponse.holiday,
        coachScheduleResponse.repeat,
        coachScheduleResponse.repeat_monday,
        coachScheduleResponse.repeat_tuesday,
        coachScheduleResponse.repeat_wednesday,
        coachScheduleResponse.repeat_thursday,
        coachScheduleResponse.repeat_friday,
        coachScheduleResponse.repeat_saturday,
        coachScheduleResponse.repeat_sunday,
        coachScheduleResponse.note,
        floorGroupId,
      ));
    });

    return new CoachSchedules(coachSchedules);
  }

  public async insert(coachSchedule: CoachSchedule): Promise<ResponseInterface> {
    const formData: FormData = this.getAccessTokenForm();
    coachSchedule.getFormData().forEach((data, key) => {
      formData.append("coach_schedule[" + key + "]", data);
    });

    try {
      await this.postRequest('/admin/coach_schedules', formData);
    } catch (error) {
      return {
        result: false,
        status: error.response.status,
        errorCode: error.response.data.error_code,
      };
    }
    return {
      result: true,
      status: 204,
      errorCode: null,
    };
  }

  public async update(coachSchedule: CoachSchedule): Promise<ResponseInterface> {
    const formData: FormData = this.getAccessTokenForm();
    coachSchedule.getFormData().forEach((data, key) => {
      formData.append("coach_schedule[" + key + "]", data);
    });

    try {
      await this.putRequest('/admin/coach_schedules/' + coachSchedule.id?.getId(), formData);
    } catch (error) {
      return {
        result: false,
        status: error.response.status,
        errorCode: error.response.data.error_code,
      };
    }
    return {
      result: true,
      status: 204,
      errorCode: null,
    };
  }

  public async delete(coachSchedule: CoachSchedule, endRepeatDate: string | null): Promise<ResponseInterface> {
    const formData: FormData = this.getAccessTokenForm();

    if (endRepeatDate != null) {
      formData.append('end_repeat_date', endRepeatDate);
    }
    try {
      await this.deleteRequest('/admin/coach_schedules/' + coachSchedule.id?.getId(), formData);
    } catch (error) {
      return {
        result: false,
        status: error.response.status,
        errorCode: error.response.data.error_code,
      };
    }
    return {
      result: true,
      status: 204,
      errorCode: null,
    };
  }
}

export interface IndexResponseInterface {
  meta: {
    total_page: number,
    current_page: number,
  };
  coach_schedules: CoachScheduleResponseInterface[];
}

export interface ShowResponseInterface {
  coach_schedule: CoachScheduleResponseInterface;
}

export interface CoachScheduleResponseInterface {
  id: number;
  coach_id: number;
  coach_name: string;
  title: string;
  start_date: string;
  start_time: string;
  end_time: string;
  end_repeat_date: string;
  holiday: boolean;
  repeat: boolean;
  repeat_monday: boolean;
  repeat_tuesday: boolean;
  repeat_wednesday: boolean;
  repeat_thursday: boolean;
  repeat_friday: boolean;
  repeat_saturday: boolean;
  repeat_sunday: boolean;
  note: string;
  is_delete: boolean;
  floor_group_id: number;
}
