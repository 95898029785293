<template>
  <Base :errorMessage="errorMessage" :successMessage="successMessage" @resultSuccess="resultSuccess" >
    <section class="lesson-reservations-edit">
      <form @submit.prevent="saveLessonReservation">
        <LessonReservationsInput :lessonReservation="lessonReservation" @changeLessonReservation="changeLessonReservation"/>
        <div class="button-block">
          <button type="submit" class="approval-button" >更新</button>
        </div>
      </form>
    </section>
  </Base>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Base from '@/components/common/BaseComponent.vue';
import LessonReservationsInput from '@/components/lesson_reservations/InputComponent.vue';
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import {LessonReservation} from "@/domains/lesson_reservation/LessonReservation";
import FloorId from "@/domains/floor/value_object/FloorId";
import LessonReservationsClient from "@/http_clients/LessonReservationsClient";
import LessonReservationId from "@/domains/lesson_reservation/value_object/LessonReservationId";

export default defineComponent({
  name: 'LessonReservationEdit',
  components: {
    Base,
    LessonReservationsInput,
  },
  data() {
    return {
      errorMessage: null as string | null,
      successMessage: null as string | null,
      lessonReservation: new LessonReservation(null, true,null,null,null,null,null,null,null,
          null,null,null,null,null,null,null,null,null,
          null,null, null,null,null) as LessonReservation,
    }
  },
  async created() {
    let startDate = null;
    if (this.$route.query.target_date) {
      startDate = this.$route.query.target_date as string;
    }

    let floorId = null;
    if (this.$route.query.floor_id) {
      floorId = new FloorId(parseInt(this.$route.query.floor_id as string));
    }

    let startTime = null;
    if (this.$route.query.schedule_time) {
      startTime = this.$route.query.schedule_time as string;
    }
    this.lessonReservation.startDate = startDate;
    this.lessonReservation.floorId = floorId;
    this.lessonReservation.startTime = startTime;

    const lessonReservationsClient = new LessonReservationsClient();
    const lessonReservation = await lessonReservationsClient.getData(new LessonReservationId(parseInt(this.$route.params.lessonReservationId as string)));

    this.lessonReservation = new LessonReservation(
        lessonReservation.id,
        lessonReservation.member,
        lessonReservation.studentUserId,
        lessonReservation.studentId,
        lessonReservation.studentName,
        lessonReservation.studentNameKana,
        lessonReservation.studentTel,
        lessonReservation.studentAge,
        floorId,
        lessonReservation.floorName,
        lessonReservation.floorGroupId,
        startDate,
        startTime,
        lessonReservation.endTime,
        lessonReservation.coachId,
        lessonReservation.coachName,
        lessonReservation.trainingMenuId1,
        lessonReservation.trainingMenu1Name,
        lessonReservation.trainingMenu1Color,
        lessonReservation.trainingMenuId2,
        lessonReservation.trainingMenu2Name,
        lessonReservation.trainingMenu2Color,
        lessonReservation.note,
    );
  },
  methods: {
    changeLessonReservation(lessonReservation: LessonReservation) {
      this.lessonReservation = lessonReservation;
    },
    async saveLessonReservation() {
      const response: ResponseInterface = await this.lessonReservation.updateByStudent();
      if (response.result) {
        this.successMessage = "更新を完了しました。";
      } else {
        if (response.status == 400) {
          if (response.errorCode == 'exist_coach_schedule') {
            this.errorMessage = "既にスタッフに予定が登録されています。";
          } else if (response.errorCode == 'exist_lesson_reservation') {
            this.errorMessage = "フロアが空いていません。";
          }
        } else {
          this.errorMessage = "予約の更新に失敗しました。";
        }
      }
    },
    resultSuccess() {
      this.$router.push({name: 'Index'});
    },
  }
});
</script>

<style lang="scss" scoped>
.lesson-reservations-edit {
  max-width: 800px;
  padding-bottom: 50px;
}

.button-block {
  margin-top: 60px;

  display: flex;
  justify-content: center;

  button {
    margin: 0 40px;
  }
}

.confirm-message-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.confirm-message-dialog {
  background-color: #ffffff;
  flex: 0 1 600px;
  margin: 40px;
  padding: 20px;
  border-radius: 10px;
}

.delete-select-block {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  label {
    margin-bottom: 10px;
  }

  input[type="date"] {
    margin-left: 20px;
  }
}
</style>
