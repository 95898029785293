
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import Pager from "@/components/common/PagerComponent.vue";
import {Students} from "@/domains/student/Students";
import StudentsClient from "@/http_clients/admin/StudentsClient";
import {ResponseInterface} from "@/http_clients/ResponseInterface";

interface HTMLEvent<T extends EventTarget> extends Event {
  target: T;
}

export default defineComponent({
  name: 'StudentIndex',
  components: {
    Base,
    Pager
  },
  data() {
    return {
      errorMessage: '' as string,
      successMessage: '' as string,
      loadCsvConfirm: false as boolean,
      students: new Students([]) as Students,
    }
  },
  watch: {
    async $route() {
      let page = null;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page as string);
      }
      const studentsClient = new StudentsClient();
      this.students = await studentsClient.getList(page);
    }
  },
  created() {
    this.getStudents();
  },
  methods: {
    async changePage(page: number) {
      this.$router.push({query: { page: page } });
    },
    async importCsvFile(e: HTMLEvent<HTMLInputElement>) {
      if (e.target == null || e.target.files == null) {
        this.errorMessage = "ファイルを選択してください";
        return;
      }
      let file = e.target.files[0];
      const studentsClient = new StudentsClient();
      const response: ResponseInterface = await studentsClient.importCsv(file);
      if (response.result) {
        this.successMessage = "CSVのインポートを完了しました。";
      } else if (response.errorCode === "csv_import_error") {
        this.errorMessage = "会員Noが重複しているため、一部CSVのインポートが出来ませんでした。";
      } else {
        this.errorMessage = "CSVのインポートに失敗しました。";
      }
      this.getStudents();
    },
    cancel() {
      this.loadCsvConfirm = false;
    },
    clickNothing(e: PointerEvent) {
      e.stopPropagation();
    },
    resultSuccess() {
      this.successMessage = "";
    },
    async getStudents() {
      let page = null;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page as string);
      }
      const studentsClient = new StudentsClient();
      this.students = await studentsClient.getList(page);
    }
  }
});
