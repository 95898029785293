import HttpClientBase from '../HttpClientBase';

export default class SessionsClient extends HttpClientBase {
  constructor() {
    super();
  }

  public async signIn(userId: string, password: string): Promise<CoachSignInResponseInterface | null> {
    const formData = new FormData();
    formData.append('user_id', userId);
    formData.append('password', password);
    const response: CoachSignInResponseInterface | null = await this.postRequest('/coach_auth/sign_in', formData);
    return response;
  }

  public async signOut(): Promise<boolean> {
    const param = this.getAccessTokenParam();
    await this.deleteRequest('/coach_auth/sign_out', param);
    return true;
  }
}

export interface CoachSignInResponseInterface {
  headers: {
    uid: string,
    "access-token": string,
    client: string,
  };
  data: {
    user_id: string;
    is_delete: boolean;
  };
}
