import {LessonReservation} from "@/domains/lesson_reservation/LessonReservation";
import moment from 'moment';
import FloorId from "@/domains/floor/value_object/FloorId";
import CoachId from "@/domains/coach/value_object/CoachId";
import FloorGroupId from "@/domains/floor_group/value_object/FloorGroupId";

export class LessonReservations {
  public list: LessonReservation[];
  public totalPage: number;
  public currentPage: number;

  constructor(list: LessonReservation[], totalPage = 1, currentPage= 1) {
    this.list = list;
    this.totalPage = totalPage;
    this.currentPage = currentPage;
  }

  public isPresent(): boolean {
    return !this.isEmpty();
  }

  public isEmpty(): boolean {
    return this.list.length == 0;
  }

  public filterFloor(floorId: FloorId): LessonReservations {
    const lessonReservations = this.list.filter((lessonReservation) => {
      if (lessonReservation.floorId == null) {
        return false;
      }
      return lessonReservation.floorId.getId() == floorId.getId();
    });
    return new LessonReservations(lessonReservations);
  }

  public filterFloorGroup(floorGroupId: FloorGroupId): LessonReservations {
    const lessonReservations = this.list.filter((lessonReservation) => {
      if (lessonReservation.floorGroupId == null) {
        return false;
      }
      return lessonReservation.floorGroupId.getId() == floorGroupId.getId();
    });
    return new LessonReservations(lessonReservations);
  }

  public filterCoach(coachId: CoachId): LessonReservations {
    const lessonReservations = this.list.filter((lessonReservation) => {
      if (lessonReservation.coachId == null) {
        return false;
      }
      return lessonReservation.coachId.getId() == coachId.getId();
    });
    return new LessonReservations(lessonReservations);
  }

  public filterScheduleTime(scheduleTime: string, timeInterval: number): LessonReservations {
    const lessonReservations = this.list.filter((lessonReservation) => {

      const scheduleTimeStartMoment = moment(scheduleTime, 'HH:mm');
      const scheduleTimeEndMoment = moment(scheduleTime, 'HH:mm').add(timeInterval, 'minute');
      const startTimeMoment = moment(lessonReservation.startTime, 'HH:mm');
      const endTimeMoment = moment(lessonReservation.endTime, 'HH:mm');

      return scheduleTimeEndMoment.isAfter(startTimeMoment) && endTimeMoment.isAfter(scheduleTimeStartMoment);
    });
    return new LessonReservations(lessonReservations);
  }
}
