
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import StudentInput from '@/components/admin/students/InputComponent.vue';
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import {Student} from "@/domains/student/Student";
import LessonReservationId from "@/domains/lesson_reservation/value_object/LessonReservationId";
import LessonReservationsClient from "@/http_clients/admin/LessonReservationsClient";

export default defineComponent({
  name: 'StudentNew',
  components: {
    Base,
    StudentInput,
  },
  data() {
    return {
      errorMessage: null as string | null,
      successMessage: null as string | null,
      student: new Student(null, null, null, null, null, null, null,
          null, null, null, null, "male", null, null, null,
          true, null, null, null, null) as Student,
      lessonReservationId: null as LessonReservationId | null,
    }
  },
  async created() {
    if (this.$route.query.lesson_reservation_id) {
      this.lessonReservationId = new LessonReservationId(parseInt(this.$route.query.lesson_reservation_id as string));

      const lessonReservationsClient = new LessonReservationsClient();
      const lessonReservation = await lessonReservationsClient.getData(this.lessonReservationId);
      this.student = new Student(
          null,
          null,
          null,
          lessonReservation.studentName,
          null,
          null,
          null,
          lessonReservation.studentTel,
          null,
          null,
          null,
          "male",
          null,
          null,
          null,
          true,
          null,
          null,
          null,
          null);
    }
  },
  methods: {
    changeStudent(student: Student) {
      this.student = student;
    },
    async saveStudent() {
      const response: ResponseInterface = await this.student.insert(this.lessonReservationId);
      if (response.result) {
        this.successMessage = "登録を完了しました。";
      } else {
        if (response.status == 422) {
          this.errorMessage = "会員Noは既に使用されています。";
        } else {
          this.errorMessage = "会員の登録処理に失敗しました。";
        }
      }
    },
    resultSuccess() {
      this.$router.push('/admin/students');
    },
  }
});
