<template>
  <Base >
    <section class="reservation_histories-index">
      <div class="header">
        <h1>予約履歴一覧</h1>
      </div>

      <div>
        <div>検索条件</div>
        <div class="search-param">
          <div>対象期間</div>
          <div class="target-date" @click="showTargetYearMonth">
            {{displayTargetDate()}}
          </div>
          <div class="select-target-year-month">
            <div class="select-target-date" v-show="showTargetYearMonthFlg">
              <select v-model="targetYear">
                <option v-for="year in selectYearList()" :key="year" :value="year">{{year}}</option>
              </select>
              <select v-model="targetMonth" class="select-month">
                <option value="01">1</option>
                <option value="02">2</option>
                <option value="03">3</option>
                <option value="04">4</option>
                <option value="05">5</option>
                <option value="06">6</option>
                <option value="07">7</option>
                <option value="08">8</option>
                <option value="09">9</option>
                <option value="10">10</option>
                <option value="11">11</option>
                <option value="12">12</option>
              </select>

              <button type="button" class="apply-button" @click="applyTargetYearMonth" >適用</button>
            </div>
          </div>
        </div>

        <div class="button-block">
          <button type="button" @click="searchReservationHistory" >検索</button>
          <button type="button" @click="csvDownload" >CSVダウンロード</button>
        </div>
      </div>

      <div class="list-block" v-if="reservationHistories.isPresent()">
        <table>
          <thead>
          <tr>
            <th>
              日付
            </th>
            <th>
              時間
            </th>
            <th>
              フロア
            </th>
            <th>
              スタッフ
            </th>
            <th>
              メニュー
            </th>
            <th>
              会員No
            </th>
            <th>
              氏名
            </th>
            <th>
              電話番号
            </th>
            <th>
              年齢
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(reservationHistory, index) in reservationHistories.list" :key="index">
            <td>
              {{ reservationHistory.displayStartDate() }}
            </td>
            <td>
              {{ reservationHistory.startTime }}～{{ reservationHistory.endTime }}
            </td>
            <td>
              {{ reservationHistory.floorName }}
            </td>
            <td>
              {{ reservationHistory.coachName }}
            </td>
            <td>
              <div>{{ reservationHistory.trainingMenu1Name }}</div>
              <div>{{ reservationHistory.trainingMenu2Name }}</div>
            </td>
            <td>
              <span v-if="reservationHistory.studentUserId != null">
                {{ reservationHistory.studentUserId.getId() }}
              </span>
            </td>
            <td>
              {{ reservationHistory.studentName }}
            </td>
            <td>
              <div>{{ reservationHistory.studentTel1 }}</div>
              <div>{{ reservationHistory.studentTel2 }}</div>
            </td>
            <td>
              {{ reservationHistory.studentAge }}
            </td>
          </tr>
          </tbody>
        </table>
        <Pager :totalPage="reservationHistories.totalPage" :currentPage="reservationHistories.currentPage" @selectPage="changePage" class="pager"></Pager>
      </div>
    </section>
  </Base>
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import moment from 'moment';
import {ReservationHistories} from "@/domains/reservation_history/ReservationHistories";
import ReservationHistoriesClient from "@/http_clients/admin/ReservationHistoriesClient";
import Pager from "@/components/common/PagerComponent.vue";

export default defineComponent({
  name: 'LessonReservationIndex',
  components: {
    Base,
    Pager,
  },
  data() {
    return {
      showTargetYearMonthFlg: false as boolean,
      targetYearMonth: moment().format("YYYY-MM") as string,
      firstYear: '2021' as string,
      targetYear: moment().format("YYYY") as string,
      targetMonth: moment().format("MM") as string,
      reservationHistories: new ReservationHistories([]) as ReservationHistories,
    }
  },
  watch: {
    async $route() {
      let page = null;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page as string);
      }
      if (this.$route.query.target_year_month) {
        this.targetYearMonth = this.$route.query.target_year_month as string;
        this.targetYear = moment(this.$route.query.target_year_month as string, "YYYY-MM").format("YYYY") as string
        this.targetMonth = moment(this.$route.query.target_year_month as string, "YYYY-MM").format("MM") as string
      }
      const reservationHistoriesClient = new ReservationHistoriesClient();
      this.reservationHistories = await reservationHistoriesClient.getList(this.targetYearMonth, page);
    }
  },
  async created() {
    let page = null;
    if (this.$route.query.page) {
      page = parseInt(this.$route.query.page as string);
    }
    if (this.$route.query.target_year_month) {
      this.targetYearMonth = this.$route.query.target_year_month as string;
      this.targetYear = moment(this.$route.query.target_year_month as string, "YYYY-MM").format("YYYY") as string
      this.targetMonth = moment(this.$route.query.target_year_month as string, "YYYY-MM").format("MM") as string
    }
    const reservationHistoriesClient = new ReservationHistoriesClient();
    this.reservationHistories = await reservationHistoriesClient.getList(this.targetYearMonth, page);
  },
  methods: {
    async changePage(page: number) {
      this.$router.push({query: { target_year_month: this.targetYearMonth, page: page } });
    },
    displayTargetDate() {
      return moment(this.targetYear + '-' + this.targetMonth).format('Y月M月');
    },
    selectYearList() {
      const yearList = [];
      for(let year = parseInt(this.firstYear); year <= parseInt(moment().format("YYYY")); year++) {
        yearList.push(year.toString());
      }
      return yearList;
    },
    applyTargetYearMonth() {
      this.targetYearMonth = this.targetYear + '-' + this.targetMonth;
      this.showTargetYearMonthFlg = false;
    },
    showTargetYearMonth() {
      this.showTargetYearMonthFlg = true;
    },
    searchReservationHistory() {
      let page = 1;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page as string);
      }
      this.$router.push({query: { target_year_month: this.targetYearMonth, page: page } });
    },
    async csvDownload() {
      const reservationHistoriesClient = new ReservationHistoriesClient();
      const csv = await reservationHistoriesClient.downloadCsv(this.targetYearMonth);
      this.downloadCsvBlob(csv, "reservation_" +  moment(this.targetYearMonth, "YYYY-MM").format("YYYY_MM") + ".csv");
    },
    downloadCsvBlob(blob: Blob, fileName: string) {
      const url = URL.createObjectURL(blob);
      const linkEl = document.createElement('a');
      linkEl.href = url;
      linkEl.setAttribute('download',fileName);
      document.body.appendChild(linkEl);
      linkEl.click();
      URL.revokeObjectURL(url);
      linkEl?.parentNode?.removeChild(linkEl);
    },
  }
});
</script>

<style lang="scss" scoped>
.search-param {
  display: flex;
  border: 1px solid #707070;
  border-radius: 5px;
  margin-bottom: 20px;
  padding: 10px;
  align-items: center;
}

.target-date {
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 5px;
  cursor: pointer;
  padding: 3px 20px;
  margin-left: 20px;
  position: relative;
}

.select-target-year-month {
  position: relative;
}

.select-target-date {
  position: absolute;
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 5px;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  left: -110px;
}

.select-month {
  margin-left: 20px;
}

.apply-button {
  margin-left: 20px;
  padding: 3px 20px;
}

.button-block {
  margin-left: 50px;
  margin-bottom: 20px;
  button {
    margin-right: 20px;
  }
}

.pager {
  width: 100%;
  margin-top: 20px;
}
</style>
