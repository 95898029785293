import {Student} from "@/domains/student/Student";

export class Students {
  public list: Student[];
  public totalPage: number;
  public currentPage: number;

  constructor(list: Student[], totalPage = 1, currentPage= 1) {
    this.list = list;
    this.totalPage = totalPage;
    this.currentPage = currentPage;
  }

  public isPresent(): boolean {
    return !this.isEmpty();
  }

  public isEmpty(): boolean {
    return this.list.length == 0;
  }
}