
import { defineComponent } from 'vue';
import FloorGroupsClient from "@/http_clients/admin/FloorGroupsClient";
import {FloorGroups} from "@/domains/floor_group/FloorGroups";
import FloorGroupId from "@/domains/floor_group/value_object/FloorGroupId";
import {TrainingMenu} from "@/domains/training_menu/TrainingMenu";

export default defineComponent({
  name: 'AdminTrainingMenusInputComponent',
  props: {
    trainingMenu: TrainingMenu,
  },
  data() {
    return {
      floorGroupId: null as number | null,
      name: null as string | null,
      trainingMinutes: null as number | null,
      color: null as string | null,
      note: null as string | null,
      floorGroups: new FloorGroups([]) as FloorGroups,
      trainingMinutesSelects: [] as number[],
      colorSelects: [] as string[],
    }
  },
  watch: {
    trainingMenu: function () {
      if (this.trainingMenu != null) {
        if (this.trainingMenu.floorGroupId != null) {
          this.floorGroupId = this.trainingMenu.floorGroupId.getId();
        }
        this.name = this.trainingMenu.name;
        this.trainingMinutes = this.trainingMenu.trainingMinutes;
        this.color = this.trainingMenu.color;
        this.note = this.trainingMenu.note;
      }
    },
    floorGroupId: function () {
      this.setTrainingMenu();
    },
    name: function () {
      this.setTrainingMenu();
    },
    trainingMinutes: function () {
      this.setTrainingMenu();
    },
    color: function () {
      this.setTrainingMenu();
    },
    note: function () {
      this.setTrainingMenu();
    },
  },
  async created() {
    const floorGroupsClient = new FloorGroupsClient();
    this.floorGroups = await floorGroupsClient.getFloorGroups();
    if (this.trainingMenu != null) {
      this.trainingMinutesSelects = this.trainingMenu.getTrainingMinutesSelectList();
      this.colorSelects = this.trainingMenu.getColorSelectList();

      if (this.trainingMenu.color != null) {
        this.color = this.trainingMenu.color;
      } else {
        this.color = this.colorSelects[0];
      }
    }
  },
  methods: {
    setTrainingMenu() {
      let id = null;
      if (this.trainingMenu != null) {
        id = this.trainingMenu.id;
      }
      let floorGroupId = null;
      if (this.floorGroupId != null) {
        floorGroupId = new FloorGroupId(this.floorGroupId);
      }

      const trainingMenu = new TrainingMenu(id,
          floorGroupId,
          null,
          this.name,
          this.trainingMinutes,
          this.color,
          this.note);
      this.$emit('changeTrainingMenu', trainingMenu);
    }
  }
});
