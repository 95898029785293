import FloorGroupId from "@/domains/floor_group/value_object/FloorGroupId";
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import TrainingMenusClient from "@/http_clients/admin/TrainingMenusClient";
import TrainingMenuId from "@/domains/training_menu/value_object/TrainingMenuId";

export class TrainingMenu {

  public BASE_TRAINING_MINUTES = 30;

  public id: TrainingMenuId | null;
  public floorGroupId: FloorGroupId | null;
  public floorGroupName: string | null;
  public name: string | null;
  public trainingMinutes: number | null;
  public note: string | null;
  public color: string | null;

  constructor(id: TrainingMenuId | null,
              floorGroupId: FloorGroupId | null,
              floorGroupName: string | null,
              name: string | null,
              trainingMinutes: number | null,
              color: string | null,
              note: string | null) {
    this.id = id;
    this.floorGroupId = floorGroupId;
    this.floorGroupName = floorGroupName;
    this.name = name;
    this.trainingMinutes = trainingMinutes;
    this.color = color;
    this.note = note;
  }

  public getTrainingMinutesSelectList():number[] {
    return [...Array(4)].map((_, i) => (i + 1) * this.BASE_TRAINING_MINUTES);
  }

  public getColorSelectList():string[] {
    return [
      "#ffffff",
      "#f4b2ba",
      "#fdd6b3",
      "#fff6c7",
      "#f0f1c6",
      "#d1c9df",
      "#e2bfd4",
      "#fadce2",
      "#fce4d6",
      "#e8e8e8",
    ];
  }

  public async insert(): Promise<ResponseInterface> {
    const trainingMenusClient: TrainingMenusClient = new TrainingMenusClient();
    return await trainingMenusClient.insert(this);
  }

  public async update(): Promise<ResponseInterface> {
    const trainingMenusClient: TrainingMenusClient = new TrainingMenusClient();
    return await trainingMenusClient.update(this);
  }

  public async delete(): Promise<ResponseInterface> {
    const trainingMenusClient: TrainingMenusClient = new TrainingMenusClient();
    return await trainingMenusClient.delete(this);
  }

  public getFormData(): FormData {
    const formData: FormData = new FormData();

    if (this.floorGroupId != null) {
      formData.append("floor_group_id", this.floorGroupId.getId().toString());
    }
    if (this.name != null) {
      formData.append("name", this.name);
    }
    if (this.trainingMinutes != null) {
      formData.append("training_minutes", this.trainingMinutes.toString());
    }
    if (this.note != null) {
      formData.append("note", this.note);
    }
    if (this.color != null) {
      formData.append("color", this.color);
    }
    return formData;
  }
}