
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import Pager from "@/components/common/PagerComponent.vue";
import TrainingMenusClient from "@/http_clients/admin/TrainingMenusClient";
import {TrainingMenus} from "@/domains/training_menu/TrainingMenus";

export default defineComponent({
  name: 'TrainingMenuIndex',
  components: {
    Base,
    Pager
  },
  data() {
    return {
      trainingMenus: new TrainingMenus([]) as TrainingMenus,
    }
  },
  watch: {
    async $route() {
      let page = null;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page as string);
      }
      const trainingMenusClient = new TrainingMenusClient();
      this.trainingMenus = await trainingMenusClient.getList(page);
    }
  },
  async created() {
    let page = null;
    if (this.$route.query.page) {
      page = parseInt(this.$route.query.page as string);
    }
    const trainingMenusClient = new TrainingMenusClient();
    this.trainingMenus = await trainingMenusClient.getList(page);
  },
  methods: {
    async changePage(page: number) {
      this.$router.push({query: { page: page } });
    }
  }
});
