
import { defineComponent } from 'vue';
import {MyAccount} from "@/domains/MyAccount";

export default defineComponent({
  name: 'MenuComponent',
  methods: {
    async signOut() {
      const myAccount = new MyAccount();
      if (await myAccount.signOutCoach()) {
        this.$router.push('/admin/sign_in');
      }
    },
  },
});
