
import { defineComponent } from 'vue';
import ErrorMessage from '@/components/common/ErrorMessageComponent.vue';
import {MyAccount} from "@/domains/MyAccount";

export default defineComponent({
  name: 'AdminSignIn',
  components: {
    ErrorMessage,
  },
  data() {
    return {
      userId: '' as string,
      password: '' as string,
      errorMessage: null as string | null,
    }
  },
  methods: {
    async logIn() {
      const myAccount = new MyAccount();
      if (await myAccount.signInCoach(this.userId, this.password)) {
        this.$router.push('/admin');
      } else {
        this.errorMessage = "ログインに失敗しました。";
      }

    },
  },
});
