import FloorId from "@/domains/floor/value_object/FloorId";
import FloorGroupId from "@/domains/floor_group/value_object/FloorGroupId";

export class Floor {
  public id: FloorId;
  public floorGroupId: FloorGroupId;
  public name: string;
  public color: string;

  constructor(id: FloorId, floorGroupId: FloorGroupId, name: string, color: string) {
    this.id = id;
    this.floorGroupId = floorGroupId;
    this.name = name;
    this.color = color;
  }
}