<template>
  <section class="admin-students-input">
    <div class="input-block">
      <span>会員番号:</span>
      {{userId}}
    </div>
    <div class="input-block">
      <span>パスワード:</span>
      <input type="password" v-model="password" v-if="editMode"/>
      <input type="password" v-model="password" required v-else/>
    </div>
    <div class="input-block">
      <span>氏名:</span>
      <input type="text" v-model="name" required/>
    </div>
    <div class="input-block">
      <span>フリガナ:</span>
      <input type="text" v-model="nameKana" required/>
    </div>
    <div class="input-block">
      <span>郵便番号:</span>
      <input type="text" v-model="postalCode"/>
    </div>
    <div class="input-block">
      <span>住所:</span>
      <input type="text" v-model="address" class="long-input"/>
    </div>
    <div class="input-block">
      <span>電話番号1:</span>
      <input type="text" v-model="tel1"/>
    </div>
    <div class="input-block">
      <span>電話番号2:</span>
      <input type="text" v-model="tel2"/>
    </div>
    <div class="input-block">
      <span>email:</span>
      <input type="email" v-model="email" class="long-input"/>
    </div>
    <div class="input-block">
      <span>生年月日:</span>
      {{displayBirthday()}}
    </div>
    <div class="input-block">
      <span>性別:</span>
      {{displayGender()}}
    </div>
    <div class="input-block">
      <span>職業:</span>
      <select v-model="business">
        <option></option>
        <option value="elementary_school_student_low">小学生（1-3年生）</option>
        <option value="elementary_school_student_high">小学生（4-6年生）</option>
        <option value="junior_high_school_student">中学生</option>
        <option value="senior_high_school_student">高校生</option>
        <option value="university_student">大学生</option>
        <option value="adult_athlete">社会人アスリート</option>
        <option value="professional_athlete">プロアスリート</option>
        <option value="general">一般</option>
      </select>
    </div>
    <div class="input-block">
      <span>メール配信:</span>
      <div>
        <label>
          <input type="radio" :value="true" v-model="deliveryMail"/>配信
        </label>
        <label>
          <input type="radio" :value="false" v-model="deliveryMail"/>不要
        </label>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {Student} from "@/domains/student/Student";
import StudentUserId from "@/domains/student/value_object/StudentUserId";
import moment from "moment";

export default defineComponent({
  name: 'AdminStudentsInputComponent',
  props: {
    student: Student,
    editMode: Boolean,
  },
  data() {
    return {
      userId: null as string | null,
      password: null as string | null,
      name: null as string | null,
      nameKana: null as string | null,
      postalCode: null as string | null,
      address: null as string | null,
      tel1: null as string | null,
      tel2: null as string | null,
      email: null as string | null,
      birthday: null as string | null,
      gender: "male" as string,
      business: null as string | null,
      motivationComingStore: null as string | null,
      note1: null as string | null,
      note2: null as string | null,
      deliveryMail: true as boolean,
    }
  },
  watch: {
    student: function () {
      if (this.student != null) {
        if (this.student.userId != null) {
          this.userId = this.student.userId.getId();
        }
        this.name = this.student.name;
        this.nameKana = this.student.nameKana;
        this.postalCode = this.student.postalCode;
        this.address = this.student.address;
        this.tel1 = this.student.tel1;
        this.tel2 = this.student.tel2;
        this.email = this.student.email;
        this.birthday = this.student.birthday;
        this.gender = this.student.gender;
        this.business = this.student.business;
        this.motivationComingStore = this.student.motivationComingStore;
        this.note1 = this.student.note1;
        this.note2 = this.student.note2;
        this.deliveryMail = this.student.deliveryMail;
      }
    },
    password: function () {
      this.setStudent();
    },
    name: function () {
      this.setStudent();
    },
    nameKana: function () {
      this.setStudent();
    },
    postalCode: function () {
      this.setStudent();
    },
    address: function () {
      this.setStudent();
    },
    tel1: function () {
      this.setStudent();
    },
    tel2: function () {
      this.setStudent();
    },
    email: function () {
      this.setStudent();
    },
    business: function () {
      this.setStudent();
    },
    deliveryMail: function () {
      this.setStudent();
    },
  },
  methods: {
    displayGender() {
      if (this.gender == 'male') {
        return '男性'
      }
      if (this.gender == 'female') {
        return '女性'
      }
      return null;
    },
    displayBirthday() {
      if (this.birthday == null) {
        return null;
      }
      return moment(this.birthday).format('Y年M月D日');
    },
    setStudent() {
      let id = null;
      if (this.student != null) {
        id = this.student.id;
      }
      let userId = null;
      if (this.userId != null) {
        userId = new StudentUserId(this.userId);
      }

      let comingStoreCount = null;
      let firstComingStoreDate = null;
      let lastComingStoreDate = null;
      if (this.student != null) {
        comingStoreCount = this.student.comingStoreCount;
        firstComingStoreDate = this.student.firstComingStoreDate;
        lastComingStoreDate = this.student.lastComingStoreDate;
      }

      const student = new Student(id,
          userId,
          this.password,
          this.name,
          this.nameKana,
          this.postalCode,
          this.address,
          this.tel1,
          this.tel2,
          this.email,
          this.birthday,
          this.gender,
          this.business,
          this.note1,
          this.note2,
          this.deliveryMail,
          this.motivationComingStore,
          comingStoreCount,
          firstComingStoreDate,
          lastComingStoreDate,
      );
      this.$emit('changeStudent', student);
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.input-block {
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  span {
    display: inline-block;
    flex: 0 0 130px;
  }

  .long-input {
    width: 100%;
  }
}

.input-textarea-block {
  align-items: flex-start;
}
</style>
