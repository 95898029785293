import {Coach} from "@/domains/coach/Coach";
import FloorGroupId from "@/domains/floor_group/value_object/FloorGroupId";
import {CoachSchedules} from "@/domains/coach_schedule/CoachSchedules";
import CoachId from "@/domains/coach/value_object/CoachId";
import {LessonReservations} from "@/domains/lesson_reservation/LessonReservations";
import LessonReservationId from "@/domains/lesson_reservation/value_object/LessonReservationId";

export class Coaches {
  public list: Coach[];
  public totalPage: number;
  public currentPage: number;

  constructor(list: Coach[], totalPage = 1, currentPage= 1) {
    this.list = list;
    this.totalPage = totalPage;
    this.currentPage = currentPage;
  }

  public isPresent(): boolean {
    return !this.isEmpty();
  }

  public isEmpty(): boolean {
    return this.list.length == 0;
  }

  public find(coachId: CoachId): Coach | null {
    for (let i = 0; i < this.list.length; i++) {
      const coach = this.list[i];
      if (coach.id == null) {
        continue;
      }
      if (coach.id.getId() == coachId.getId()) {
        return coach;
      }
    }
    return null;
  }

  public filterNotHoliday(coachSchedules: CoachSchedules): Coaches {
    const notHolidayCoaches = this.list.filter((coach) => {
      if (coach.id == null) {
        return false;
      }
      const coachCoachSchedules = coachSchedules.filterCoach(coach.id);
      return !coachCoachSchedules.existHoliday();
    });
    return new Coaches(notHolidayCoaches);
  }

  public filterFloorGroup(floorGroupId: FloorGroupId): Coaches {
    const floorGroupCoaches = this.list.filter((coach) => {
      if (coach.floorGroupId == null) {
        return false;
      }
      return coach.floorGroupId.getId() == floorGroupId.getId();
    });
    return new Coaches(floorGroupCoaches);
  }

  public existFreeSchedule(coachSchedules: CoachSchedules, floorGroupLessonReservations: LessonReservations, editLessonReservationId: LessonReservationId | null): boolean {
    let coaches = this.list.filter((coach) => {
      if (coach.id == null) {
        return false;
      }
      for (let i = 0; i < coachSchedules.list.length; i++) {
        const coachSchedule = coachSchedules.list[i];
        if (coachSchedule.coachId == null) {
          continue;
        }
        if (coach.id.getId() == coachSchedule.coachId.getId()) {
          return false;
        }
      }
      return true;
    });

    coaches = coaches.filter((coach) => {
      if (coach.id == null) {
        return false;
      }
      for (let i = 0; i < floorGroupLessonReservations.list.length; i++) {
        const lessonReservation = floorGroupLessonReservations.list[i];
        if (lessonReservation.coachId == null) {
          continue;
        }
        if (editLessonReservationId != null && lessonReservation.id != null && editLessonReservationId.getId() == lessonReservation.id.getId()) {
          continue;
        }
        if (coach.id.getId() == lessonReservation.coachId.getId()) {
          return false;
        }
      }
      return true;
    });

    return coaches.length != 0;
  }
}
