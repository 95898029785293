import HttpClientBase from './HttpClientBase';
import {AxiosResponse} from "axios";
import {CoachSchedules} from "@/domains/coach_schedule/CoachSchedules";
import CoachScheduleId from "@/domains/coach_schedule/value_object/CoachScheduleId";
import {CoachSchedule} from "@/domains/coach_schedule/CoachSchedule";
import CoachId from "@/domains/coach/value_object/CoachId";
import moment from 'moment';
import FloorGroupId from "@/domains/floor_group/value_object/FloorGroupId";

export default class CoachSchedulesClient extends HttpClientBase {

  public async getTargetDateSchedules(targetDate= null as string | null): Promise<CoachSchedules> {
    const param = this.getAccessTokenParam();
    if (targetDate != null) {
      param["target_date"] = targetDate;
    }
    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/coach_schedules/target_date_schedules', param);

    const coachSchedules: CoachSchedule[] = [];

    response.data.coach_schedules.forEach((coachScheduleResponse) => {

      let coachId = null;
      if (coachScheduleResponse.coach_id != null) {
        coachId = new CoachId(coachScheduleResponse.coach_id);
      }

      let floorGroupId = null;
      if (coachScheduleResponse.floor_group_id != null) {
        floorGroupId = new FloorGroupId(coachScheduleResponse.floor_group_id);
      }

      coachSchedules.push(new CoachSchedule(
        new CoachScheduleId(coachScheduleResponse.id),
        coachId,
        null,
        coachScheduleResponse.title,
        coachScheduleResponse.start_date,
        moment(coachScheduleResponse.start_time).format("HH:mm"),
        moment(coachScheduleResponse.end_time).format("HH:mm"),
        coachScheduleResponse.end_repeat_date,
        coachScheduleResponse.holiday,
        coachScheduleResponse.repeat,
        coachScheduleResponse.repeat_monday,
        coachScheduleResponse.repeat_tuesday,
        coachScheduleResponse.repeat_wednesday,
        coachScheduleResponse.repeat_thursday,
        coachScheduleResponse.repeat_friday,
        coachScheduleResponse.repeat_saturday,
        coachScheduleResponse.repeat_sunday,
        coachScheduleResponse.note,
        floorGroupId,
      ));
    });

    return new CoachSchedules(coachSchedules);
  }

  public async getTargetDateHolidaySchedules(targetDate= null as string | null): Promise<CoachSchedules> {
    const param = this.getAccessTokenParam();
    if (targetDate != null) {
      param["target_date"] = targetDate;
    }
    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/coach_schedules/target_date_holiday_schedules', param);

    const coachSchedules: CoachSchedule[] = [];

    response.data.coach_schedules.forEach((coachScheduleResponse) => {

      let coachId = null;
      if (coachScheduleResponse.coach_id != null) {
        coachId = new CoachId(coachScheduleResponse.coach_id);
      }

      let floorGroupId = null;
      if (coachScheduleResponse.floor_group_id != null) {
        floorGroupId = new FloorGroupId(coachScheduleResponse.floor_group_id);
      }

      coachSchedules.push(new CoachSchedule(
        new CoachScheduleId(coachScheduleResponse.id),
        coachId,
        null,
        coachScheduleResponse.title,
        coachScheduleResponse.start_date,
        moment(coachScheduleResponse.start_time).format("HH:mm"),
        moment(coachScheduleResponse.end_time).format("HH:mm"),
        coachScheduleResponse.end_repeat_date,
        coachScheduleResponse.holiday,
        coachScheduleResponse.repeat,
        coachScheduleResponse.repeat_monday,
        coachScheduleResponse.repeat_tuesday,
        coachScheduleResponse.repeat_wednesday,
        coachScheduleResponse.repeat_thursday,
        coachScheduleResponse.repeat_friday,
        coachScheduleResponse.repeat_saturday,
        coachScheduleResponse.repeat_sunday,
        coachScheduleResponse.note,
        floorGroupId,
      ));
    });

    return new CoachSchedules(coachSchedules);
  }

  public async getTargetDateNotHolidaySchedules(targetDate= null as string | null): Promise<CoachSchedules> {
    const param = this.getAccessTokenParam();
    if (targetDate != null) {
      param["target_date"] = targetDate;
    }
    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/coach_schedules/target_date_not_holiday_schedules', param);

    const coachSchedules: CoachSchedule[] = [];

    response.data.coach_schedules.forEach((coachScheduleResponse) => {

      let coachId = null;
      if (coachScheduleResponse.coach_id != null) {
        coachId = new CoachId(coachScheduleResponse.coach_id);
      }

      let floorGroupId = null;
      if (coachScheduleResponse.floor_group_id != null) {
        floorGroupId = new FloorGroupId(coachScheduleResponse.floor_group_id);
      }

      coachSchedules.push(new CoachSchedule(
        new CoachScheduleId(coachScheduleResponse.id),
        coachId,
        null,
        coachScheduleResponse.title,
        coachScheduleResponse.start_date,
        moment(coachScheduleResponse.start_time).format("HH:mm"),
        moment(coachScheduleResponse.end_time).format("HH:mm"),
        coachScheduleResponse.end_repeat_date,
        coachScheduleResponse.holiday,
        coachScheduleResponse.repeat,
        coachScheduleResponse.repeat_monday,
        coachScheduleResponse.repeat_tuesday,
        coachScheduleResponse.repeat_wednesday,
        coachScheduleResponse.repeat_thursday,
        coachScheduleResponse.repeat_friday,
        coachScheduleResponse.repeat_saturday,
        coachScheduleResponse.repeat_sunday,
        coachScheduleResponse.note,
        floorGroupId,
      ));
    });

    return new CoachSchedules(coachSchedules);
  }
}

export interface IndexResponseInterface {
  meta: {
    total_page: number,
    current_page: number,
  };
  coach_schedules: CoachScheduleResponseInterface[];
}

export interface CoachScheduleResponseInterface {
  id: number;
  coach_id: number;
  title: string;
  start_date: string;
  start_time: string;
  end_time: string;
  end_repeat_date: string;
  holiday: boolean;
  repeat: boolean;
  repeat_monday: boolean;
  repeat_tuesday: boolean;
  repeat_wednesday: boolean;
  repeat_thursday: boolean;
  repeat_friday: boolean;
  repeat_saturday: boolean;
  repeat_sunday: boolean;
  note: string;
  is_delete: boolean;
  floor_group_id: number;
}
