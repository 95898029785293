
import {defineComponent} from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import moment from 'moment';
import {ReservationHistories} from "@/domains/reservation_history/ReservationHistories";
import ReservationHistoriesClient from "@/http_clients/admin/ReservationHistoriesClient";
import Pager from "@/components/common/PagerComponent.vue";

export default defineComponent({
  name: 'LessonReservationIndex',
  components: {
    Base,
    Pager,
  },
  data() {
    return {
      showTargetYearMonthFlg: false as boolean,
      targetYearMonth: moment().format("YYYY-MM") as string,
      firstYear: '2021' as string,
      targetYear: moment().format("YYYY") as string,
      targetMonth: moment().format("MM") as string,
      reservationHistories: new ReservationHistories([]) as ReservationHistories,
    }
  },
  watch: {
    async $route() {
      let page = null;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page as string);
      }
      if (this.$route.query.target_year_month) {
        this.targetYearMonth = this.$route.query.target_year_month as string;
        this.targetYear = moment(this.$route.query.target_year_month as string, "YYYY-MM").format("YYYY") as string
        this.targetMonth = moment(this.$route.query.target_year_month as string, "YYYY-MM").format("MM") as string
      }
      const reservationHistoriesClient = new ReservationHistoriesClient();
      this.reservationHistories = await reservationHistoriesClient.getList(this.targetYearMonth, page);
    }
  },
  async created() {
    let page = null;
    if (this.$route.query.page) {
      page = parseInt(this.$route.query.page as string);
    }
    if (this.$route.query.target_year_month) {
      this.targetYearMonth = this.$route.query.target_year_month as string;
      this.targetYear = moment(this.$route.query.target_year_month as string, "YYYY-MM").format("YYYY") as string
      this.targetMonth = moment(this.$route.query.target_year_month as string, "YYYY-MM").format("MM") as string
    }
    const reservationHistoriesClient = new ReservationHistoriesClient();
    this.reservationHistories = await reservationHistoriesClient.getList(this.targetYearMonth, page);
  },
  methods: {
    async changePage(page: number) {
      this.$router.push({query: { target_year_month: this.targetYearMonth, page: page } });
    },
    displayTargetDate() {
      return moment(this.targetYear + '-' + this.targetMonth).format('Y月M月');
    },
    selectYearList() {
      const yearList = [];
      for(let year = parseInt(this.firstYear); year <= parseInt(moment().format("YYYY")); year++) {
        yearList.push(year.toString());
      }
      return yearList;
    },
    applyTargetYearMonth() {
      this.targetYearMonth = this.targetYear + '-' + this.targetMonth;
      this.showTargetYearMonthFlg = false;
    },
    showTargetYearMonth() {
      this.showTargetYearMonthFlg = true;
    },
    searchReservationHistory() {
      let page = 1;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page as string);
      }
      this.$router.push({query: { target_year_month: this.targetYearMonth, page: page } });
    },
    async csvDownload() {
      const reservationHistoriesClient = new ReservationHistoriesClient();
      const csv = await reservationHistoriesClient.downloadCsv(this.targetYearMonth);
      this.downloadCsvBlob(csv, "reservation_" +  moment(this.targetYearMonth, "YYYY-MM").format("YYYY_MM") + ".csv");
    },
    downloadCsvBlob(blob: Blob, fileName: string) {
      const url = URL.createObjectURL(blob);
      const linkEl = document.createElement('a');
      linkEl.href = url;
      linkEl.setAttribute('download',fileName);
      document.body.appendChild(linkEl);
      linkEl.click();
      URL.revokeObjectURL(url);
      linkEl?.parentNode?.removeChild(linkEl);
    },
  }
});
