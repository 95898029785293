import HttpClientBase from '../HttpClientBase';
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import {AxiosResponse} from "axios";
import CoachId from "@/domains/coach/value_object/CoachId";
import moment from 'moment';
import LessonReservationId from "@/domains/lesson_reservation/value_object/LessonReservationId";
import {LessonReservation} from "@/domains/lesson_reservation/LessonReservation";
import StudentId from "@/domains/student/value_object/StudentId";
import FloorId from "@/domains/floor/value_object/FloorId";
import TrainingMenuId from "@/domains/training_menu/value_object/TrainingMenuId";
import {LessonReservations} from "@/domains/lesson_reservation/LessonReservations";
import StudentUserId from "@/domains/student/value_object/StudentUserId";
import FloorGroupId from "@/domains/floor_group/value_object/FloorGroupId";

export default class LessonReservationsClient extends HttpClientBase {

  public async getData(lessonReservationId: LessonReservationId): Promise<LessonReservation> {
    const param = this.getAccessTokenParam();
    const response: AxiosResponse<ShowResponseInterface> = await this.getRequest('/admin/lesson_reservations/' + lessonReservationId.getId(), param);

    const lessonReservationResponse = response.data.lesson_reservation;

    let studentUserId = null;
    if (lessonReservationResponse.student_user_id != null) {
      studentUserId = new StudentUserId(lessonReservationResponse.student_user_id);
    }
    let member = false;
    if (studentUserId != null) {
      member = true;
    }

    let studentId = null;
    if (lessonReservationResponse.student_id != null) {
      studentId = new StudentId(lessonReservationResponse.student_id);
    }

    let floorId = null;
    if (lessonReservationResponse.floor_id != null) {
      floorId = new FloorId(lessonReservationResponse.floor_id);
    }
    let floorGroupId = null;
    if (lessonReservationResponse.floor_group_id != null) {
      floorGroupId = new FloorGroupId(lessonReservationResponse.floor_group_id);
    }
    let coachId = null;
    if (lessonReservationResponse.coach_id != null) {
      coachId = new CoachId(lessonReservationResponse.coach_id);
    }
    let trainingMenuId1 = null;
    if (lessonReservationResponse.training_menu_id1 != null) {
      trainingMenuId1 = new TrainingMenuId(lessonReservationResponse.training_menu_id1);
    }
    let trainingMenuId2 = null;
    if (lessonReservationResponse.training_menu_id2 != null) {
      trainingMenuId2 = new TrainingMenuId(lessonReservationResponse.training_menu_id2);
    }

    return new LessonReservation(
      new LessonReservationId(lessonReservationResponse.id),
      member,
      studentUserId,
      studentId,
      lessonReservationResponse.student_name,
      lessonReservationResponse.student_name_kana,
      lessonReservationResponse.student_tel,
      lessonReservationResponse.student_age,
      floorId,
      lessonReservationResponse.floor_name,
      floorGroupId,
      lessonReservationResponse.start_date,
      moment(lessonReservationResponse.start_time).format("HH:mm"),
      moment(lessonReservationResponse.end_time).format("HH:mm"),
      coachId,
      lessonReservationResponse.coach_name,
      trainingMenuId1,
      lessonReservationResponse.training_menu1_name,
      lessonReservationResponse.training_menu1_color,
      trainingMenuId2,
      lessonReservationResponse.training_menu2_name,
      lessonReservationResponse.training_menu2_color,
      lessonReservationResponse.note,
    );
  }

  public async getTargetDateSchedules(targetDate= null as string | null): Promise<LessonReservations> {
    const param = this.getAccessTokenParam();
    if (targetDate != null) {
      param["target_date"] = targetDate;
    }
    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/admin/lesson_reservations/target_date_schedules', param);

    const lessonReservations: LessonReservation[] = [];

    response.data.lesson_reservations.forEach((lessonReservationResponse) => {

      let studentId = null;
      if (lessonReservationResponse.student_id != null) {
        studentId = new StudentId(lessonReservationResponse.student_id);
      }

      let studentUserId = null;
      if (lessonReservationResponse.student_user_id != null) {
        studentUserId = new StudentUserId(lessonReservationResponse.student_user_id);
      }
      let member = false;
      if (studentUserId != null) {
        member = true;
      }

      let floorId = null;
      if (lessonReservationResponse.floor_id != null) {
        floorId = new FloorId(lessonReservationResponse.floor_id);
      }
      let floorGroupId = null;
      if (lessonReservationResponse.floor_group_id != null) {
        floorGroupId = new FloorGroupId(lessonReservationResponse.floor_group_id);
      }
      let coachId = null;
      if (lessonReservationResponse.coach_id != null) {
        coachId = new CoachId(lessonReservationResponse.coach_id);
      }
      let trainingMenuId1 = null;
      if (lessonReservationResponse.training_menu_id1 != null) {
        trainingMenuId1 = new TrainingMenuId(lessonReservationResponse.training_menu_id1);
      }
      let trainingMenuId2 = null;
      if (lessonReservationResponse.training_menu_id2 != null) {
        trainingMenuId2 = new TrainingMenuId(lessonReservationResponse.training_menu_id2);
      }

      lessonReservations.push(new LessonReservation(
        new LessonReservationId(lessonReservationResponse.id),
        member,
        studentUserId,
        studentId,
        lessonReservationResponse.student_name,
        lessonReservationResponse.student_name_kana,
        lessonReservationResponse.student_tel,
        lessonReservationResponse.student_age,
        floorId,
        lessonReservationResponse.floor_name,
        floorGroupId,
        lessonReservationResponse.start_date,
        moment(lessonReservationResponse.start_time).format("HH:mm"),
        moment(lessonReservationResponse.end_time).format("HH:mm"),
        coachId,
        lessonReservationResponse.coach_name,
        trainingMenuId1,
        lessonReservationResponse.training_menu1_name,
        lessonReservationResponse.training_menu1_color,
        trainingMenuId2,
        lessonReservationResponse.training_menu2_name,
        lessonReservationResponse.training_menu2_color,
        lessonReservationResponse.note,
      ));
    });

    return new LessonReservations(lessonReservations);
  }

  public async getTargetDateFloorGroupSchedules(targetDate= null as string | null, floorGroupId: FloorGroupId): Promise<LessonReservations> {
    const param = this.getAccessTokenParam();
    if (targetDate != null) {
      param["target_date"] = targetDate;
    }
    param["floor_group_id"] = floorGroupId.getId().toString();

    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/admin/lesson_reservations/target_date_floor_group_schedules', param);

    const lessonReservations: LessonReservation[] = [];

    response.data.lesson_reservations.forEach((lessonReservationResponse) => {

      let studentId = null;
      if (lessonReservationResponse.student_id != null) {
        studentId = new StudentId(lessonReservationResponse.student_id);
      }

      let studentUserId = null;
      if (lessonReservationResponse.student_user_id != null) {
        studentUserId = new StudentUserId(lessonReservationResponse.student_user_id);
      }
      let member = false;
      if (studentUserId != null) {
        member = true;
      }

      let floorId = null;
      if (lessonReservationResponse.floor_id != null) {
        floorId = new FloorId(lessonReservationResponse.floor_id);
      }
      let floorGroupId = null;
      if (lessonReservationResponse.floor_group_id != null) {
        floorGroupId = new FloorGroupId(lessonReservationResponse.floor_group_id);
      }
      let coachId = null;
      if (lessonReservationResponse.coach_id != null) {
        coachId = new CoachId(lessonReservationResponse.coach_id);
      }
      let trainingMenuId1 = null;
      if (lessonReservationResponse.training_menu_id1 != null) {
        trainingMenuId1 = new TrainingMenuId(lessonReservationResponse.training_menu_id1);
      }
      let trainingMenuId2 = null;
      if (lessonReservationResponse.training_menu_id2 != null) {
        trainingMenuId2 = new TrainingMenuId(lessonReservationResponse.training_menu_id2);
      }

      lessonReservations.push(new LessonReservation(
        new LessonReservationId(lessonReservationResponse.id),
        member,
        studentUserId,
        studentId,
        lessonReservationResponse.student_name,
        lessonReservationResponse.student_name_kana,
        lessonReservationResponse.student_tel,
        lessonReservationResponse.student_age,
        floorId,
        lessonReservationResponse.floor_name,
        floorGroupId,
        lessonReservationResponse.start_date,
        moment(lessonReservationResponse.start_time).format("HH:mm"),
        moment(lessonReservationResponse.end_time).format("HH:mm"),
        coachId,
        lessonReservationResponse.coach_name,
        trainingMenuId1,
        lessonReservationResponse.training_menu1_name,
        lessonReservationResponse.training_menu1_color,
        trainingMenuId2,
        lessonReservationResponse.training_menu2_name,
        lessonReservationResponse.training_menu2_color,
        lessonReservationResponse.note,
      ));
    });

    return new LessonReservations(lessonReservations);
  }

  public async getStudentSchedules(studentId: StudentId): Promise<LessonReservations> {
    const param = this.getAccessTokenParam();
    if (studentId != null) {
      param["student_id"] = studentId.getId().toString();
    }
    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/admin/lesson_reservations/student_schedules', param);

    const lessonReservations: LessonReservation[] = [];
    const totalPage = response.data.meta.total_page;
    const currentPage = response.data.meta.current_page;

    response.data.lesson_reservations.forEach((lessonReservationResponse) => {

      let studentId = null;
      if (lessonReservationResponse.student_id != null) {
        studentId = new StudentId(lessonReservationResponse.student_id);
      }

      let studentUserId = null;
      if (lessonReservationResponse.student_user_id != null) {
        studentUserId = new StudentUserId(lessonReservationResponse.student_user_id);
      }
      let member = false;
      if (studentUserId != null) {
        member = true;
      }

      let floorId = null;
      if (lessonReservationResponse.floor_id != null) {
        floorId = new FloorId(lessonReservationResponse.floor_id);
      }
      let floorGroupId = null;
      if (lessonReservationResponse.floor_group_id != null) {
        floorGroupId = new FloorGroupId(lessonReservationResponse.floor_group_id);
      }
      let coachId = null;
      if (lessonReservationResponse.coach_id != null) {
        coachId = new CoachId(lessonReservationResponse.coach_id);
      }
      let trainingMenuId1 = null;
      if (lessonReservationResponse.training_menu_id1 != null) {
        trainingMenuId1 = new TrainingMenuId(lessonReservationResponse.training_menu_id1);
      }
      let trainingMenuId2 = null;
      if (lessonReservationResponse.training_menu_id2 != null) {
        trainingMenuId2 = new TrainingMenuId(lessonReservationResponse.training_menu_id2);
      }

      lessonReservations.push(new LessonReservation(
        new LessonReservationId(lessonReservationResponse.id),
        member,
        studentUserId,
        studentId,
        lessonReservationResponse.student_name,
        lessonReservationResponse.student_name_kana,
        lessonReservationResponse.student_tel,
        lessonReservationResponse.student_age,
        floorId,
        lessonReservationResponse.floor_name,
        floorGroupId,
        lessonReservationResponse.start_date,
        moment(lessonReservationResponse.start_time).format("HH:mm"),
        moment(lessonReservationResponse.end_time).format("HH:mm"),
        coachId,
        lessonReservationResponse.coach_name,
        trainingMenuId1,
        lessonReservationResponse.training_menu1_name,
        lessonReservationResponse.training_menu1_color,
        trainingMenuId2,
        lessonReservationResponse.training_menu2_name,
        lessonReservationResponse.training_menu2_color,
        lessonReservationResponse.note,
      ));
    });

    return new LessonReservations(lessonReservations, totalPage, currentPage);
  }

  public async insert(lessonReservation: LessonReservation): Promise<ResponseInterface> {
    const formData: FormData = this.getAccessTokenForm();
    lessonReservation.getFormData().forEach((data, key) => {
      formData.append("lesson_reservation[" + key + "]", data);
    });

    try {
      await this.postRequest('/admin/lesson_reservations', formData);
    } catch (error) {
      console.log(error.response);
      return {
        result: false,
        status: error.response.status,
        errorCode: error.response.data.error_code,
      };
    }
    return {
      result: true,
      status: 204,
      errorCode: null,
    };
  }

  public async update(lessonReservation: LessonReservation): Promise<ResponseInterface> {
    const formData: FormData = this.getAccessTokenForm();
    lessonReservation.getFormData().forEach((data, key) => {
      formData.append("lesson_reservation[" + key + "]", data);
    });

    try {
      await this.putRequest('/admin/lesson_reservations/' + lessonReservation.id?.getId(), formData);
    } catch (error) {
      return {
        result: false,
        status: error.response.status,
        errorCode: error.response.data.error_code,
      };
    }
    return {
      result: true,
      status: 204,
      errorCode: null,
    };
  }

  public async delete(lessonReservation: LessonReservation): Promise<ResponseInterface> {
    const formData: FormData = this.getAccessTokenForm();

    try {
      await this.deleteRequest('/admin/lesson_reservations/' + lessonReservation.id?.getId(), formData);
    } catch (error) {
      return {
        result: false,
        status: error.response.status,
        errorCode: error.response.data.error_code,
      };
    }
    return {
      result: true,
      status: 204,
      errorCode: null,
    };
  }
}

export interface IndexResponseInterface {
  meta: {
    total_page: number,
    current_page: number,
  };
  lesson_reservations: lessonReservationResponseInterface[];
}

export interface ShowResponseInterface {
  lesson_reservation: lessonReservationResponseInterface;
}

export interface lessonReservationResponseInterface {
  id: number;
  student_user_id: string;
  student_id: number;
  student_name: string;
  student_name_kana: string;
  student_tel: string;
  student_age: string;
  floor_id: number;
  floor_name: string;
  floor_group_id: number;
  start_date: string;
  start_time: string ;
  end_time: string;
  coach_id: number;
  coach_name: string;
  training_menu_id1: number;
  training_menu1_name: string;
  training_menu1_color: string;
  training_menu_id2: number;
  training_menu2_name: string;
  training_menu2_color: string;
  note: string;
  is_delete: boolean;
}
