import axios, {AxiosInstance} from 'axios';

export default class HttpClient {

  private static instance: HttpClient;

  public BASE_URL = process.env.VUE_APP_API_BASE_URL;
  private httpClient: AxiosInstance;

  private constructor() {
    this.httpClient = axios.create({
      baseURL: this.BASE_URL,
      xsrfCookieName: 'XSRF-TOKEN',
      xsrfHeaderName: 'X-XSRF-TOKEN',
      withCredentials: true,
      headers: {
        "Access-Control-Allow-Origin": process.env.VUE_APP_FRONT_BASE_DOMAIN,
        "Access-Control-Allow-Methods": "GET, POST, PATCH, PUT, DELETE, OPTIONS",
        "Access-Control-Allow-Credentials": "true",
      },
    });
  }

  public static getInstance(): HttpClient {
    if (!this.instance) {
      this.instance = new HttpClient();
    }
    return this.instance;
  }

  public getAxiosInstance(): AxiosInstance {
    return this.httpClient;
  }

  public getRequest(path: string, params = {}) {
    return this.httpClient.get(`${path}`, {params});
  }

  public getBlobRequest(path: string, params = {}) {
    return this.httpClient.get(`${path}`, {params, responseType: 'blob'});
  }

  public postRequest(path: string, params = {}) {
    return this.httpClient.post(`${path}`, params);
  }

  public multipartPostRequest(path: string, params = {}) {
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
      },
    };
    return this.httpClient.post(`${path}`, params, config);
  }

  public putRequest(path: string, params = {}) {
    return this.httpClient.put(`${path}`, params);
  }

  public deleteRequest(path: string, params = {}) {
    return this.httpClient.delete(`${path}`, { data: params });
  }
}
