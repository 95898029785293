import HttpClientBase from './HttpClientBase';
import {AxiosResponse} from "axios";
import FloorGroupId from "@/domains/floor_group/value_object/FloorGroupId";
import {Floors} from "@/domains/floor/Floors";
import {Floor} from "@/domains/floor/Floor";
import FloorId from "@/domains/floor/value_object/FloorId";

export default class FloorsClient extends HttpClientBase {

  public async getFloors(): Promise<Floors> {
    const param = this.getAccessTokenParam();
    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/floors', param);

    const floors: Floor[] = [];
    response.data.floors.forEach((floor) => {
      floors.push(new Floor(new FloorId(floor.id), new FloorGroupId(floor.floor_group_id), floor.name, floor.color));
    });

    return new Floors(floors);
  }

  public async getStudentFloors(): Promise<Floors> {
    const param = this.getAccessTokenParam();
    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/floors', param);

    const floors: Floor[] = [];
    response.data.floors.forEach((floor) => {
      floors.push(new Floor(new FloorId(floor.id), new FloorGroupId(floor.floor_group_id), floor.name, floor.color));
    });

    return new Floors(floors);
  }
}

export interface IndexResponseInterface {
  floors: FloorResponseInterface[];
}

export interface FloorResponseInterface {
  id: number;
  floor_group_id: number;
  name: string;
  color: string;
}
