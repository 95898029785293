<template>
  <section class="admin-coaches-input">
    <div class="input-block">
      <span>ユーザーID:</span>
      <input type="text" v-model="userId" pattern="^[0-9A-Za-z]+$"  required/>
    </div>
    <div class="input-block">
      <span>パスワード:</span>
      <input type="password" v-model="password" v-if="editMode"/>
      <input type="password" v-model="password" required v-else/>
    </div>
    <div class="input-block">
      <span>氏名:</span>
      <input type="text" v-model="name" required/>
    </div>
    <div class="input-block">
      <span>フリガナ:</span>
      <input type="text" v-model="nameKana" required/>
    </div>
    <div class="input-block">
      <span>担当フロア:</span>
      <select v-model="floorGroupId">
        <option></option>
        <option v-for="floorGroup in floorGroups.list" :key="floorGroup.id.getId()" :value="floorGroup.id.getId()">{{floorGroup.name}}</option>
      </select>
    </div>
    <div class="input-block input-textarea-block">
      <span>備考:</span>
      <textarea v-model="note"/>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import FloorGroupsClient from "@/http_clients/admin/FloorGroupsClient";
import {FloorGroups} from "@/domains/floor_group/FloorGroups";
import {Coach} from "@/domains/coach/Coach";
import CoachUserId from "@/domains/coach/value_object/CoachUserId";
import FloorGroupId from "@/domains/floor_group/value_object/FloorGroupId";

export default defineComponent({
  name: 'AdminCoachesInputComponent',
  props: {
    coach: Coach,
    editMode: Boolean,
  },
  data() {
    return {
      userId: null as string | null,
      password: null as string | null,
      name: null as string | null,
      nameKana: null as string | null,
      floorGroupId: null as number | null,
      note: null as string | null,
      floorGroups: new FloorGroups([]) as FloorGroups,
    }
  },
  watch: {
    coach: function () {
      if (this.coach != null) {
        if (this.coach.userId != null) {
          this.userId = this.coach.userId.getId();
        }
        this.name = this.coach.name;
        this.nameKana = this.coach.nameKana;
        if (this.coach.floorGroupId != null) {
          this.floorGroupId = this.coach.floorGroupId.getId();
        }
        this.note = this.coach.note;
      }
    },
    userId: function () {
      this.setCoach();
    },
    password: function () {
      this.setCoach();
    },
    name: function () {
      this.setCoach();
    },
    nameKana: function () {
      this.setCoach();
    },
    floorGroupId: function () {
      this.setCoach();
    },
    note: function () {
      this.setCoach();
    },
  },
  async created() {
    const floorGroupsClient = new FloorGroupsClient();
    this.floorGroups = await floorGroupsClient.getFloorGroups();
  },
  methods: {
    setCoach() {
      let id = null;
      if (this.coach != null) {
        id = this.coach.id;
      }
      let userId = null;
      if (this.userId != null) {
        userId = new CoachUserId(this.userId);
      }
      let floorGroupId = null;
      if (this.floorGroupId != null) {
        floorGroupId = new FloorGroupId(this.floorGroupId);
      }

      const coach = new Coach(id,
          userId,
          this.password,
          this.name,
          this.nameKana,
          floorGroupId,
          null,
          null,
          this.note);
      this.$emit('changeCoach', coach);
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.input-block {
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  span {
    display: inline-block;
    flex: 0 0 130px;
  }
}

.input-textarea-block {
  align-items: flex-start;
}
</style>
