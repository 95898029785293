<template>
  <section class="pager">
    <div class="page" :class="{ active: currentPage === 1 }" @click="selectPage(1)">
      1
    </div>
    <div class="page-interval" v-if="displayPages.length !== 0 && 2 < displayPages[0]">
      ・・・
    </div>
    <div class="page" :class="{ active: currentPage === displayPage }" v-for="displayPage in displayPages" :key="displayPage" @click="selectPage(displayPage)">
      {{displayPage}}
    </div>
    <div class="page-interval" v-if="displayPages.length !== 0 && totalPage - 1 > displayPages[displayPages.length - 1]">
      ・・・
    </div>
    <div class="page" :class="{ active: currentPage === totalPage }" v-if="1 !== totalPage" @click="selectPage(totalPage)">
      {{totalPage}}
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PagerComponent',
  props: {
    totalPage: Number,
    currentPage: Number,
  },
  data() {
    return {
      displayPages: [] as number[],
    }
  },
  watch: {
    totalPage: function () {
      let totalPage = this.totalPage || 1;
      let currentPage = this.currentPage || 1;
      this.setDisplayPage(totalPage, currentPage);
    },
    currentPage: function () {
      let totalPage = this.totalPage || 1;
      let currentPage = this.currentPage || 1;
      this.setDisplayPage(totalPage, currentPage);
    }
  },
  created() {
    let totalPage = this.totalPage || 1;
    let currentPage = this.currentPage || 1;
    this.setDisplayPage(totalPage, currentPage);
  },
  methods: {
    setDisplayPage(totalPage: number, currentPage: number) {
      if (totalPage === 1) {
        this.displayPages = [];
        return;
      }

      let displayFirstPage = currentPage - 2;

      if (displayFirstPage < 2) {
        displayFirstPage = 2;
      }

      let displayLastPage = currentPage + 2;
      if (displayLastPage > totalPage - 1) {
        displayLastPage = totalPage - 1;
      }

      if (displayLastPage - displayFirstPage + 1 <= 0) {
        this.displayPages = [];
        return;
      }
      this.displayPages = [...Array(displayLastPage - displayFirstPage + 1)].map((_, i) => i + displayFirstPage)
    },
    selectPage(page: number) {
      this.$emit('selectPage', page);
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.pager {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 200px;
  font-size: 16px;
  height: 44px;
}

.page {
  margin: 5px;
  padding: 10px;
  cursor: pointer;
}

.page-interval {
  padding: 10px;
  margin: 0 5px;
}

.active {
  font-weight: bold;
}
</style>
