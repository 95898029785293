<template>
  <Base :errorMessage="errorMessage" :successMessage="successMessage" @resultSuccess="resultSuccess" >
    <section class="admin-coach-schedule-edit">
      <div class="header">
        <h1>スタッフの予定編集</h1>
      </div>
      <form @submit.prevent="saveCoachSchedule">
        <CoachSchedulesInput :coachSchedule="coachSchedule" @changeCoachSchedule="changeCoachSchedule"/>
        <div class="button-block">
          <button type="submit" class="approval-button" >更新</button>
          <button type="button" class="approval-button" @click="confirmDeleteCoachSchedule" >削除</button>
        </div>
      </form>
    </section>

    <div class="confirm-message-background" @click="cancel" v-show="deleteConfirm">
      <div class="confirm-message-dialog" @click="clickNothing">
        <form @submit.prevent="deleteCoachSchedule">
          <div class="message">
            予定を削除します。よろしいですか？
          </div>
          <div class="delete-select-block" v-if="repeat">
            <div class="delete-select">
              <label>
                <input type="radio" :value="true" v-model="allDelete"/>過去も含めて全ての予定を削除
              </label>
              <label>
                <input type="radio" :value="false" v-model="allDelete"/>以下の日付以降の予定を削除
              </label>
              <input type="date" v-model="endRepeatDate" required :disabled="allDelete"/>
            </div>
          </div>
          <div class="button-block">
            <button type="submit" >削除</button>
            <button type="button" @click="cancel">キャンセル</button>
          </div>
        </form>
      </div>
    </div>
  </Base>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import CoachSchedulesInput from '@/components/admin/coach_schedules/InputComponent.vue';
import {CoachSchedule} from "@/domains/coach_schedule/CoachSchedule";
import CoachSchedulesClient from "@/http_clients/admin/CoachSchedulesClient";
import CoachScheduleId from "@/domains/coach_schedule/value_object/CoachScheduleId";

export default defineComponent({
  name: 'CoachScheduleEdit',
  components: {
    Base,
    CoachSchedulesInput,
  },
  data() {
    return {
      errorMessage: null as string | null,
      successMessage: null as string | null,
      deleteConfirm: false as boolean,
      endRepeatDate: null as string | null,
      allDelete: true as boolean,
      repeat: false as boolean,
      coachSchedule: new CoachSchedule(null, null, null,null,null,null,null,null,null,
          null,null,null,null,null,null,null,null,
          null) as CoachSchedule,
      targetDate: null as string | null,
      basePage: null as string | null,
    }
  },
  async created() {
    const coachSchedulesClient = new CoachSchedulesClient();
    this.coachSchedule = await coachSchedulesClient.getData(new CoachScheduleId(parseInt(this.$route.params.coachScheduleId as string)));
    this.repeat = this.coachSchedule.repeat;

    if (this.$route.query.target_date) {
      this.targetDate = this.$route.query.target_date as string;
    }
    if (this.$route.query.base_page) {
      this.basePage = this.$route.query.base_page as string;
    }

  },
  methods: {
    changeCoachSchedule(coachSchedule: CoachSchedule) {
      this.coachSchedule = coachSchedule;
    },
    async saveCoachSchedule() {
      if (this.coachSchedule.validateStartTime()) {
        this.errorMessage = "開始時間は9:00以降を指定してください。";
        return;
      }
      if (this.coachSchedule.validateEndTime()) {
        this.errorMessage = "終了時間は22:00以前を指定してください。";
        return;
      }
      if (this.coachSchedule.validateStartTimeAndEndTime()) {
        this.errorMessage = "終了時間は開始時間より後の時間を指定してください。";
        return;
      }

      const response: ResponseInterface = await this.coachSchedule.update();
      if (response.result) {
        this.successMessage = "更新を完了しました。";
      } else {
        if (response.status == 400) {
          if (response.errorCode == 'exist_coach_schedule') {
            this.errorMessage = "既にスタッフに予定が登録されています。";
          } else if (response.errorCode == 'exist_lesson_reservation') {
            this.errorMessage = "既にスタッフにレッスンが登録されています。";
          }
        } else {
          this.errorMessage = "予定の更新に失敗しました。";
        }
      }
    },
    confirmDeleteCoachSchedule() {
      this.deleteConfirm = true;
    },
    async deleteCoachSchedule() {
      let endRepeatDate = null;
      if (!this.allDelete) {
        endRepeatDate = this.endRepeatDate;
      }
      const response: ResponseInterface = await this.coachSchedule.delete(endRepeatDate);
      this.deleteConfirm = false;
      if (response.result) {
        this.successMessage = "削除を完了しました。";
      } else {
        this.errorMessage = "予定の削除に失敗しました。";
      }
    },
    resultSuccess() {
      if (this.basePage != null && this.basePage == 'index') {
        // トップページより遷移してきた場合
        this.$router.push({name: 'AdminIndex', query: { target_date: this.targetDate }});
      } else {
        this.$router.push('/admin/coach_schedules');
      }
    },
    cancel() {
      this.deleteConfirm = false;
    },
    clickNothing(e: PointerEvent) {
      e.stopPropagation();
    }
  }
});
</script>

<style lang="scss" scoped>
.admin-coach-schedule-edit {
  max-width: 600px;
}

.button-block {
  margin-top: 60px;

  display: flex;
  justify-content: center;

  button {
    margin: 0 40px;
  }
}

.confirm-message-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.confirm-message-dialog {
  background-color: #ffffff;
  flex: 0 1 600px;
  margin: 40px;
  padding: 20px;
  border-radius: 10px;
}

.delete-select-block {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.delete-select {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  label {
    margin-bottom: 10px;
  }

  input[type="date"] {
    margin-left: 20px;
  }
}
</style>
