
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import Calendar from "@/components/common/CalendarComponent.vue";
import moment from "moment";
import {Coaches} from "@/domains/coach/Coaches";
import {CoachSchedules} from "@/domains/coach_schedule/CoachSchedules";
import {LessonReservations} from "@/domains/lesson_reservation/LessonReservations";
import CoachesClient from "@/http_clients/admin/CoachesClient";
import LessonReservationsClient from "@/http_clients/admin/LessonReservationsClient";
import CoachSchedulesClient from "@/http_clients/admin/CoachSchedulesClient";
import {Coach} from "@/domains/coach/Coach";
import {LessonReservation} from "@/domains/lesson_reservation/LessonReservation";
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import {CoachSchedule} from "@/domains/coach_schedule/CoachSchedule";

export default defineComponent({
  name: 'AdminIndex',
  components: {
    Base,
    Calendar,
  },
  data() {
    return {
      startTime: "9:00" as string,
      endTime: "22:00" as string,
      timeInterval: 30 as number,
      calenderHeight: 40 as number,
      scheduleTimes: [] as string[],
      targetDate: moment().format("YYYY-MM-DD") as string,
      coaches: new Coaches([]) as Coaches,
      coachSchedules: new CoachSchedules([]) as CoachSchedules,
      lessonReservations: new LessonReservations([]) as LessonReservations,
      coachScheduleReservations: [] as CoachScheduleReservation[],
      loading: false as boolean,
      dialogLessonReservation: null as LessonReservation | null,
      tempDialogLessonReservation: null as LessonReservation | null,
      dialogStudentLessonHistories: new LessonReservations([]) as LessonReservations,
      confirmMessage: null as string | null,
      successMessage: null as string | null,
      errorMessage: null as string | null,
    }
  },
  watch: {
    async $route() {
      this.loading = true;
      if (this.$router.currentRoute.value.path != "/admin") {
        return;
      }
      if (this.$route.query.target_date) {
        this.targetDate = this.$route.query.target_date as string;
      } else {
        this.targetDate = moment().format("YYYY-MM-DD");
      }
      await this.setSchedule();
      this.loading = false;
    }
  },
  async created() {
    if (this.$route.query.target_date) {
      this.targetDate = this.$route.query.target_date as string;
    }
    this.scheduleTimes = this.createScheduleTimes();

    const coachesClient = new CoachesClient();
    this.coaches = await coachesClient.getScheduleCoaches();

    await this.setSchedule();
  },
  methods: {
    async setSchedule() {
      const lessonReservationsClient = new LessonReservationsClient();
      this.lessonReservations = await lessonReservationsClient.getTargetDateSchedules(this.targetDate);

      const coachSchedulesClient = new CoachSchedulesClient();
      this.coachSchedules = await coachSchedulesClient.getTargetDateSchedules(this.targetDate);

      this.coachScheduleReservations = this.getCoachScheduleReservations();
    },
    changeTargetDate(targetDate: string) {
      this.targetDate = targetDate;
      this.$router.push({query: { target_date: this.targetDate } });
    },
    createScheduleTimes() {
      let timeMoment = moment(this.startTime, 'HH:mm');
      let endTimeMoment = moment(this.endTime, 'HH:mm');
      let times = [];

      while (timeMoment.isSameOrBefore(endTimeMoment)) {
        times.push(timeMoment.format('HH:mm'));
        timeMoment.add(this.timeInterval, 'minute');
      }
      return times;
    },
    getCoachScheduleReservations(): CoachScheduleReservation[] {
      return this.coaches.filterNotHoliday(this.coachSchedules).list.map((coach) => {
        let coachLessonReservations = new LessonReservations([]);
        let coachCoachSchedules = new CoachSchedules([]);
        if (coach.id != null) {
          coachLessonReservations = this.lessonReservations.filterCoach(coach.id);
          coachCoachSchedules = this.coachSchedules.filterCoach(coach.id);
        }

        const schedules: ScheduleReservation[] = this.scheduleTimes.map((scheduleTime) => {
          const lessonReservations = coachLessonReservations.filterScheduleTime(scheduleTime, this.timeInterval);
          const coachSchedules = coachCoachSchedules.filterScheduleTime(scheduleTime, this.timeInterval);

          return {
            scheduleTime: scheduleTime,
            lessonReservations: lessonReservations,
            coachSchedules: coachSchedules,
          };
        });

        return {
          coach: coach,
          schedules: schedules,
        };
      });
    },
    async displayDetail(lessonReservation: LessonReservation) {
      this.dialogLessonReservation = lessonReservation;

      if (lessonReservation.studentId != null) {
        const lessonReservationsClient: LessonReservationsClient = new LessonReservationsClient();
        this.dialogStudentLessonHistories = await lessonReservationsClient.getStudentSchedules(lessonReservation.studentId);
      }
    },
    editCoachSchedule(coachSchedule: CoachSchedule) {
      if (coachSchedule.id != null) {
        this.$router.push({name: 'AdminCoachScheduleEdit', params: { coachScheduleId: coachSchedule.id.getId() }, query: { base_page: 'index', target_date: this.targetDate } });
      }
    },
    closeLessonReservation() {
      this.dialogLessonReservation = null;
      this.dialogStudentLessonHistories = new LessonReservations([]);
    },
    clickNothing(e: PointerEvent) {
      e.stopPropagation();
    },
    editLessonReservation() {
      this.$router.push({name: 'AdminLessonReservationIndex', query: { lesson_reservation_id: this.dialogLessonReservation?.id?.getId(), target_date: this.targetDate } });
    },
    deleteLessonReservation() {
      this.tempDialogLessonReservation = this.dialogLessonReservation;
      this.dialogLessonReservation = null;
      this.confirmMessage = "削除してもよろしいですか？";
    },
    async resultConfirm(result: boolean) {
      if (result) {
        if (this.tempDialogLessonReservation == null) {
          this.confirmMessage = null;
          this.errorMessage = "予約の削除に失敗しました。";
          return;
        }
        const response: ResponseInterface = await this.tempDialogLessonReservation.delete();
        this.confirmMessage = null;
        if (response.result) {
          this.tempDialogLessonReservation = null;
          this.dialogStudentLessonHistories = new LessonReservations([]);
          this.successMessage = "削除を完了しました。";
        } else {
          this.errorMessage = "予約の削除に失敗しました。";
        }
      } else {
        this.dialogLessonReservation = this.tempDialogLessonReservation;
        this.tempDialogLessonReservation = null;
        this.confirmMessage = null;
      }
    },
    async resultSuccess() {
      this.successMessage = null;
      await this.setSchedule();
    },
    registerStudent() {
      if (this.dialogLessonReservation == null) {
        return;
      }
      this.$router.push({name: 'AdminStudentNew', query: { lesson_reservation_id: this.dialogLessonReservation.id?.getId() } });
    }
  }
});

export interface CoachScheduleReservation {
  coach: Coach;
  schedules: ScheduleReservation[];
}

export interface ScheduleReservation {
  scheduleTime: string;
  lessonReservations: LessonReservations;
  coachSchedules: CoachSchedules;
}
