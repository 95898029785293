import HttpClient from "./HttpClient";

export default class HttpClientBase {

  private httpClient: HttpClient;

  constructor() {
    this.httpClient = HttpClient.getInstance();
  }

  public getRequest(path: string, params = {}) {
    return this.httpClient.getRequest(path, params);
  }

  public getBlobRequest(path: string, params = {}) {
    return this.httpClient.getBlobRequest(path, params);
  }

  public postRequest(path: string, params = {}) {
    return this.httpClient.postRequest(path, params);
  }

  public multipartPostRequest(path: string, params = {}) {
    return this.httpClient.multipartPostRequest(path, params);
  }

  public putRequest(path: string, params = {}) {
    return this.httpClient.putRequest(path, params);
  }

  public deleteRequest(path: string, params = {}) {
    return this.httpClient.deleteRequest(path, params);
  }

  public getAccessTokenParam(): { [key: string]: string; } {
    if (sessionStorage.getItem("uid") != null
      && sessionStorage.getItem("access_token") != null
      && sessionStorage.getItem("client") != null) {

      return {
        uid: sessionStorage.getItem("uid") as string,
        'access-token': sessionStorage.getItem("access_token") as string,
        client: sessionStorage.getItem("client") as string,
      }
    }
    return {};
  }

  public getAccessTokenForm(): FormData {
    const formData = new FormData();
    if (sessionStorage.getItem("uid") != null
      && sessionStorage.getItem("access_token") != null
      && sessionStorage.getItem("client") != null) {

      formData.append('uid', sessionStorage.getItem("uid") as string);
      formData.append('access-token', sessionStorage.getItem("access_token") as string);
      formData.append('client', sessionStorage.getItem("client") as string);
    }
    return formData;
  }
}
