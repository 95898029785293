<template>
  <section class="admin-log-in">
    <ErrorMessage :errorMessage="errorMessage" />
    <img class="logo-img" alt="logo" src="@/assets/logo.png">
    <h1>予約システム ログイン画面</h1>
    <form @submit.prevent="logIn">
      <div class="input-block">
        <span>ユーザーID</span>
        <input type="text" v-model="userId" required/>
      </div>
      <div class="input-block">
        <span>パスワード</span>
        <input type="password" v-model="password" required/>
      </div>

      <div class="button-block">
        <button type="submit" class="approval-button" >ログイン</button>
      </div>
    </form>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ErrorMessage from '@/components/common/ErrorMessageComponent.vue';
import {MyAccount} from "@/domains/MyAccount";

export default defineComponent({
  name: 'AdminSignIn',
  components: {
    ErrorMessage,
  },
  data() {
    return {
      userId: '' as string,
      password: '' as string,
      errorMessage: null as string | null,
    }
  },
  methods: {
    async logIn() {
      const myAccount = new MyAccount();
      if (await myAccount.signInCoach(this.userId, this.password)) {
        this.$router.push('/admin');
      } else {
        this.errorMessage = "ログインに失敗しました。";
      }

    },
  },
});
</script>

<style lang="scss" scoped>
.admin-log-in {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.logo-img {
  width: 300px;
}

h1 {
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: normal;
  font-size: 18px;
}

.input-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 300px;

  input {
    width: 100%;
    font-size: 16px;
  }
}

.button-block {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
</style>
