
import { defineComponent } from 'vue';
import Base from '@/components/common/BaseComponent.vue';
import FutureOnlyCalendar from '@/components/common/FutureOnlyCalendarComponent.vue';
import {LessonReservations} from "@/domains/lesson_reservation/LessonReservations";
import LessonReservationsClient from "@/http_clients/LessonReservationsClient";
import {CoachSchedules} from "@/domains/coach_schedule/CoachSchedules";
import CoachSchedulesClient from "@/http_clients/CoachSchedulesClient";
import {Floors} from "@/domains/floor/Floors";
import FloorsClient from "@/http_clients/FloorsClient";
import moment from 'moment';
import {Coaches} from "@/domains/coach/Coaches";
import CoachesClient from "@/http_clients/CoachesClient";
import {Floor} from "@/domains/floor/Floor";
import LessonReservationId from "@/domains/lesson_reservation/value_object/LessonReservationId";
import {LessonReservation} from "@/domains/lesson_reservation/LessonReservation";
import {Student} from "@/domains/student/Student";

export default defineComponent({
  name: 'Index',
  components: {
    Base,
    FutureOnlyCalendar,
  },
  data() {
    return {
      student: null as Student | null,
      startTime: "11:00" as string,
      endTime: "22:00" as string,
      timeInterval: 30 as number,
      calenderHeight: 40 as number,
      scheduleTimes: [] as string[],
      targetDate: moment().format("YYYY-MM-DD") as string,
      floors: new Floors([]) as Floors,
      coaches: new Coaches([]) as Coaches,
      coachSchedules: new CoachSchedules([]) as CoachSchedules,
      lessonReservations: new LessonReservations([]) as LessonReservations,
      allLessonReservations: new LessonReservations([]) as LessonReservations,
      loading: false as boolean,
      editLessonReservationId: null as LessonReservationId | null,
      showSelectLogin: false as boolean,
      selectFloor: null as Floor | null,
      selectScheduleTime: null as string | null,
    }
  },
  watch: {
    async $route() {
      this.loading = true;
      if (this.$router.currentRoute.value.path != "/lesson_reservations") {
        return;
      }
      if (this.$route.query.target_date) {
        this.targetDate = this.$route.query.target_date as string;
      } else {
        this.targetDate = moment().format("YYYY-MM-DD");
      }
      if (this.$route.query.lesson_reservation_id) {
        this.editLessonReservationId = new LessonReservationId(parseInt(this.$route.query.lesson_reservation_id as string));
      } else {
        this.editLessonReservationId = null;
      }
      await this.setSchedule();
      this.loading = false;
    }
  },
  async created() {
    if (this.$route.query.target_date) {
      this.targetDate = this.$route.query.target_date as string;
    }
    if (this.$route.query.lesson_reservation_id) {
      this.editLessonReservationId = new LessonReservationId(parseInt(this.$route.query.lesson_reservation_id as string));
    } else {
      this.editLessonReservationId = null;
    }
    await this.setSchedule();

    this.scheduleTimes = this.createScheduleTimes();

    const floorsClient = new FloorsClient();
    this.floors = await floorsClient.getStudentFloors();

    const coachesClient = new CoachesClient();
    this.coaches = await coachesClient.getAllCoaches();
  },
  methods: {
    setLoginStudent(student: Student) {
      this.student = student;
    },
    async setSchedule() {
      const lessonReservationsClient = new LessonReservationsClient();
      this.lessonReservations = await lessonReservationsClient.getTargetDateSchedules(this.targetDate);
      this.allLessonReservations = await lessonReservationsClient.getTargetDateAllSchedules(this.targetDate);

      const coachSchedulesClient = new CoachSchedulesClient();
      this.coachSchedules = await coachSchedulesClient.getTargetDateSchedules(this.targetDate);
    },
    changeTargetDate(targetDate: string) {
      this.targetDate = targetDate;
      let query = null;
      if (this.editLessonReservationId != null) {
        query = {lesson_reservation_id: this.editLessonReservationId.getId(), target_date: this.targetDate};
      } else {
        query = {target_date: this.targetDate};
      }
      this.$router.push({query: query });
    },
    createScheduleTimes() {
      let timeMoment = moment(this.startTime, 'HH:mm');
      let endTimeMoment = moment(this.endTime, 'HH:mm');
      let times = [];

      while (timeMoment.isBefore(endTimeMoment)) {
        times.push(timeMoment.format('HH:mm'));
        timeMoment.add(this.timeInterval, 'minute');
      }
      return times;
    },
    getFloorSchedules(): FloorSchedule[] {
      return this.floors.list.map((floor) => {
        const floorLessonReservations = this.lessonReservations.filterFloor(floor.id);
        const floorGroupLessonReservations = this.allLessonReservations.filterFloorGroup(floor.floorGroupId);
        const floorGroupCoaches = this.coaches.filterFloorGroup(floor.floorGroupId);
        const floorGroupCoachSchedules = this.coachSchedules.filterFloorGroup(floor.floorGroupId);

        const schedules: Schedule[] = this.scheduleTimes.map((scheduleTime) => {
          const lessonReservations = floorLessonReservations.filterScheduleTime(scheduleTime, this.timeInterval);
          const floorGroupAndTimeLessonReservations = floorGroupLessonReservations.filterScheduleTime(scheduleTime, this.timeInterval);
          const coachSchedules = floorGroupCoachSchedules.filterScheduleTime(scheduleTime, this.timeInterval);

          return {
            scheduleTime: scheduleTime,
            lessonReservations: lessonReservations,
            coachNothing: !floorGroupCoaches.existFreeSchedule(coachSchedules, floorGroupAndTimeLessonReservations, this.editLessonReservationId),
          };
        });

        return {
          floor: floor,
          schedules: schedules,
        };
      });
    },
    displayLessonReservations(lessonReservations: LessonReservations, schedule: Schedule) {

      let result = false;

      lessonReservations.list.forEach((lessonReservation: LessonReservation) => {
        if (this.editLessonReservationId != null && lessonReservation.id != null && this.editLessonReservationId.getId() === lessonReservation.id.getId()) {
          return;
        }
        if (lessonReservation.startTime == null || lessonReservation.endTime == null) {
          return;
        }

        if (lessonReservation.startTime <= schedule.scheduleTime && schedule.scheduleTime < lessonReservation.endTime) {
          result = true;
        }
      });
      return result;
    },
    clickPossibleReservation(floor: Floor, scheduleTime: string) {

      if (this.student == null) {
        this.selectFloor = floor;
        this.selectScheduleTime = scheduleTime;
        this.showSelectLogin = true;
        return;
      }

      if (this.editLessonReservationId == null) {
        this.$router.push({name: 'LessonReservationNew', query: { floor_id: floor.id.getId(), target_date: this.targetDate, schedule_time: scheduleTime }});
      } else {
        this.$router.push({name: 'LessonReservationEdit', params: {lessonReservationId: this.editLessonReservationId.getId()}, query: { floor_id: floor.id.getId(), target_date: this.targetDate, schedule_time: scheduleTime }});
      }
    },
    clickLoginReservation() {
      if (this.selectFloor != null && this.selectScheduleTime != null) {
        this.$router.push({name: 'SignIn', query: { floor_id: this.selectFloor.id.getId(), target_date: this.targetDate, schedule_time: this.selectScheduleTime }});
      }
    },
    clickGuestReservation() {
      if (this.selectFloor != null && this.selectScheduleTime != null) {
        this.$router.push({name: 'LessonReservationNew', query: { floor_id: this.selectFloor.id.getId(), target_date: this.targetDate, schedule_time: this.selectScheduleTime }});
      }
    },
    cancel() {
      this.showSelectLogin = false;
    },
    clickNothing(e: PointerEvent) {
      e.stopPropagation();
    },
  }
});

export interface FloorSchedule {
  floor: Floor;
  schedules: Schedule[];
}

export interface Schedule {
  scheduleTime: string;
  lessonReservations: LessonReservations;
  coachNothing: boolean;
}

