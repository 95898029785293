import HttpClientBase from './HttpClientBase';
import {Coach} from "@/domains/coach/Coach";
import {Coaches} from "@/domains/coach/Coaches";
import {AxiosResponse} from "axios";
import FloorGroupId from "@/domains/floor_group/value_object/FloorGroupId";
import CoachId from "@/domains/coach/value_object/CoachId";
import CoachUserId from "@/domains/coach/value_object/CoachUserId";
import FloorId from "@/domains/floor/value_object/FloorId";

export default class CoachesClient extends HttpClientBase {

  public async getFloorCoaches(floorId :FloorId): Promise<Coaches> {
    const param = this.getAccessTokenParam();
    param["floor_id"] = floorId.getId().toString();
    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/coaches/floor_coaches', param);

    const coaches: Coach[] = [];

    response.data.coaches.forEach((coachResponse) => {
      let floorGroupId = null;
      if (coachResponse.floor_group_id != null) {
        floorGroupId = new FloorGroupId(coachResponse.floor_group_id);
      }

      coaches.push(new Coach(
        new CoachId(coachResponse.id),
        new CoachUserId(coachResponse.user_id),
        null,
        coachResponse.name,
        coachResponse.name_kana,
        floorGroupId,
        coachResponse.floor_group_name,
        coachResponse.floor_group_color,
        coachResponse.note,
      ));
    });

    return new Coaches(coaches);
  }

  public async getAllCoaches(): Promise<Coaches> {
    const param = this.getAccessTokenParam();
    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/coaches/all_coaches', param);

    const coaches: Coach[] = [];

    response.data.coaches.forEach((coachResponse) => {
      let floorGroupId = null;
      if (coachResponse.floor_group_id != null) {
        floorGroupId = new FloorGroupId(coachResponse.floor_group_id);
      }

      coaches.push(new Coach(
        new CoachId(coachResponse.id),
        new CoachUserId(coachResponse.user_id),
        null,
        coachResponse.name,
        coachResponse.name_kana,
        floorGroupId,
        coachResponse.floor_group_name,
        coachResponse.floor_group_color,
        coachResponse.note,
      ));
    });

    return new Coaches(coaches);
  }
}

export interface IndexResponseInterface {
  meta: {
    total_page: number,
    current_page: number,
  };
  coaches: CoachResponseInterface[];
}

export interface ShowResponseInterface {
  coach: CoachResponseInterface;
}

export interface CoachResponseInterface {
  id: number;
  user_id: string;
  name: string;
  name_kana: string;
  floor_group_id: number;
  floor_group_name: string;
  floor_group_color: string;
  note: string;
  is_delete: boolean;
}
