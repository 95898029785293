<template>
  <section class="base">
    <ConfirmMessage :confirmMessage="confirmMessage" @resultConfirm="resultConfirm"/>
    <SuccessMessage :successMessage="successMessage" @resultSuccess="resultSuccess"/>
    <div class="main-contents-block">
      <div class="header-block">
        <div class="menu-block">
          <font-awesome-icon icon="bars" size="3x" @click="clickMenu()" v-if="student != null" />
        </div>
        <div class="login-status-block">
          <router-link :to="{ name: 'SignIn'}" class="header-button" v-if="student == null">
            ログイン
          </router-link>
          <div class="student-name" v-if="student != null">
            {{student.name}}様
          </div>
          <div class="header-button" @click="signOut()" v-if="student != null">
            ログアウト
          </div>
          <img class="logo" alt="logo" src="@/assets/logo.png">
        </div>
      </div>
      <div class="contents-block">
        <ErrorMessage :errorMessage="errorMessage" />
        <slot></slot>
      </div>
    </div>
    <div v-if="loading != null && loading" class="loading-background">
      <div class="loading-dialog">
        <font-awesome-icon icon="spinner" size="6x" pulse />
      </div>
    </div>

    <div class="menu-background" v-show="showMenu" @click="cancelMenu()">
      <div class="menu" @click="clickNothing">
        <ul>
          <li>
            <router-link :to="{ name: 'Index'}">予約確認</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'LessonReservationIndex'}">予約追加</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'StudentEdit'}">アカウント編集</router-link>
          </li>
          <li>
            <a href="#" @click="signOut()">
              ログアウト
            </a>
          </li>
        </ul>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ErrorMessage from "@/components/common/ErrorMessageComponent.vue";
import ConfirmMessage from "@/components/common/ConfirmMessageComponent.vue";
import SuccessMessage from "@/components/common/SuccessMessageComponent.vue";
import {MyAccount} from "@/domains/MyAccount";
import {Student} from "@/domains/student/Student";

export default defineComponent({
  name: 'BaseComponent',
  components: {
    ErrorMessage,
    ConfirmMessage,
    SuccessMessage,
  },
  props: {
    errorMessage: String,
    confirmMessage: String,
    successMessage: String,
    loading: Boolean,
  },
  data() {
    return {
      showMenu: false as boolean,
      student: null as Student | null,
    }
  },
  async mounted() {
    const myAccount = new MyAccount();
    this.student = await myAccount.getCurrentStudent();
    this.$emit('setLoginStudent', this.student);
  },
  methods: {
    resultConfirm(result: boolean) {
      this.$emit('resultConfirm', result);
    },
    resultSuccess(result: boolean) {
      this.$emit('resultSuccess', result);
    },
    clickMenu() {
      this.showMenu = true;
    },
    cancelMenu() {
      this.showMenu = false;
    },
    clickNothing(e: PointerEvent) {
      e.stopPropagation();
    },
    async signOut() {
      const myAccount = new MyAccount();
      if (await myAccount.signOutStudent()) {
        this.$router.push('/sign_in');
      }
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.base {
  height: 100%;
}

.main-contents-block {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%;
}

.header-block {
  flex: 0 0 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  background-color: #ffffff;
  width: 100%;
  z-index: 5;
  height: 42px;

  .menu-block {
    margin-left: 10px;
    svg {
      width: 25px;
    }
  }

  .login-status-block {
    display: flex;
    height: 27px;

  }

  .student-name {
    margin-right: 10px;
    line-height: 27px;
  }

  .header-button {
    color: #FF2F92;
    border: 1px solid #000000;
    margin-right: 10px;
    padding: 2px 5px;
    cursor: pointer;
    text-decoration: none;
  }

  .logo {
    width: 70px;
    object-fit: contain;
    margin-right: 5px;
  }
}

.contents-block {
  flex: 1 1 100%;
  padding: 10px 5px;
  margin-top: 40px;
}

.loading-background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  top: 0;
}

.loading-dialog {
  background-color: #ffffff;
  margin: 40px;
  padding: 50px;
  border-radius: 10px;
  color: #263165;
}

.menu-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.menu {
  background-color: #ffffff;
  width: 150px;

  ul {
    list-style: none;
    padding: 0;
    border-top: 1px solid #000000;
    margin: 0;
  }

  li {
    line-height: 50px;
    border-bottom: 1px solid #000000;

    a {
      display: block;
      padding-left: 10px;
      text-decoration: none;
      color: #000000;
    }
  }
}

</style>
