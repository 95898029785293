
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import Pager from "@/components/common/PagerComponent.vue";
import {CoachSchedules} from "@/domains/coach_schedule/CoachSchedules";
import CoachSchedulesClient from "@/http_clients/admin/CoachSchedulesClient";

export default defineComponent({
  name: 'CoachScheduleIndex',
  components: {
    Base,
    Pager
  },
  data() {
    return {
      coachSchedules: new CoachSchedules([]) as CoachSchedules,
    }
  },
  watch: {
    async $route() {
      let page = null;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page as string);
      }
      const coachSchedulesClient = new CoachSchedulesClient();
      this.coachSchedules = await coachSchedulesClient.getList(page);
    }
  },
  async created() {
    let page = null;
    if (this.$route.query.page) {
      page = parseInt(this.$route.query.page as string);
    }
    const coachSchedulesClient = new CoachSchedulesClient();
    this.coachSchedules = await coachSchedulesClient.getList(page);
  },
  methods: {
    async changePage(page: number) {
      this.$router.push({query: { page: page } });
    }
  }
});
