
import { defineComponent } from 'vue';
import Base from '@/components/common/BaseComponent.vue';
import LessonReservationsClient from "@/http_clients/LessonReservationsClient";
import {LessonReservations} from "@/domains/lesson_reservation/LessonReservations";
import {LessonReservation} from "@/domains/lesson_reservation/LessonReservation";
import {ResponseInterface} from "@/http_clients/ResponseInterface";

export default defineComponent({
  name: 'Index',
  components: {
    Base,
  },
  data() {
    return {
      loading: false as boolean,
      lessonReservations: new LessonReservations([]) as LessonReservations,
      selectLessonReservation: null as LessonReservation | null,
      tempDialogLessonReservation: null as LessonReservation | null,
      confirmMessage: null as string | null,
      successMessage: null as string | null,
      errorMessage: null as string | null,
    }
  },
  async created() {
    const lessonReservationsClient = new LessonReservationsClient();
    this.lessonReservations = await lessonReservationsClient.getStudentSchedules();
  },
  methods: {
    clickLessonReservation(lessonReservation: LessonReservation) {
      this.selectLessonReservation = lessonReservation;
    },
    editLessonReservation() {
      this.$router.push({name: 'LessonReservationIndex', query: { lesson_reservation_id: this.selectLessonReservation?.id?.getId(), target_date: this.selectLessonReservation?.startDate } });
    },
    deleteLessonReservation() {
      this.tempDialogLessonReservation = this.selectLessonReservation;
      this.selectLessonReservation = null;
      this.confirmMessage = "予約を削除してもよろしいですか？";
    },
    async resultConfirm(result: boolean) {
      if (result) {
        if (this.tempDialogLessonReservation == null) {
          this.confirmMessage = null;
          this.errorMessage = "予約の削除に失敗しました。";
          return;
        }
        const response: ResponseInterface = await this.tempDialogLessonReservation.deleteByStudent();
        this.confirmMessage = null;
        if (response.result) {
          this.tempDialogLessonReservation = null;
          this.successMessage = "削除を完了しました。";
        } else {
          this.errorMessage = "予約の削除に失敗しました。";
        }
      } else {
        this.tempDialogLessonReservation = null;
        this.confirmMessage = null;
      }
    },
    async resultSuccess() {
      this.successMessage = null;
      const lessonReservationsClient = new LessonReservationsClient();
      this.lessonReservations = await lessonReservationsClient.getStudentSchedules();
    },
    cancel() {
      this.selectLessonReservation = null;
    },
    clickNothing(e: PointerEvent) {
      e.stopPropagation();
    },
  }
});

