
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ConfirmMessageComponent',
  props: {
    confirmMessage: String,
  },
  methods: {
    cancel() {
      this.$emit('resultConfirm', false);
    },
    ok() {
      this.$emit('resultConfirm', true);
    },
    clickNothing(e: PointerEvent) {
      e.stopPropagation();
    }
  }
});
