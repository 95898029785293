import HttpClientBase from './HttpClientBase';
import {AxiosResponse} from "axios";
import {Student} from "@/domains/student/Student";
import StudentUserId from "@/domains/student/value_object/StudentUserId";
import StudentId from "@/domains/student/value_object/StudentId";
import {ResponseInterface} from "@/http_clients/ResponseInterface";

export default class StudentsClient extends HttpClientBase {

  public async findStudentUserId(studentUserId: StudentUserId): Promise<Student> {
    const param = this.getAccessTokenParam();
    if (studentUserId != null) {
      param["student_user_id"] = studentUserId.getId().toString();
    }
    const response: AxiosResponse<ShowResponseInterface> = await this.getRequest('/students/find_student_user_id', param);

    const studentResponse = response.data.student;

    return new Student(
      new StudentId(studentResponse.id),
      new StudentUserId(studentResponse.user_id),
      null,
      studentResponse.name,
      studentResponse.name_kana,
      studentResponse.postal_code,
      studentResponse.address,
      studentResponse.tel1,
      studentResponse.tel2,
      studentResponse.email,
      studentResponse.birthday,
      studentResponse.gender,
      studentResponse.business,
      studentResponse.note1,
      studentResponse.note2,
      studentResponse.delivery_mail,
      studentResponse.motivation_coming_store,
      studentResponse.coming_store_count,
      studentResponse.first_coming_store_date,
      studentResponse.last_coming_store_date,
    );
  }

  public async getCurrentStudent(): Promise<Student | null> {
    const param = this.getAccessTokenParam();

    try {
      const response: AxiosResponse<ShowResponseInterface> = await this.getRequest('/students/find_current_student', param);

      const studentResponse = response.data.student;

      return new Student(
        new StudentId(studentResponse.id),
        new StudentUserId(studentResponse.user_id),
        null,
        studentResponse.name,
        studentResponse.name_kana,
        studentResponse.postal_code,
        studentResponse.address,
        studentResponse.tel1,
        studentResponse.tel2,
        studentResponse.email,
        studentResponse.birthday,
        studentResponse.gender,
        studentResponse.business,
        studentResponse.note1,
        studentResponse.note2,
        studentResponse.delivery_mail,
        studentResponse.motivation_coming_store,
        studentResponse.coming_store_count,
        studentResponse.first_coming_store_date,
        studentResponse.last_coming_store_date,
      );
    } catch {
      return null;
    }
  }

  public async update(student: Student): Promise<ResponseInterface> {
    const formData: FormData = this.getAccessTokenForm();
    student.getFormData().forEach((data, key) => {
      formData.append("student[" + key + "]", data);
    });

    try {
      await this.putRequest('/students/update', formData);
    } catch (error) {
      return {
        result: false,
        status: error.response.status,
        errorCode: error.response.data.error_code,
      };
    }
    return {
      result: true,
      status: 204,
      errorCode: null,
    };
  }

}

export interface ShowResponseInterface {
  student: StudentResponseInterface;
}

export interface StudentResponseInterface {
  id: number;
  user_id: string;
  name: string;
  name_kana: string;
  postal_code: string;
  address: string;
  tel1: string;
  tel2: string;
  email: string;
  birthday: string;
  gender: string;
  business: string;
  note1: string;
  note2: string;
  delivery_mail: boolean;
  motivation_coming_store: string;
  coming_store_count: number;
  first_coming_store_date: string;
  last_coming_store_date: string;
  is_delete: boolean;
}
