
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import TrainingMenusInput from '@/components/admin/training_menus/InputComponent.vue';
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import {TrainingMenu} from "@/domains/training_menu/TrainingMenu";

export default defineComponent({
  name: 'TrainingMenuNew',
  components: {
    Base,
    TrainingMenusInput,
  },
  data() {
    return {
      errorMessage: null as string | null,
      successMessage: null as string | null,
      trainingMenu: new TrainingMenu(null, null,null,null,null,null,null) as TrainingMenu,
    }
  },
  methods: {
    changeTrainingMenu(trainingMenu: TrainingMenu) {
      this.trainingMenu = trainingMenu;
    },
    async saveTrainingMenu() {
      const response: ResponseInterface = await this.trainingMenu.insert();
      if (response.result) {
        this.successMessage = "登録を完了しました。";
      } else {
        this.errorMessage = "トレーニングメニューの登録処理に失敗しました。";
      }
    },
    resultSuccess() {
      this.$router.push('/admin/training_menus');
    },
  }
});
