import {ReservationHistory} from "@/domains/reservation_history/ReservationHistory";

export class ReservationHistories {
  public list: ReservationHistory[];
  public totalPage: number;
  public currentPage: number;

  constructor(list: ReservationHistory[], totalPage = 1, currentPage= 1) {
    this.list = list;
    this.totalPage = totalPage;
    this.currentPage = currentPage;
  }

  public isPresent(): boolean {
    return !this.isEmpty();
  }

  public isEmpty(): boolean {
    return this.list.length == 0;
  }
}