<template>
  <section class="log-in">
    <div class="header-block">
      <img class="logo" alt="logo" src="@/assets/logo.png">
    </div>
    <ErrorMessage :errorMessage="errorMessage" />
    <form @submit.prevent="logIn">
      <div class="input-block">
        <span>会員番号</span>
        <input type="text" v-model="userId" required/>
      </div>
      <div class="input-block">
        <span>パスワード</span>
        <input type="password" v-model="password" required/>
      </div>

      <div class="button-block">
        <button type="submit" class="approval-button" >ログイン</button>
      </div>
    </form>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ErrorMessage from '@/components/common/ErrorMessageComponent.vue';
import {MyAccount} from "@/domains/MyAccount";

export default defineComponent({
  name: 'SignIn',
  components: {
    ErrorMessage,
  },
  data() {
    return {
      userId: '' as string,
      password: '' as string,
      errorMessage: null as string | null,
    }
  },
  methods: {
    async logIn() {
      const myAccount = new MyAccount();
      if (await myAccount.signInStudent(this.userId, this.password)) {

        if (this.$route.query.floor_id != null && this.$route.query.target_date != null && this.$route.query.schedule_time != null) {
          const floorId = this.$route.query.floor_id as string;
          const targetDate = this.$route.query.target_date as string;
          const scheduleTime = this.$route.query.schedule_time as string;
          this.$router.push({name: 'LessonReservationNew', query: { floor_id: floorId, target_date: targetDate, schedule_time: scheduleTime }});
        } else {
          this.$router.push('/');
        }
      } else {
        this.errorMessage = "ログインに失敗しました。";
      }

    },
  },
});
</script>

<style lang="scss" scoped>
.header-block {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 50px;

  .logo {
    width: 70px;
    object-fit: contain;
    margin-right: 5px;
  }
}

.log-in {
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100vh;
}

.logo-img {
  width: 300px;
}

h1 {
  margin-top: 40px;
  margin-bottom: 40px;
  font-weight: normal;
  font-size: 18px;
}

.input-block {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  width: 300px;

  input {
    width: 100%;
    font-size: 16px;
  }
}

.button-block {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}
</style>
