import HttpClientBase from '../HttpClientBase';
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import {AxiosResponse} from "axios";
import FloorGroupId from "@/domains/floor_group/value_object/FloorGroupId";
import {TrainingMenu} from "@/domains/training_menu/TrainingMenu";
import TrainingMenuId from "@/domains/training_menu/value_object/TrainingMenuId";
import {TrainingMenus} from "@/domains/training_menu/TrainingMenus";
import FloorId from "@/domains/floor/value_object/FloorId";

export default class TrainingMenusClient extends HttpClientBase {

  public async getFloorTrainingMenus(floorId :FloorId): Promise<TrainingMenus> {
    const param = this.getAccessTokenParam();
    param["floor_id"] = floorId.getId().toString();
    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/admin/training_menus/floor_training_menus', param);

    const trainingMenus: TrainingMenu[] = [];

    response.data.training_menus.forEach((trainingMenuResponse) => {
      let floorGroupId = null;
      if (trainingMenuResponse.floor_group_id != null) {
        floorGroupId = new FloorGroupId(trainingMenuResponse.floor_group_id);
      }

      trainingMenus.push(new TrainingMenu(
        new TrainingMenuId(trainingMenuResponse.id),
        floorGroupId,
        trainingMenuResponse.floor_group_name,
        trainingMenuResponse.name,
        trainingMenuResponse.training_minutes,
        trainingMenuResponse.color,
        trainingMenuResponse.note,
      ));
    });

    return new TrainingMenus(trainingMenus);
  }

  public async getList(page= null as number | null): Promise<TrainingMenus> {
    const param = this.getAccessTokenParam();
    if (page != null) {
      param["page"] = page.toString();
    }
    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/admin/training_menus', param);

    const trainingMenus: TrainingMenu[] = [];
    const totalPage = response.data.meta.total_page;
    const currentPage = response.data.meta.current_page;

    response.data.training_menus.forEach((trainingMenuResponse) => {
      let floorGroupId = null;
      if (trainingMenuResponse.floor_group_id != null) {
        floorGroupId = new FloorGroupId(trainingMenuResponse.floor_group_id);
      }

      trainingMenus.push(new TrainingMenu(
        new TrainingMenuId(trainingMenuResponse.id),
        floorGroupId,
        trainingMenuResponse.floor_group_name,
        trainingMenuResponse.name,
        trainingMenuResponse.training_minutes,
        trainingMenuResponse.color,
        trainingMenuResponse.note,
      ));
    });

    return new TrainingMenus(trainingMenus, totalPage, currentPage);
  }

  public async getData(trainingMenu: TrainingMenuId): Promise<TrainingMenu> {
    const param = this.getAccessTokenParam();
    const response: AxiosResponse<ShowResponseInterface> = await this.getRequest('/admin/training_menus/' + trainingMenu.getId(), param);

    const trainingMenuResponse = response.data.training_menu;

    let floorGroupId = null;
    if (trainingMenuResponse.floor_group_id != null) {
      floorGroupId = new FloorGroupId(trainingMenuResponse.floor_group_id);
    }

    return new TrainingMenu(
      new TrainingMenuId(trainingMenuResponse.id),
      floorGroupId,
      trainingMenuResponse.floor_group_name,
      trainingMenuResponse.name,
      trainingMenuResponse.training_minutes,
      trainingMenuResponse.color,
      trainingMenuResponse.note,
    );
  }


  public async insert(trainingMenu: TrainingMenu): Promise<ResponseInterface> {
    const formData: FormData = this.getAccessTokenForm();
    trainingMenu.getFormData().forEach((data, key) => {
      formData.append("training_menu[" + key + "]", data);
    });

    try {
      await this.postRequest('/admin/training_menus', formData);
    } catch (error) {
      return {
        result: false,
        status: error.response.status,
        errorCode: error.response.data.error_code,
      };
    }
    return {
      result: true,
      status: 204,
      errorCode: null,
    };
  }

  public async update(trainingMenu: TrainingMenu): Promise<ResponseInterface> {
    const formData: FormData = this.getAccessTokenForm();
    trainingMenu.getFormData().forEach((data, key) => {
      formData.append("training_menu[" + key + "]", data);
    });

    try {
      await this.putRequest('/admin/training_menus/' + trainingMenu.id?.getId(), formData);
    } catch (error) {
      return {
        result: false,
        status: error.response.status,
        errorCode: error.response.data.error_code,
      };
    }
    return {
      result: true,
      status: 204,
      errorCode: null,
    };
  }

  public async delete(trainingMenu: TrainingMenu): Promise<ResponseInterface> {
    const formData: FormData = this.getAccessTokenForm();

    try {
      await this.deleteRequest('/admin/training_menus/' + trainingMenu.id?.getId(), formData);
    } catch (error) {
      return {
        result: false,
        status: error.response.status,
        errorCode: error.response.data.error_code,
      };
    }
    return {
      result: true,
      status: 204,
      errorCode: null,
    };
  }
}

export interface IndexResponseInterface {
  meta: {
    total_page: number,
    current_page: number,
  };
  training_menus: TrainingMenuResponseInterface[];
}

export interface ShowResponseInterface {
  training_menu: TrainingMenuResponseInterface;
}

export interface TrainingMenuResponseInterface {
  id: number;
  floor_group_id: number;
  floor_group_name: string;
  name: string;
  training_minutes: number;
  note: string;
  color: string;
  is_delete: boolean;
}
