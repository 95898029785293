
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import CoachInput from "@/components/admin/coaches/InputComponent.vue";
import {Coach} from "@/domains/coach/Coach";
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import CoachesClient from "@/http_clients/admin/CoachesClient";
import CoachId from "@/domains/coach/value_object/CoachId";

export default defineComponent({
  name: 'CoachEdit',
  components: {
    Base,
    CoachInput,
  },
  data() {
    return {
      errorMessage: null as string | null,
      confirmMessage: null as string | null,
      successMessage: null as string | null,
      coach: new Coach(null, null, null, null, null, null, null, null, null) as Coach,
    }
  },
  async created() {
    const coachesClient = new CoachesClient();
    this.coach = await coachesClient.getData(new CoachId(parseInt(this.$route.params.coachId as string)));
  },
  methods: {
    changeCoach(coach: Coach) {
      this.coach = coach;
    },
    async saveCoach() {
      const response: ResponseInterface = await this.coach.update();
      if (response.result) {
        this.successMessage = "更新を完了しました。";
      } else {
        if (response.status == 422) {
          this.errorMessage = "ユーザーIDは既に使用されています。";
        } else {
          this.errorMessage = "スタッフの更新に失敗しました。";
        }
      }
    },
    deleteCoach() {
      this.confirmMessage = "削除してもよろしいですか？";
    },
    async resultConfirm(result: boolean) {
      if (result) {
        const response: ResponseInterface = await this.coach.delete();
        this.confirmMessage = null;
        if (response.result) {
          this.successMessage = "削除を完了しました。";
        } else {
          this.errorMessage = "スタッフの削除に失敗しました。";
        }
      } else {
        this.confirmMessage = null;
      }
    },
    resultSuccess() {
      this.$router.push('/admin/coaches');
    },
  }
});
