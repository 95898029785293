<template>
  <section class="lesson-reservations-input">
    <div class="input-block" v-if="!member">
      <span>氏名:</span>
      <input type="text" v-model="name" required/>
    </div>
    <div class="input-block" v-if="!member">
      <span>電話番号:</span>
      <input type="tel" v-model="tel" required/>
    </div>
    <div class="input-block" v-if="!member">
      <span>年齢:</span>
      <input type="number" v-model="age" required/>
    </div>

    <div class="input-block">
      <span>フロア:</span>
      <div>
        {{ getFloorName() }}
      </div>
    </div>
    <div class="input-block">
      <span>日付:</span>
      <div>
        {{startDate}}
      </div>
    </div>
    <div class="input-block">
      <span>時間:</span>
      <div>
        {{startTime}}～{{endTime}}
      </div>
    </div>
    <div class="input-block">
      <span>スタッフ:</span>
      <select v-model="coachId" required>
        <option v-for="coach in targetCoaches.list" :key="coach.id.getId()" :value="coach.id.getId()">{{coach.name}}</option>
      </select>
    </div>
    <div class="menu-select-block">
      <span>メニュー:</span>
      <div class="menu-select-item">
        <select v-model="trainingMenuId1" required>
          <option v-for="trainingMenu in trainingMenus.list" :key="trainingMenu.id.getId()" :value="trainingMenu.id.getId()">{{trainingMenu.name}}</option>
        </select>
      </div>
    </div>
    <div class="input-textarea-block">
      <span>備考:</span>
      <textarea v-model="note"/>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {LessonReservation} from "@/domains/lesson_reservation/LessonReservation";
import FloorsClient from "@/http_clients/FloorsClient";
import FloorId from "@/domains/floor/value_object/FloorId";
import CoachId from "@/domains/coach/value_object/CoachId";
import TrainingMenuId from "@/domains/training_menu/value_object/TrainingMenuId";
import CoachesClient from "@/http_clients/CoachesClient";
import {Coaches} from "@/domains/coach/Coaches";
import TrainingMenusClient from "@/http_clients/TrainingMenusClient";
import {TrainingMenus} from "@/domains/training_menu/TrainingMenus";
import moment from "moment";
import CoachSchedulesClient from "@/http_clients/CoachSchedulesClient";
import {Student} from "@/domains/student/Student";
import {CoachSchedules} from "@/domains/coach_schedule/CoachSchedules";
import {LessonReservations} from "@/domains/lesson_reservation/LessonReservations";
import {Floor} from "@/domains/floor/Floor";
import LessonReservationsClient from "@/http_clients/LessonReservationsClient";

export default defineComponent({
  name: 'LessonReservationsInputComponent',
  props: {
    lessonReservation: LessonReservation,
  },
  data() {
    return {
      member: false as boolean,
      student: null as Student | null,
      studentId: null as number | null,
      studentName: null as string | null,
      name: null as string | null,
      tel: null as string | null,
      age: null as string | null,
      floorId: null as number | null,
      startDate: null as string | null,
      startTime: null as string | null,
      endTime: null as string | null,
      coachId: null as number | null,
      trainingMenuId1: null as number | null,
      trainingMenuId2: null as number | null,
      note: null as string | null,
      targetFloor: null as Floor | null,
      coaches: new Coaches([]) as Coaches,
      trainingMenus: new TrainingMenus([]) as TrainingMenus,
      timeInterval: null as number | null,
      notHolidayCoachSchedules: new CoachSchedules([]) as CoachSchedules,
      targetCoaches: new Coaches([]) as Coaches,
      targetDateLessonReservations: new LessonReservations([]) as LessonReservations,
    }
  },
  watch: {
    lessonReservation: function () {
      this.setInputData();
    },
    name: function () {
      this.setLessonReservation();
    },
    tel: function () {
      this.setLessonReservation();
    },
    age: function () {
      this.setLessonReservation();
    },
    coachId: function () {
      this.setLessonReservation();
    },
    endTime: function () {
      this.setLessonReservation();
    },
    trainingMenuId1: function () {
      this.setLessonReservation();
      this.targetCoaches = this.getTargetCoaches();
    },
    note: function () {
      this.setLessonReservation();
    },
  },
  async created() {
    if (this.lessonReservation != null && this.lessonReservation.floorId != null) {
      this.targetFloor = await this.getTargetFloor();

      const coachesClient = new CoachesClient();
      this.coaches = await coachesClient.getFloorCoaches(this.lessonReservation.floorId);

      const trainingMenusClient = new TrainingMenusClient();
      this.trainingMenus = await trainingMenusClient.getFloorTrainingMenus(this.lessonReservation.floorId);
      if (this.lessonReservation.startTime != null) {
        this.trainingMenus = this.trainingMenus.filterEndTimeOver(this.lessonReservation.startTime);
      }

      const coachSchedulesClient = new CoachSchedulesClient();
      const coachSchedule = await coachSchedulesClient.getTargetDateHolidaySchedules(this.lessonReservation.startDate);
      this.coaches = this.coaches.filterNotHoliday(coachSchedule);

      this.notHolidayCoachSchedules = await coachSchedulesClient.getTargetDateNotHolidaySchedules(this.lessonReservation.startDate);

      this.setInputData();
      this.targetDateLessonReservations = await this.getTargetDateLessonReservations(this.lessonReservation.startDate);
      this.targetCoaches = this.getTargetCoaches();
    }
  },
  methods: {
    getFloorName() {
      if (this.targetFloor == null || this.targetFloor.name == null) {
        return null;
      }
      return this.targetFloor.name;
    },
    setInputData() {
      if (this.lessonReservation != null) {
        this.member = this.lessonReservation.member;
        this.name = this.lessonReservation.studentName;
        this.tel = this.lessonReservation.studentTel;
        this.age = this.lessonReservation.studentAge;

        if (this.lessonReservation.floorId != null) {
          this.floorId = this.lessonReservation.floorId.getId();
        }

        this.startDate = this.lessonReservation.startDate;
        this.startTime = this.lessonReservation.startTime;
        this.endTime = this.lessonReservation.endTime;

        if (this.lessonReservation.coachId != null) {
          this.coachId = this.lessonReservation.coachId.getId();
        }
        if (this.lessonReservation.trainingMenuId1 != null) {
          this.trainingMenuId1 = this.lessonReservation.trainingMenuId1.getId();
        }
        if (this.lessonReservation.trainingMenuId2 != null) {
          this.trainingMenuId2 = this.lessonReservation.trainingMenuId2.getId();
        }
        this.note = this.lessonReservation.note;

        this.timeInterval = this.calculateTimeInterval();
        if (this.timeInterval != null) {
          this.endTime = moment(this.startTime, 'HH:mm').add(this.timeInterval, 'minute').format('HH:mm');
        }
      }
    },
    setLessonReservation() {
      let id = null;
      let member = false;
      let studentUserId = null;
      let studentId = null;
      if (this.lessonReservation != null) {
        id = this.lessonReservation.id;
        member = this.lessonReservation.member;
        studentUserId = this.lessonReservation.studentUserId;
        studentId = this.lessonReservation.studentId;
      }
      let floorId = null;
      if (this.floorId != null) {
        floorId = new FloorId(this.floorId);
      }
      let floorName = null;
      let floorGroupId = null;
      if (this.targetFloor != null) {
        floorName = this.targetFloor.name;
        floorGroupId = this.targetFloor.floorGroupId;
      }
      let coachId = null;
      let coachName = null;
      if (this.coachId != null) {
        coachId = new CoachId(this.coachId);
        const coach = this.coaches.find(coachId);
        if (coach != null) {
          coachName = coach.name;
        }
      }
      let trainingMenuId1 = null;
      let trainingMenu1Name = null;
      if (this.trainingMenuId1 != null) {
        trainingMenuId1 = new TrainingMenuId(this.trainingMenuId1);

        const trainingMenu = this.trainingMenus.find(trainingMenuId1);
        if (trainingMenu != null) {
          trainingMenu1Name = trainingMenu.name;
        }
      }
      let trainingMenuId2 = null;
      let trainingMenu2Name = null;
      if (this.trainingMenuId2 != null) {
        trainingMenuId2 = new TrainingMenuId(this.trainingMenuId2);

        const trainingMenu = this.trainingMenus.find(trainingMenuId2);
        if (trainingMenu != null) {
          trainingMenu2Name = trainingMenu.name;
        }
      }
      this.timeInterval = this.calculateTimeInterval();
      if (this.timeInterval != null) {
        this.endTime = moment(this.startTime, 'HH:mm').add(this.timeInterval, 'minute').format('HH:mm');
      }

      const lessonReservation = new LessonReservation(
          id,
          member,
          studentUserId,
          studentId,
          this.name,
          null,
          this.tel,
          this.age,
          floorId,
          floorName,
          floorGroupId,
          this.startDate,
          this.startTime,
          this.endTime,
          coachId,
          coachName,
          trainingMenuId1,
          trainingMenu1Name,
          null,
          trainingMenuId2,
          trainingMenu2Name,
          null,
          this.note);
      this.$emit('changeLessonReservation', lessonReservation);
    },
    calculateTimeInterval() {
      if (this.trainingMenus == null) {
        return null;
      }

      let timeInterval = null;
      if (this.trainingMenuId1 != null) {
        const trainingMenu = this.trainingMenus.find(new TrainingMenuId(this.trainingMenuId1));
        if (trainingMenu != null) {
          timeInterval = trainingMenu.trainingMinutes;
        }
      }
      if (this.trainingMenuId2 != null) {
        const trainingMenu = this.trainingMenus.find(new TrainingMenuId(this.trainingMenuId2));
        if (trainingMenu != null) {
          if (timeInterval == null) {
            timeInterval = trainingMenu.trainingMinutes;
          } else if (trainingMenu.trainingMinutes != null && timeInterval < trainingMenu.trainingMinutes) {
            timeInterval = trainingMenu.trainingMinutes;
          }
        }
      }
      return timeInterval;
    },
    getTargetCoaches() {
      let targetEndTime = this.endTime;
      if (targetEndTime == null) {
        targetEndTime = moment(this.startTime, 'HH:mm').add(30, 'minute').format('HH:mm');
      }
      let targetCoaches = this.coaches.list.filter((coach) => {
        const targetCoachSchedules = this.notHolidayCoachSchedules.list.filter((coachSchedule) => {
          if (coach.id == null || coachSchedule.coachId == null) {
            return false;
          }
          return (moment(targetEndTime, 'HH:mm').isAfter(moment(coachSchedule.startTime, 'HH:mm')) &&
              moment(this.startTime, 'HH:mm').isBefore(moment(coachSchedule.endTime, 'HH:mm')) &&
              coach.id.getId() == coachSchedule.coachId.getId());
        });
        return targetCoachSchedules.length == 0;
      });

      targetCoaches = targetCoaches.filter((coach) => {
        const targetLessonReservations = this.targetDateLessonReservations.list.filter((lessonReservation) => {
          if (coach.id == null || lessonReservation.coachId == null) {
            return false;
          }
          if (this.lessonReservation != null && this.lessonReservation.id != null && lessonReservation.id != null &&
              this.lessonReservation.id.getId() == lessonReservation.id.getId()) {
            return false;
          }
          return (moment(targetEndTime, 'HH:mm').isAfter(moment(lessonReservation.startTime, 'HH:mm')) &&
              moment(this.startTime, 'HH:mm').isBefore(moment(lessonReservation.endTime, 'HH:mm')) &&
              coach.id.getId() == lessonReservation.coachId.getId());
        });
        return targetLessonReservations.length == 0;
      });
      return new Coaches(targetCoaches);
    },
    async getTargetDateLessonReservations(targetDate= null as string | null) {
      if (this.targetFloor == null) {
        return new LessonReservations([]);
      }
      const lessonReservationsClient = new LessonReservationsClient();
      return await lessonReservationsClient.getTargetDateFloorGroupSchedules(targetDate, this.targetFloor.floorGroupId);
    },
    async getTargetFloor() {
      const floorsClient = new FloorsClient();
      const floors = await floorsClient.getFloors();
      if (floors == null) {
        return null;
      }
      if (this.lessonReservation == null || this.lessonReservation.floorId == null) {
        return null;
      }

      return floors.find(this.lessonReservation.floorId);
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>

.menu-select-block {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;

  span {
    display: inline-block;
    flex: 0 0 80px;
  }
}

.menu-select-item {
  display: flex;
  flex-direction: column;

  select {
    margin-bottom: 10px;
  }
}

.input-block {
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  span {
    display: inline-block;
    flex: 0 0 80px;
  }

  label {
    margin-right: 10px;
    display: flex;
  }
}

.input-textarea-block {
  margin-bottom: 20px;
  display: flex;
  align-items: flex-start;
  flex-direction: column;

  textarea {
    width: calc(100% - 15px);
    margin: 0 5px;
  }
}
</style>
