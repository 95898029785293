<template>
  <Base >
    <section class="coach-schedule-index">
      <div class="header">
        <h1>登録済み予定一覧</h1>
        <div class="button-block">
          <router-link class="new-button" :to="{ name: 'AdminCoachScheduleNew'}">新規登録</router-link>
        </div>
      </div>

      <div class="list-block" v-if="coachSchedules.isPresent()">
        <table>
          <thead>
          <tr>
            <th>
              日付
            </th>
            <th>
              時間
            </th>
            <th>
              スタッフ
            </th>
            <th>
              タイトル
            </th>
            <th>
              繰り返し
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="coachSchedule in coachSchedules.list" :key="coachSchedule.id.getId()">
            <td>
              {{ coachSchedule.displayStartDate() }}
            </td>
            <td>
              <span v-if="!coachSchedule.holiday">
                {{ coachSchedule.startTime }} ～ {{ coachSchedule.endTime }}
              </span>
              <span v-else>
                休日
              </span>
            </td>
            <td>
              {{ coachSchedule.coachName }}
            </td>
            <td>
              <router-link :to="{ name: 'AdminCoachScheduleEdit', params: {coachScheduleId: coachSchedule.id.getId()}}">
                {{ coachSchedule.getTitleName() }}
              </router-link>
            </td>
            <td>
              <span v-if="coachSchedule.repeat" class="repeat">
                <span v-if="coachSchedule.repeatMonday">月曜</span>
                <span v-if="coachSchedule.repeatTuesday">火曜</span>
                <span v-if="coachSchedule.repeatWednesday">水曜</span>
                <span v-if="coachSchedule.repeatThursday">木曜</span>
                <span v-if="coachSchedule.repeatFriday">金曜</span>
                <span v-if="coachSchedule.repeatSaturday">土曜</span>
                <span v-if="coachSchedule.repeatSunday">日曜</span>
              </span>
              <span v-else>
                1回のみ
              </span>
            </td>
          </tr>
          </tbody>
        </table>
        <Pager :totalPage="coachSchedules.totalPage" :currentPage="coachSchedules.currentPage" @selectPage="changePage" class="pager"></Pager>
      </div>
      <div v-else>
        データが登録されていません。
      </div>
    </section>
  </Base>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import Pager from "@/components/common/PagerComponent.vue";
import {CoachSchedules} from "@/domains/coach_schedule/CoachSchedules";
import CoachSchedulesClient from "@/http_clients/admin/CoachSchedulesClient";

export default defineComponent({
  name: 'CoachScheduleIndex',
  components: {
    Base,
    Pager
  },
  data() {
    return {
      coachSchedules: new CoachSchedules([]) as CoachSchedules,
    }
  },
  watch: {
    async $route() {
      let page = null;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page as string);
      }
      const coachSchedulesClient = new CoachSchedulesClient();
      this.coachSchedules = await coachSchedulesClient.getList(page);
    }
  },
  async created() {
    let page = null;
    if (this.$route.query.page) {
      page = parseInt(this.$route.query.page as string);
    }
    const coachSchedulesClient = new CoachSchedulesClient();
    this.coachSchedules = await coachSchedulesClient.getList(page);
  },
  methods: {
    async changePage(page: number) {
      this.$router.push({query: { page: page } });
    }
  }
});
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.button-block {
  display: flex;
  align-items: flex-end;
}

.new-button {
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 40px;
  text-decoration: none;
  color: #000000;
}

.repeat {
  display: flex;
  flex-wrap: wrap;
  span {
    margin-right: 10px;
  }
}

.pager {
  width: 100%;
  margin-top: 20px;
}
</style>
