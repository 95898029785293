
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import LessonReservationsInput from '@/components/admin/lesson_reservations/InputComponent.vue';
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import {LessonReservation} from "@/domains/lesson_reservation/LessonReservation";
import FloorId from "@/domains/floor/value_object/FloorId";
import LessonReservationsClient from "@/http_clients/admin/LessonReservationsClient";
import LessonReservationId from "@/domains/lesson_reservation/value_object/LessonReservationId";
import moment from "moment";

export default defineComponent({
  name: 'LessonReservationEdit',
  components: {
    Base,
    LessonReservationsInput,
  },
  data() {
    return {
      errorMessage: null as string | null,
      successMessage: null as string | null,
      lessonReservation: new LessonReservation(null, true,null,null,null,null,null,null,null,
          null,null,null,null,null,null,null,null,null,
          null,null,null,null,null) as LessonReservation,
    }
  },
  async created() {
    let startDate = null;
    if (this.$route.query.target_date) {
      startDate = this.$route.query.target_date as string;
    }

    let floorId = null;
    if (this.$route.query.floor_id) {
      floorId = new FloorId(parseInt(this.$route.query.floor_id as string));
    }

    let startTime = null;
    if (this.$route.query.schedule_time) {
      startTime = this.$route.query.schedule_time as string;
    }
    this.lessonReservation.startDate = startDate;
    this.lessonReservation.floorId = floorId;
    this.lessonReservation.startTime = startTime;

    const lessonReservationsClient = new LessonReservationsClient();
    const lessonReservation = await lessonReservationsClient.getData(new LessonReservationId(parseInt(this.$route.params.lessonReservationId as string)));


    this.lessonReservation = new LessonReservation(
        lessonReservation.id,
        lessonReservation.member,
        lessonReservation.studentUserId,
        lessonReservation.studentId,
        lessonReservation.studentName,
        lessonReservation.studentNameKana,
        lessonReservation.studentTel,
        lessonReservation.studentAge,
        floorId,
        lessonReservation.floorName,
        lessonReservation.floorGroupId,
        startDate,
        startTime,
        lessonReservation.endTime,
        lessonReservation.coachId,
        lessonReservation.coachName,
        lessonReservation.trainingMenuId1,
        lessonReservation.trainingMenu1Name,
        lessonReservation.trainingMenu1Color,
        lessonReservation.trainingMenuId2,
        lessonReservation.trainingMenu2Name,
        lessonReservation.trainingMenu2Color,
        lessonReservation.note,
    );
  },
  methods: {
    changeLessonReservation(lessonReservation: LessonReservation) {
      this.lessonReservation = lessonReservation;
    },
    async saveLessonReservation() {
      const response: ResponseInterface = await this.lessonReservation.update();
      if (response.result) {
        this.successMessage = "更新を完了しました。";
      } else {
        if (response.status == 400) {
          if (response.errorCode == 'exist_coach_schedule') {
            this.errorMessage = "既にスタッフに予定が登録されています。";
          } else if (response.errorCode == 'exist_lesson_reservation') {
            this.errorMessage = "フロアが空いていません。";
          } else if (response.errorCode == 'exist_student_schedule') {
            this.errorMessage = "既に会員に予定が登録されています。";
          }
        } else {
          this.errorMessage = "予約の更新に失敗しました。";
        }
      }
    },
    resultSuccess() {
      this.$router.push({name: 'AdminIndex', query: { target_date: moment(this.lessonReservation.startDate).format("YYYY-MM-DD") }});
    },
  }
});
