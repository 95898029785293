import HttpClientBase from '../HttpClientBase';
import {Coach} from "@/domains/coach/Coach";
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import {Coaches} from "@/domains/coach/Coaches";
import {AxiosResponse} from "axios";
import FloorGroupId from "@/domains/floor_group/value_object/FloorGroupId";
import CoachId from "@/domains/coach/value_object/CoachId";
import CoachUserId from "@/domains/coach/value_object/CoachUserId";
import FloorId from "@/domains/floor/value_object/FloorId";

export default class CoachesClient extends HttpClientBase {
  public async getCurrentCoach(): Promise<Coach | null> {
    const param = this.getAccessTokenParam();
    try {
      const response: AxiosResponse<ShowResponseInterface> = await this.getRequest('/admin/coaches/find_current_coach', param);
      const coachResponse = response.data.coach;

      let floorGroupId = null;
      if (coachResponse.floor_group_id != null) {
        floorGroupId = new FloorGroupId(coachResponse.floor_group_id);
      }

      return new Coach(
        new CoachId(coachResponse.id),
        new CoachUserId(coachResponse.user_id),
        null,
        coachResponse.name,
        coachResponse.name_kana,
        floorGroupId,
        coachResponse.floor_group_name,
        coachResponse.floor_group_color,
        coachResponse.note,
      );
    } catch {
      return null;
    }
  }
  public async getFloorCoaches(floorId :FloorId): Promise<Coaches> {
    const param = this.getAccessTokenParam();
    param["floor_id"] = floorId.getId().toString();
    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/admin/coaches/floor_coaches', param);

    const coaches: Coach[] = [];

    response.data.coaches.forEach((coachResponse) => {
      let floorGroupId = null;
      if (coachResponse.floor_group_id != null) {
        floorGroupId = new FloorGroupId(coachResponse.floor_group_id);
      }

      coaches.push(new Coach(
        new CoachId(coachResponse.id),
        new CoachUserId(coachResponse.user_id),
        null,
        coachResponse.name,
        coachResponse.name_kana,
        floorGroupId,
        coachResponse.floor_group_name,
        coachResponse.floor_group_color,
        coachResponse.note,
      ));
    });

    return new Coaches(coaches);
  }

  public async getScheduleCoaches(): Promise<Coaches> {
    const param = this.getAccessTokenParam();
    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/admin/coaches/schedule_coaches', param);

    const coaches: Coach[] = [];

    response.data.coaches.forEach((coachResponse) => {
      let floorGroupId = null;
      if (coachResponse.floor_group_id != null) {
        floorGroupId = new FloorGroupId(coachResponse.floor_group_id);
      }

      coaches.push(new Coach(
        new CoachId(coachResponse.id),
        new CoachUserId(coachResponse.user_id),
        null,
        coachResponse.name,
        coachResponse.name_kana,
        floorGroupId,
        coachResponse.floor_group_name,
        coachResponse.floor_group_color,
        coachResponse.note,
      ));
    });

    return new Coaches(coaches);
  }

  public async getAllCoaches(): Promise<Coaches> {
    const param = this.getAccessTokenParam();
    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/admin/coaches/all_coaches', param);

    const coaches: Coach[] = [];

    response.data.coaches.forEach((coachResponse) => {
      let floorGroupId = null;
      if (coachResponse.floor_group_id != null) {
        floorGroupId = new FloorGroupId(coachResponse.floor_group_id);
      }

      coaches.push(new Coach(
        new CoachId(coachResponse.id),
        new CoachUserId(coachResponse.user_id),
        null,
        coachResponse.name,
        coachResponse.name_kana,
        floorGroupId,
        coachResponse.floor_group_name,
        coachResponse.floor_group_color,
        coachResponse.note,
      ));
    });

    return new Coaches(coaches);
  }

  public async getList(page= null as number | null): Promise<Coaches> {
    const param = this.getAccessTokenParam();
    if (page != null) {
      param["page"] = page.toString();
    }
    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/admin/coaches', param);

    const coaches: Coach[] = [];
    const totalPage = response.data.meta.total_page;
    const currentPage = response.data.meta.current_page;

    response.data.coaches.forEach((coachResponse) => {
      let floorGroupId = null;
      if (coachResponse.floor_group_id != null) {
        floorGroupId = new FloorGroupId(coachResponse.floor_group_id);
      }

      coaches.push(new Coach(
        new CoachId(coachResponse.id),
        new CoachUserId(coachResponse.user_id),
        null,
        coachResponse.name,
        coachResponse.name_kana,
        floorGroupId,
        coachResponse.floor_group_name,
        coachResponse.floor_group_color,
        coachResponse.note,
      ));
    });

    return new Coaches(coaches, totalPage, currentPage);
  }

  public async getData(coachId: CoachId): Promise<Coach> {
    const param = this.getAccessTokenParam();
    const response: AxiosResponse<ShowResponseInterface> = await this.getRequest('/admin/coaches/' + coachId.getId(), param);

    const coachResponse = response.data.coach;

    let floorGroupId = null;
    if (coachResponse.floor_group_id != null) {
      floorGroupId = new FloorGroupId(coachResponse.floor_group_id);
    }

    return new Coach(
      new CoachId(coachResponse.id),
      new CoachUserId(coachResponse.user_id),
      null,
      coachResponse.name,
      coachResponse.name_kana,
      floorGroupId,
      coachResponse.floor_group_name,
      coachResponse.floor_group_color,
      coachResponse.note,
    );
  }


  public async insert(coach: Coach): Promise<ResponseInterface> {
    const formData: FormData = this.getAccessTokenForm();
    coach.getFormData().forEach((data, key) => {
      formData.append("coach[" + key + "]", data);
    });

    try {
      await this.postRequest('/admin/coaches', formData);
    } catch (error) {
      return {
        result: false,
        status: error.response.status,
        errorCode: error.response.data.error_code,
      };
    }
    return {
      result: true,
      status: 204,
      errorCode: null,
    };
  }

  public async update(coach: Coach): Promise<ResponseInterface> {
    const formData: FormData = this.getAccessTokenForm();
    coach.getFormData().forEach((data, key) => {
      formData.append("coach[" + key + "]", data);
    });

    try {
      await this.putRequest('/admin/coaches/' + coach.id?.getId(), formData);
    } catch (error) {
      return {
        result: false,
        status: error.response.status,
        errorCode: error.response.data.error_code,
      };
    }
    return {
      result: true,
      status: 204,
      errorCode: null,
    };
  }

  public async delete(coach: Coach): Promise<ResponseInterface> {
    const formData: FormData = this.getAccessTokenForm();

    try {
      await this.deleteRequest('/admin/coaches/' + coach.id?.getId(), formData);
    } catch (error) {
      return {
        result: false,
        status: error.response.status,
        errorCode: error.response.data.error_code,
      };
    }
    return {
      result: true,
      status: 204,
      errorCode: null,
    };
  }
}

export interface IndexResponseInterface {
  meta: {
    total_page: number,
    current_page: number,
  };
  coaches: CoachResponseInterface[];
}

export interface ShowResponseInterface {
  coach: CoachResponseInterface;
}

export interface CoachResponseInterface {
  id: number;
  user_id: string;
  name: string;
  name_kana: string;
  floor_group_id: number;
  floor_group_name: string;
  floor_group_color: string;
  note: string;
  is_delete: boolean;
}
