import HttpClient from "../http_clients/HttpClient";
import {App} from "@vue/runtime-core";
import {Router} from "vue-router";

export default {
  install: (app: App, router: Router): boolean => {

    const httpClient = HttpClient.getInstance();
    httpClient.getAxiosInstance().interceptors.response.use(
      // eslint-disable-next-line
      (response: any) => {
        return response;
      },
      // eslint-disable-next-line
      (error: any) => {
        if (error.response != null) {
          if (error.response.status === 401 && router.currentRoute.value.path != "/admin/sign_in" && router.currentRoute.value.path != "/sign_in") {
            if(router.currentRoute.value.path.indexOf("/admin") === 0){
              router.push("/admin/sign_in");
            } else {
              router.push("/lesson_reservations");
            }
          } else {
            throw error;
          }
        }
      }
    );
    return true;
  }
}
