<template>
  <section class="menu">
    <div class="logo-block">
      <img class="logo" alt="logo" src="@/assets/logo.png">
    </div>
    <ul>
      <li>
        <router-link :to="{ name: 'AdminIndex'}">トップページ</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'AdminLessonReservationIndex'}">予約登録</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'AdminReservationHistoryIndex'}">予約履歴</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'AdminCoachScheduleIndex'}">スタッフの予定</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'AdminStudentIndex'}">会員アカウント</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'AdminCoachIndex'}">スタッフアカウント</router-link>
      </li>
      <li>
        <router-link :to="{ name: 'AdminTrainingMenuIndex'}">トレーニングメニュー</router-link>
      </li>
      <li>
        <a href="#" @click="signOut()">
          ログアウト
        </a>
      </li>
    </ul>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {MyAccount} from "@/domains/MyAccount";

export default defineComponent({
  name: 'MenuComponent',
  methods: {
    async signOut() {
      const myAccount = new MyAccount();
      if (await myAccount.signOutCoach()) {
        this.$router.push('/admin/sign_in');
      }
    },
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.menu {
  height: 100%;
  border-right: 1px solid #000000;
  position: fixed;
  width: 200px;
  background-color: #ffffff;
  z-index: 5;
}

.logo-block {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;

  .logo {
    width: 100px;
  }
}

ul {
  list-style: none;
  padding: 0;
  border-top: 1px solid #000000;
}

li {
  line-height: 50px;
  border-bottom: 1px solid #000000;

  a {
    display: block;
    padding-left: 10px;
    text-decoration: none;
    color: #000000;
  }
}
</style>
