import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import AdminSignIn from '../views/admin/SignIn.vue'
import AdminIndex from '../views/admin/Index.vue'
import AdminCoachIndex from '../views/admin/coaches/Index.vue'
import AdminCoachNew from '../views/admin/coaches/New.vue'
import AdminCoachEdit from '../views/admin/coaches/Edit.vue'
import AdminTrainingMenuIndex from '../views/admin/training_menus/Index.vue'
import AdminTrainingMenuNew from '../views/admin/training_menus/New.vue'
import AdminTrainingMenuEdit from '../views/admin/training_menus/Edit.vue'
import AdminCoachScheduleIndex from '../views/admin/coach_schedules/Index.vue'
import AdminCoachScheduleNew from '../views/admin/coach_schedules/New.vue'
import AdminCoachScheduleEdit from '../views/admin/coach_schedules/Edit.vue'
import AdminStudentIndex from '../views/admin/students/Index.vue'
import AdminStudentNew from '../views/admin/students/New.vue'
import AdminStudentEdit from '../views/admin/students/Edit.vue'
import AdminLessonReservationIndex from '../views/admin/lesson_reservations/Index.vue'
import AdminLessonReservationNew from '../views/admin/lesson_reservations/New.vue'
import AdminLessonReservationEdit from '../views/admin/lesson_reservations/Edit.vue'
import AdminReservationHistoryIndex from '../views/admin/reservation_histories/Index.vue'
import Index from '../views/Index.vue'
import SignIn from '../views/SignIn.vue'
import LessonReservationIndex from '../views/lesson_reservations/Index.vue'
import LessonReservationNew from '../views/lesson_reservations/New.vue'
import LessonReservationEdit from '../views/lesson_reservations/Edit.vue'
import StudentEdit from '../views/students/Edit.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/admin/sign_in',
    name: 'AdminSignIn',
    component: AdminSignIn
  },
  {
    path: '/admin',
    name: 'AdminIndex',
    component: AdminIndex
  },
  {
    path: '/admin/coaches',
    name: 'AdminCoachIndex',
    component: AdminCoachIndex
  },
  {
    path: '/admin/coaches/new',
    name: 'AdminCoachNew',
    component: AdminCoachNew
  },
  {
    path: '/admin/coaches/:coachId/edit',
    name: 'AdminCoachEdit',
    component: AdminCoachEdit
  },
  {
    path: '/admin/training_menus',
    name: 'AdminTrainingMenuIndex',
    component: AdminTrainingMenuIndex
  },
  {
    path: '/admin/training_menus/new',
    name: 'AdminTrainingMenuNew',
    component: AdminTrainingMenuNew
  },
  {
    path: '/admin/training_menus/:trainingMenuId/edit',
    name: 'AdminTrainingMenuEdit',
    component: AdminTrainingMenuEdit
  },
  {
    path: '/admin/coach_schedules',
    name: 'AdminCoachScheduleIndex',
    component: AdminCoachScheduleIndex
  },
  {
    path: '/admin/coach_schedules/new',
    name: 'AdminCoachScheduleNew',
    component: AdminCoachScheduleNew
  },
  {
    path: '/admin/coach_schedules/:coachScheduleId/edit',
    name: 'AdminCoachScheduleEdit',
    component: AdminCoachScheduleEdit
  },
  {
    path: '/admin/students',
    name: 'AdminStudentIndex',
    component: AdminStudentIndex
  },
  {
    path: '/admin/students/new',
    name: 'AdminStudentNew',
    component: AdminStudentNew
  },
  {
    path: '/admin/students/:studentId/edit',
    name: 'AdminStudentEdit',
    component: AdminStudentEdit
  },
  {
    path: '/admin/lesson_reservations',
    name: 'AdminLessonReservationIndex',
    component: AdminLessonReservationIndex
  },
  {
    path: '/admin/lesson_reservations/new',
    name: 'AdminLessonReservationNew',
    component: AdminLessonReservationNew
  },
  {
    path: '/admin/lesson_reservations/:lessonReservationId/edit',
    name: 'AdminLessonReservationEdit',
    component: AdminLessonReservationEdit
  },
  {
    path: '/admin/reservation_histories',
    name: 'AdminReservationHistoryIndex',
    component: AdminReservationHistoryIndex
  },

  {
    path: '/',
    name: 'Index',
    component: Index
  },
  {
    path: '/sign_in',
    name: 'SignIn',
    component: SignIn
  },
  {
    path: '/lesson_reservations',
    name: 'LessonReservationIndex',
    component: LessonReservationIndex
  },
  {
    path: '/lesson_reservations/new',
    name: 'LessonReservationNew',
    component: LessonReservationNew
  },
  {
    path: '/lesson_reservations/:lessonReservationId/edit',
    name: 'LessonReservationEdit',
    component: LessonReservationEdit
  },
  {
    path: '/students/edit',
    name: 'StudentEdit',
    component: StudentEdit
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
