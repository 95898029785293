<template>
  <Base :loading="loading" :errorMessage="errorMessage" :confirmMessage="confirmMessage" :successMessage="successMessage"
        @resultConfirm="resultConfirm" @resultSuccess="resultSuccess">
    <section class="top-page">
      <Calendar :targetDate="targetDate" @selectTargetDate="changeTargetDate"/>

      <div class="coach-schedule" :style="{width: (50 + 130 * coachScheduleReservations.length) + 'px'}">
        <div class="time-block">
          <div class="header">時間</div>
          <div v-for="scheduleTime in scheduleTimes" :key="scheduleTime" class="schedule-body">{{scheduleTime}}</div>
        </div>
        <div v-for="coachScheduleReservation in coachScheduleReservations" :key="coachScheduleReservation.coach.id.getId()" class="coach-block">
          <div class="header" :style="{backgroundColor: coachScheduleReservation.coach.floorGroupColor}">{{coachScheduleReservation.coach.name}}</div>
          <div v-for="schedule in coachScheduleReservation.schedules" :key="schedule.scheduleTime" class="schedule-body">

            <div v-for="lessonReservation in schedule.lessonReservations.list" :key="lessonReservation.id">
              <div v-if="lessonReservation.startTime === schedule.scheduleTime"
                   class="exist-reservation" :style="{height: lessonReservation.calendarHeight(calenderHeight) + 'px', backgroundColor: lessonReservation.trainingMenu1Color}"
                   @click="displayDetail(lessonReservation)">

                {{lessonReservation.startTime}}～{{lessonReservation.endTime}}<br />
                {{lessonReservation.floorName}}：{{lessonReservation.studentName}}<br />
                {{lessonReservation.trainingMenu1Name}}<span v-if="lessonReservation.trainingMenuId2 != null">、</span>{{lessonReservation.trainingMenu2Name}}
              </div>
            </div>

            <div v-for="coachSchedule in schedule.coachSchedules.list" :key="coachSchedule.id">
              <div v-if="coachSchedule.withinRangeStartTime(schedule.scheduleTime, timeInterval)" class="schedule"
                   :style="{height: coachSchedule.calendarHeight(calenderHeight) + 'px',
                   top: coachSchedule.calendarTop(schedule.scheduleTime, calenderHeight) + 'px'}"
                   @click="editCoachSchedule(coachSchedule)">
                {{coachSchedule.startTime}}～{{coachSchedule.endTime}}<br />
                {{coachSchedule.getTitleName()}}
                <template v-if="coachSchedule.noteFirstLine() !== ''">：{{coachSchedule.noteFirstLine()}}</template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>


    <div class="lesson-reservation-detail-background" @click="closeLessonReservation" v-show="dialogLessonReservation != null">
      <div class="lesson-reservation-detail-dialog" @click="clickNothing">
        <div class="lesson-reservation-block">
          <div class="reservation-date-time">
            <div class="reservation-date">
              {{dialogLessonReservation?.displayStartDate()}}
            </div>
            <div class="reservation-time">
              {{dialogLessonReservation?.startTime}}～{{dialogLessonReservation?.endTime}}
            </div>
          </div>
          <div class="lesson-reservation-information">
            <div class="title">
              会員-氏名：
            </div>
            <div class="value">
              {{dialogLessonReservation?.studentName}}<br />
            </div>
            <div v-if="dialogLessonReservation?.studentId == null">
              <button type="button" class="register-button" @click="registerStudent()" >会員を登録</button>
            </div>
          </div>
          <div class="lesson-reservation-information">
            <div class="title">
              会員-フリガナ：
            </div>
            <div class="value">
              {{dialogLessonReservation?.studentNameKana}}<br />
            </div>
          </div>
          <div class="lesson-reservation-information">
            <div class="title">
              会員-電話番号：
            </div>
            <div class="value">
              {{dialogLessonReservation?.studentTel}}
            </div>
          </div>
          <div class="lesson-reservation-information">
            <div class="title">
              会員-年齢：
            </div>
            <div class="value">
              {{dialogLessonReservation?.studentAge}}
            </div>
          </div>
          <div class="lesson-reservation-information">
            <div class="title">
              フロア：
            </div>
            <div class="value">
              {{dialogLessonReservation?.floorName}}
            </div>
          </div>
          <div class="lesson-reservation-information">
            <div class="title">
              担当スタッフ：
            </div>
            <div class="value">
              {{dialogLessonReservation?.coachName}}
            </div>
          </div>
          <div class="lesson-reservation-information">
            <div class="title">
              メニュー：
            </div>
            <div class="value">
              <div>
                {{dialogLessonReservation?.trainingMenu1Name}}
              </div>
              <div>
                {{dialogLessonReservation?.trainingMenu2Name}}
              </div>
            </div>
          </div>
          <div class="lesson-reservation-information">
            <div class="title">
              備考：
            </div>
            <div class="value note" v-show="dialogLessonReservation?.note != null">
              {{dialogLessonReservation?.note}}
            </div>
          </div>

          <div class="lesson-reservation-histories">
            <div>
              過去の履歴：
            </div>
            <div class="list-block" v-if="dialogStudentLessonHistories.isPresent()">
              <table>
                <thead>
                <tr>
                  <th>
                    日付
                  </th>
                  <th>
                    時間
                  </th>
                  <th>
                    フロア
                  </th>
                  <th>
                    スタッフ
                  </th>
                  <th>
                    トレーニング
                  </th>
                </tr>
                </thead>
                <tbody>
                <tr v-for="lessonHistory in dialogStudentLessonHistories.list" :key="lessonHistory.id.getId()">
                  <td>
                    {{lessonHistory.startDate}}
                  </td>
                  <td>
                    {{lessonHistory.startTime}}～{{lessonHistory.endTime}}
                  </td>
                  <td>
                    {{lessonHistory.floorName}}
                  </td>
                  <td>
                    {{lessonHistory.coachName}}
                  </td>
                  <td>
                    {{lessonHistory.trainingMenu1Name}}<br />
                    {{lessonHistory.trainingMenu2Name}}
                  </td>
                </tr>
                </tbody>
              </table>
            </div>
            <div v-else>
              データが登録されていません。
            </div>
          </div>

        </div>
        <div class="button-block">
          <button type="button" @click="editLessonReservation()" >編集</button>
          <button type="button" @click="deleteLessonReservation()" >削除</button>
          <button type="button" @click="closeLessonReservation()" >閉じる</button>
        </div>
      </div>
    </div>
  </Base>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import Calendar from "@/components/common/CalendarComponent.vue";
import moment from "moment";
import {Coaches} from "@/domains/coach/Coaches";
import {CoachSchedules} from "@/domains/coach_schedule/CoachSchedules";
import {LessonReservations} from "@/domains/lesson_reservation/LessonReservations";
import CoachesClient from "@/http_clients/admin/CoachesClient";
import LessonReservationsClient from "@/http_clients/admin/LessonReservationsClient";
import CoachSchedulesClient from "@/http_clients/admin/CoachSchedulesClient";
import {Coach} from "@/domains/coach/Coach";
import {LessonReservation} from "@/domains/lesson_reservation/LessonReservation";
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import {CoachSchedule} from "@/domains/coach_schedule/CoachSchedule";

export default defineComponent({
  name: 'AdminIndex',
  components: {
    Base,
    Calendar,
  },
  data() {
    return {
      startTime: "9:00" as string,
      endTime: "22:00" as string,
      timeInterval: 30 as number,
      calenderHeight: 40 as number,
      scheduleTimes: [] as string[],
      targetDate: moment().format("YYYY-MM-DD") as string,
      coaches: new Coaches([]) as Coaches,
      coachSchedules: new CoachSchedules([]) as CoachSchedules,
      lessonReservations: new LessonReservations([]) as LessonReservations,
      coachScheduleReservations: [] as CoachScheduleReservation[],
      loading: false as boolean,
      dialogLessonReservation: null as LessonReservation | null,
      tempDialogLessonReservation: null as LessonReservation | null,
      dialogStudentLessonHistories: new LessonReservations([]) as LessonReservations,
      confirmMessage: null as string | null,
      successMessage: null as string | null,
      errorMessage: null as string | null,
    }
  },
  watch: {
    async $route() {
      this.loading = true;
      if (this.$router.currentRoute.value.path != "/admin") {
        return;
      }
      if (this.$route.query.target_date) {
        this.targetDate = this.$route.query.target_date as string;
      } else {
        this.targetDate = moment().format("YYYY-MM-DD");
      }
      await this.setSchedule();
      this.loading = false;
    }
  },
  async created() {
    if (this.$route.query.target_date) {
      this.targetDate = this.$route.query.target_date as string;
    }
    this.scheduleTimes = this.createScheduleTimes();

    const coachesClient = new CoachesClient();
    this.coaches = await coachesClient.getScheduleCoaches();

    await this.setSchedule();
  },
  methods: {
    async setSchedule() {
      const lessonReservationsClient = new LessonReservationsClient();
      this.lessonReservations = await lessonReservationsClient.getTargetDateSchedules(this.targetDate);

      const coachSchedulesClient = new CoachSchedulesClient();
      this.coachSchedules = await coachSchedulesClient.getTargetDateSchedules(this.targetDate);

      this.coachScheduleReservations = this.getCoachScheduleReservations();
    },
    changeTargetDate(targetDate: string) {
      this.targetDate = targetDate;
      this.$router.push({query: { target_date: this.targetDate } });
    },
    createScheduleTimes() {
      let timeMoment = moment(this.startTime, 'HH:mm');
      let endTimeMoment = moment(this.endTime, 'HH:mm');
      let times = [];

      while (timeMoment.isSameOrBefore(endTimeMoment)) {
        times.push(timeMoment.format('HH:mm'));
        timeMoment.add(this.timeInterval, 'minute');
      }
      return times;
    },
    getCoachScheduleReservations(): CoachScheduleReservation[] {
      return this.coaches.filterNotHoliday(this.coachSchedules).list.map((coach) => {
        let coachLessonReservations = new LessonReservations([]);
        let coachCoachSchedules = new CoachSchedules([]);
        if (coach.id != null) {
          coachLessonReservations = this.lessonReservations.filterCoach(coach.id);
          coachCoachSchedules = this.coachSchedules.filterCoach(coach.id);
        }

        const schedules: ScheduleReservation[] = this.scheduleTimes.map((scheduleTime) => {
          const lessonReservations = coachLessonReservations.filterScheduleTime(scheduleTime, this.timeInterval);
          const coachSchedules = coachCoachSchedules.filterScheduleTime(scheduleTime, this.timeInterval);

          return {
            scheduleTime: scheduleTime,
            lessonReservations: lessonReservations,
            coachSchedules: coachSchedules,
          };
        });

        return {
          coach: coach,
          schedules: schedules,
        };
      });
    },
    async displayDetail(lessonReservation: LessonReservation) {
      this.dialogLessonReservation = lessonReservation;

      if (lessonReservation.studentId != null) {
        const lessonReservationsClient: LessonReservationsClient = new LessonReservationsClient();
        this.dialogStudentLessonHistories = await lessonReservationsClient.getStudentSchedules(lessonReservation.studentId);
      }
    },
    editCoachSchedule(coachSchedule: CoachSchedule) {
      if (coachSchedule.id != null) {
        this.$router.push({name: 'AdminCoachScheduleEdit', params: { coachScheduleId: coachSchedule.id.getId() }, query: { base_page: 'index', target_date: this.targetDate } });
      }
    },
    closeLessonReservation() {
      this.dialogLessonReservation = null;
      this.dialogStudentLessonHistories = new LessonReservations([]);
    },
    clickNothing(e: PointerEvent) {
      e.stopPropagation();
    },
    editLessonReservation() {
      this.$router.push({name: 'AdminLessonReservationIndex', query: { lesson_reservation_id: this.dialogLessonReservation?.id?.getId(), target_date: this.targetDate } });
    },
    deleteLessonReservation() {
      this.tempDialogLessonReservation = this.dialogLessonReservation;
      this.dialogLessonReservation = null;
      this.confirmMessage = "削除してもよろしいですか？";
    },
    async resultConfirm(result: boolean) {
      if (result) {
        if (this.tempDialogLessonReservation == null) {
          this.confirmMessage = null;
          this.errorMessage = "予約の削除に失敗しました。";
          return;
        }
        const response: ResponseInterface = await this.tempDialogLessonReservation.delete();
        this.confirmMessage = null;
        if (response.result) {
          this.tempDialogLessonReservation = null;
          this.dialogStudentLessonHistories = new LessonReservations([]);
          this.successMessage = "削除を完了しました。";
        } else {
          this.errorMessage = "予約の削除に失敗しました。";
        }
      } else {
        this.dialogLessonReservation = this.tempDialogLessonReservation;
        this.tempDialogLessonReservation = null;
        this.confirmMessage = null;
      }
    },
    async resultSuccess() {
      this.successMessage = null;
      await this.setSchedule();
    },
    registerStudent() {
      if (this.dialogLessonReservation == null) {
        return;
      }
      this.$router.push({name: 'AdminStudentNew', query: { lesson_reservation_id: this.dialogLessonReservation.id?.getId() } });
    }
  }
});

export interface CoachScheduleReservation {
  coach: Coach;
  schedules: ScheduleReservation[];
}

export interface ScheduleReservation {
  scheduleTime: string;
  lessonReservations: LessonReservations;
  coachSchedules: CoachSchedules;
}
</script>

<style lang="scss" scoped>
.top-page {
  margin-top: 40px;
}

.coach-schedule {
  display: flex;
  text-align: center;
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
  margin-top: 20px;

  .header {
    line-height: 30px;
    border-top: 1px solid #000000;
    border-left: 1px solid #000000;
  }

  .schedule-body {
    height: 40px;
    border-top: 1px solid #000000;
    border-left: 1px solid #000000;
    position: relative;
  }

  .time-block {
    flex: 0 0 50px;
    background-color: #EBEBEB;
  }

  .coach-block {
    flex: 0 0 130px;

    .header {
      color: #ffffff;
    }
  }
}

.exist-reservation {
  position: absolute;
  width: 100%;
  text-align: left;
  font-size: 11px;
  line-height: 13px;
  padding-top: 1px;
  background-color: #dddddd;
  height: 39px;
  z-index: 2;
  cursor: pointer;
  overflow:hidden;
}

.schedule {
  position: absolute;
  width: 100%;
  text-align: left;
  font-size: 11px;
  line-height: 13px;
  padding-top: 1px;
  background-color: #dddddd;
  height: 39px;
  z-index: 2;
  cursor: pointer;
  overflow:hidden;
}

.lesson-reservation-detail-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.lesson-reservation-detail-dialog {
  background-color: #ffffff;
  flex: 0 1 600px;
  margin: 40px;
  padding: 20px;
  border-radius: 10px;
}

.button-block {
  margin-top: 30px;

  display: flex;
  justify-content: center;

  button {
    margin: 0 40px;
  }
}

.lesson-reservation-block {

  .reservation-date-time {
    font-weight: bold;
    margin-bottom: 20px;
    display: flex;
    align-items: flex-end;
  }
  .reservation-date {
    font-size: 20px;
    margin-right: 20px;
  }
  .reservation-time {
    font-size: 16px;
  }

  .lesson-reservation-information {
    margin-bottom: 10px;
    display: flex;

    .title {
      flex: 0 0 110px;
    }
    .value {
      white-space: pre-wrap;
    }
    .note {
      max-height: 120px;
      width: 100%;
      overflow-y: auto;
      border: 1px solid #000000;
      padding: 5px;
    }
  }

  .list-block {
    font-size: 12px;
    th {
      padding: 3px;
    }
  }

  .register-button {
    padding: 2px 20px;
    margin-left: 50px;
  }
}
</style>
