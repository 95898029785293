<template>
  <section class="calendar">
    <v-date-picker v-model="date" :attributes='attrs' class="calendar-block" :min-date='new Date()' :max-date="maxDate" >
      <template v-slot="{ inputEvents }">
        <span v-on="inputEvents" class="calendar-select-text">カレンダーから選択</span>
        <div class="calendar-date">
          <span @click="prevDate" class="date-edit" v-if="date > new Date()">＜</span>
          <span class="date-edit" v-if="date <= new Date()"></span>
          <span v-on="inputEvents">{{displayTargetDate()}}</span>
          <span @click="nextDate" class="date-edit" v-if="date < maxDate">＞</span>
        </div>
      </template>
    </v-date-picker>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import moment from 'moment';

export default defineComponent({
  name: 'FutureOnlyCalendarComponent',
  props: {
    targetDate: String,
  },
  data() {
    return {
      attrs: [
        {
          key: 'today',
          dot: true,
          dates: new Date(),
        },
      ],
      date: new Date(),
      maxDate: new Date(),
    };
  },
  watch: {
    date() {
      this.$emit('selectTargetDate', moment(this.date).format("YYYY-MM-DD"));
    }
  },
  created() {
    this.date = moment(this.targetDate).toDate();
    this.maxDate = moment(moment().format("YYYY-MM-DD")).add(2, 'months').toDate();
  },
  methods: {
    displayTargetDate() {
      return moment(this.targetDate).format('M月D日(ddd)');
    },
    prevDate() {
      this.date = moment(this.targetDate).add(-1, 'days').toDate();
    },
    nextDate() {
      this.date = moment(this.targetDate).add(1, 'days').toDate();
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.calendar-block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.calendar-select-text {
  font-size: 12px;
  color: #FF2F92;
  margin-bottom: 5px;
}

.calendar-date {
  font-size: 18px;
  span {
    margin: 10px;
  }

  .date-edit {
    cursor: pointer;
    display: inline-block;
    width: 18px;
  }
}
</style>
