<template>
  <section class="error-message" v-if="errorMessage != null && errorMessage !== ''">
    {{ errorMessage }}
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ErrorMessageComponent',
  props: {
    errorMessage: String,
  },
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.error-message {
  background-color: #fd7f7f;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 20px;
}

</style>
