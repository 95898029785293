import HttpClientBase from '../HttpClientBase';
import {AxiosResponse} from "axios";
import {FloorGroup} from "@/domains/floor_group/FloorGroup";
import {FloorGroups} from "@/domains/floor_group/FloorGroups";
import FloorGroupId from "@/domains/floor_group/value_object/FloorGroupId";

export default class FloorGroupsClient extends HttpClientBase {

  public async getFloorGroups(): Promise<FloorGroups> {
    const param = this.getAccessTokenParam();
    const response: AxiosResponse<FloorGroupsResponseInterface> = await this.getRequest('/admin/floor_groups', param);

    const floorGroups: FloorGroup[] = [];

    response.data.floor_groups.forEach((floorGroup) => {
      floorGroups.push(new FloorGroup(new FloorGroupId(floorGroup.id), floorGroup.name, floorGroup.color));
    });

    return new FloorGroups(floorGroups);
  }
}

export interface FloorGroupsResponseInterface {
  floor_groups: FloorGroupResponseInterface[];
}

export interface FloorGroupResponseInterface {
  id: number;
  name: string;
  color: string;
}
