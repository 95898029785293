
import { defineComponent } from 'vue';
import moment from 'moment';

export default defineComponent({
  name: 'FutureOnlyCalendarComponent',
  props: {
    targetDate: String,
  },
  data() {
    return {
      attrs: [
        {
          key: 'today',
          dot: true,
          dates: new Date(),
        },
      ],
      date: new Date(),
      maxDate: new Date(),
    };
  },
  watch: {
    date() {
      this.$emit('selectTargetDate', moment(this.date).format("YYYY-MM-DD"));
    }
  },
  created() {
    this.date = moment(this.targetDate).toDate();
    this.maxDate = moment(moment().format("YYYY-MM-DD")).add(2, 'months').toDate();
  },
  methods: {
    displayTargetDate() {
      return moment(this.targetDate).format('M月D日(ddd)');
    },
    prevDate() {
      this.date = moment(this.targetDate).add(-1, 'days').toDate();
    },
    nextDate() {
      this.date = moment(this.targetDate).add(1, 'days').toDate();
    }
  }
});
