import {Floor} from "@/domains/floor/Floor";
import FloorId from "@/domains/floor/value_object/FloorId";

export class Floors {
  public list: Floor[];

  constructor(list: Floor[]) {
    this.list = list;
  }

  public isPresent(): boolean {
    return !this.isEmpty();
  }

  public isEmpty(): boolean {
    return this.list.length == 0;
  }

  public find(floorId: FloorId): Floor | null {
    for (let i = 0; i < this.list.length; i++) {
      const floor = this.list[i];
      if (floor.id == null) {
        continue;
      }
      if (floor.id.getId() == floorId.getId()) {
        return floor;
      }
    }
    return null;
  }
}