<template>
  <section class="confirm-message" v-if="confirmMessage != null && confirmMessage !== ''">
    <div class="confirm-message-background" @click="cancel">
      <div class="confirm-message-dialog" @click="clickNothing">
        <div class="message">
          {{ confirmMessage }}
        </div>
        <div class="button-block">
          <button type="button" @click="ok" >OK</button>
          <button type="button" @click="cancel">キャンセル</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'ConfirmMessageComponent',
  props: {
    confirmMessage: String,
  },
  methods: {
    cancel() {
      this.$emit('resultConfirm', false);
    },
    ok() {
      this.$emit('resultConfirm', true);
    },
    clickNothing(e: PointerEvent) {
      e.stopPropagation();
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.confirm-message-background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.confirm-message-dialog {
  background-color: #ffffff;
  flex: 0 1 600px;
  margin: 40px;
  padding: 20px;
  border-radius: 10px;
}

.button-block {
  margin-top: 60px;
  min-width: 300px;
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;

  display: flex;
  justify-content: space-between;

  input {
    width: 120px;
    padding: 5px 10px;
  }
}
</style>
