
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import CoachSchedulesInput from '@/components/admin/coach_schedules/InputComponent.vue';
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import {CoachSchedule} from "@/domains/coach_schedule/CoachSchedule";
import {Coach} from "@/domains/coach/Coach";
import CoachesClient from "@/http_clients/admin/CoachesClient";

export default defineComponent({
  name: 'CoachScheduleNew',
  components: {
    Base,
    CoachSchedulesInput,
  },
  data() {
    return {
      errorMessage: null as string | null,
      successMessage: null as string | null,
      coachSchedule: new CoachSchedule(null, null, null,null,null,null,null,null,null,
          null,null,null,null,null,null,null,null,
          null) as CoachSchedule,
      currentCoach: null as Coach | null,
    }
  },
  async created() {
    const coachesClient = new CoachesClient();
    this.currentCoach = await coachesClient.getCurrentCoach();
    if (this.currentCoach != null) {
      this.coachSchedule = new CoachSchedule(null, this.currentCoach.id, null,null,null,null,null,null,null,
          null,null,null,null,null,null,null,null,
          null);
    }
  },
  methods: {
    changeCoachSchedule(coachSchedule: CoachSchedule) {
      this.coachSchedule = coachSchedule;
    },
    async saveCoachSchedule() {
      if (this.coachSchedule.validateStartTime()) {
        this.errorMessage = "開始時間は9:00以降を指定してください。";
        return;
      }
      if (this.coachSchedule.validateEndTime()) {
        this.errorMessage = "終了時間は22:00以前を指定してください。";
        return;
      }
      if (this.coachSchedule.validateStartTimeAndEndTime()) {
        this.errorMessage = "終了時間は開始時間より後の時間を指定してください。";
        return;
      }

      const response: ResponseInterface = await this.coachSchedule.insert();
      if (response.result) {
        this.successMessage = "登録を完了しました。";
      } else {
        if (response.status == 400) {
          if (response.errorCode == 'exist_coach_schedule') {
            this.errorMessage = "既にスタッフに予定が登録されています。";
          } else if (response.errorCode == 'exist_lesson_reservation') {
            this.errorMessage = "既にスタッフにレッスンが登録されています。";
          }
        } else {
          this.errorMessage = "予定の登録処理に失敗しました。";
        }
      }
    },
    resultSuccess() {
      this.$router.push('/admin/coach_schedules');
    },
  }
});
