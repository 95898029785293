
import { defineComponent } from 'vue';
import Base from '@/components/common/BaseComponent.vue';
import StudentInput from "@/components/students/InputComponent.vue";
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import {Student} from "@/domains/student/Student";
import StudentsClient from "@/http_clients/StudentsClient";

export default defineComponent({
  name: 'StudentEdit',
  components: {
    Base,
    StudentInput,
  },
  data() {
    return {
      errorMessage: null as string | null,
      successMessage: null as string | null,
      student: new Student(null, null, null, null, null, null, null,
          null, null, null, null, "male", null, null, null, true,
          null, null, null, null) as Student,
    }
  },
  async created() {
    const studentsClient = new StudentsClient();
    const student = await studentsClient.getCurrentStudent();

    if (student != null) {
      this.student = student;
    } else {
      this.$router.push({name: 'SignIn'});
    }
  },
  methods: {
    changeStudent(student: Student) {
      this.student = student;
    },
    async saveStudent() {
      const response: ResponseInterface = await this.student.updateCurrentStudent();
      if (response.result) {
        this.successMessage = "更新を完了しました。";
      } else {
        this.errorMessage = "更新に失敗しました。";
      }
    },
    resultSuccess() {
      this.$router.push({name: 'Index'});
    },
  }
});
