
import { defineComponent } from 'vue';
import {Student} from "@/domains/student/Student";
import StudentUserId from "@/domains/student/value_object/StudentUserId";

export default defineComponent({
  name: 'AdminStudentsInputComponent',
  props: {
    student: Student,
    editMode: Boolean,
  },
  data() {
    return {
      userId: null as string | null,
      password: null as string | null,
      name: null as string | null,
      nameKana: null as string | null,
      postalCode: null as string | null,
      address: null as string | null,
      tel1: null as string | null,
      tel2: null as string | null,
      email: null as string | null,
      birthday: null as string | null,
      gender: "male" as string,
      business: null as string | null,
      motivationComingStore: null as string | null,
      note1: null as string | null,
      note2: null as string | null,
      deliveryMail: true as boolean,
    }
  },
  watch: {
    student: function () {
      if (this.student != null) {
        if (this.student.userId != null) {
          this.userId = this.student.userId.getId();
        }
        this.name = this.student.name;
        this.nameKana = this.student.nameKana;
        this.postalCode = this.student.postalCode;
        this.address = this.student.address;
        this.tel1 = this.student.tel1;
        this.tel2 = this.student.tel2;
        this.email = this.student.email;
        this.birthday = this.student.birthday;
        this.gender = this.student.gender;
        this.business = this.student.business;
        this.motivationComingStore = this.student.motivationComingStore;
        this.note1 = this.student.note1;
        this.note2 = this.student.note2;
        this.deliveryMail = this.student.deliveryMail;
      }
    },
    userId: function () {
      this.setStudent();
    },
    password: function () {
      this.setStudent();
    },
    name: function () {
      this.setStudent();
    },
    nameKana: function () {
      this.setStudent();
    },
    postalCode: function () {
      this.setStudent();
    },
    address: function () {
      this.setStudent();
    },
    tel1: function () {
      this.setStudent();
    },
    tel2: function () {
      this.setStudent();
    },
    email: function () {
      this.setStudent();
    },
    birthday: function () {
      this.setStudent();
    },
    gender: function () {
      this.setStudent();
    },
    business: function () {
      this.setStudent();
    },
    motivationComingStore: function () {
      this.setStudent();
    },
    note1: function () {
      this.setStudent();
    },
    note2: function () {
      this.setStudent();
    },
    deliveryMail: function () {
      this.setStudent();
    },
  },
  methods: {
    setStudent() {
      let id = null;
      if (this.student != null) {
        id = this.student.id;
      }
      let userId = null;
      if (this.userId != null) {
        userId = new StudentUserId(this.userId);
      }

      let comingStoreCount = null;
      let firstComingStoreDate = null;
      let lastComingStoreDate = null;
      if (this.student != null) {
        comingStoreCount = this.student.comingStoreCount;
        firstComingStoreDate = this.student.firstComingStoreDate;
        lastComingStoreDate = this.student.lastComingStoreDate;
      }

      const student = new Student(id,
          userId,
          this.password,
          this.name,
          this.nameKana,
          this.postalCode,
          this.address,
          this.tel1,
          this.tel2,
          this.email,
          this.birthday,
          this.gender,
          this.business,
          this.note1,
          this.note2,
          this.deliveryMail,
          this.motivationComingStore,
          comingStoreCount,
          firstComingStoreDate,
          lastComingStoreDate,
      );
      this.$emit('changeStudent', student);
    }
  }
});
