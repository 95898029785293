<template>
  <router-view/>
</template>

<script lang="ts">
import { defineComponent } from 'vue';


export default defineComponent({
  name: 'App',
});
</script>

<style lang="scss">
html, body, #app {
  min-height: 100%;
  margin: 0;
  font-size: 14px;
}

h1 {
  margin: 0;
  margin-bottom: 20px;
  font-size: 20px;
}

input[type="text"],
input[type="password"],
input[type="email"],
input[type="date"],
input[type="time"],
input[type="tel"],
input[type="number"]  {
  font-size: 16px;
}

select {
  font-size: 16px;
  padding: 3px;
}

textarea {
  font-size: 16px;
  width: 100%;
  height: 200px;
}

button {
  appearance: none;
  -webkit-appearance: none;
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 40px;
  color:#000000;
}

table {
  border-collapse: collapse;
  width: 100%;
  tr {
    th {
      border: 1px solid #000000;
      padding: 10px 5px;
      font-weight: normal;
      background-color: #dddddd;
    }
    td {
      border: 1px solid #000000;
      padding: 5px;
    }
  }
}
</style>
