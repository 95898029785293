
import { defineComponent } from 'vue';
import ErrorMessage from "@/components/common/ErrorMessageComponent.vue";
import ConfirmMessage from "@/components/common/ConfirmMessageComponent.vue";
import SuccessMessage from "@/components/common/SuccessMessageComponent.vue";
import {MyAccount} from "@/domains/MyAccount";
import {Student} from "@/domains/student/Student";

export default defineComponent({
  name: 'BaseComponent',
  components: {
    ErrorMessage,
    ConfirmMessage,
    SuccessMessage,
  },
  props: {
    errorMessage: String,
    confirmMessage: String,
    successMessage: String,
    loading: Boolean,
  },
  data() {
    return {
      showMenu: false as boolean,
      student: null as Student | null,
    }
  },
  async mounted() {
    const myAccount = new MyAccount();
    this.student = await myAccount.getCurrentStudent();
    this.$emit('setLoginStudent', this.student);
  },
  methods: {
    resultConfirm(result: boolean) {
      this.$emit('resultConfirm', result);
    },
    resultSuccess(result: boolean) {
      this.$emit('resultSuccess', result);
    },
    clickMenu() {
      this.showMenu = true;
    },
    cancelMenu() {
      this.showMenu = false;
    },
    clickNothing(e: PointerEvent) {
      e.stopPropagation();
    },
    async signOut() {
      const myAccount = new MyAccount();
      if (await myAccount.signOutStudent()) {
        this.$router.push('/sign_in');
      }
    },
  }
});
