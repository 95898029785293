<template>
  <Base :errorMessage="errorMessage" :confirmMessage="confirmMessage" :successMessage="successMessage"
        @resultConfirm="resultConfirm" @resultSuccess="resultSuccess" >
    <section class="admin-students-edit">
      <div class="header">
        <h1>会員アカウント編集</h1>
      </div>
      <form @submit.prevent="saveStudent">
        <StudentInput :student="student" :editMode="true" @changeStudent="changeStudent"/>
        <div class="button-block">
          <button type="submit" class="approval-button" >更新</button>
          <button type="button" class="approval-button" @click="deleteStudent" >削除</button>
        </div>
      </form>
    </section>
  </Base>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import StudentInput from "@/components/admin/students/InputComponent.vue";
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import {Student} from "@/domains/student/Student";
import StudentsClient from "@/http_clients/admin/StudentsClient";
import StudentId from "@/domains/student/value_object/StudentId";

export default defineComponent({
  name: 'StudentEdit',
  components: {
    Base,
    StudentInput,
  },
  data() {
    return {
      errorMessage: null as string | null,
      confirmMessage: null as string | null,
      successMessage: null as string | null,
      student: new Student(null, null, null, null, null, null, null,
          null, null, null, null, "male", null, null, null, true,
          null, null, null, null) as Student,
    }
  },
  async created() {
    const studentsClient = new StudentsClient();
    this.student = await studentsClient.getData(new StudentId(parseInt(this.$route.params.studentId as string)));
  },
  methods: {
    changeStudent(student: Student) {
      this.student = student;
    },
    async saveStudent() {
      const response: ResponseInterface = await this.student.update();
      if (response.result) {
        this.successMessage = "更新を完了しました。";
      } else {
        if (response.status == 422) {
          this.errorMessage = "ユーザーIDは既に使用されています。";
        } else {
          this.errorMessage = "会員の更新に失敗しました。";
        }
      }
    },
    deleteStudent() {
      this.confirmMessage = "削除してもよろしいですか？";
    },
    async resultConfirm(result: boolean) {
      if (result) {
        const response: ResponseInterface = await this.student.delete();
        this.confirmMessage = null;
        if (response.result) {
          this.successMessage = "削除を完了しました。";
        } else {
          this.errorMessage = "会員の削除に失敗しました。";
        }
      } else {
        this.confirmMessage = null;
      }
    },
    resultSuccess() {
      this.$router.push('/admin/students');
    },
  }
});
</script>

<style lang="scss" scoped>
.admin-students-edit {
  max-width: 600px;
  padding-bottom: 60px;
}

.button-block {
  margin-top: 60px;

  display: flex;
  justify-content: center;

  button {
    margin: 0 40px;
  }
}
</style>
