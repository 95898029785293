
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import LessonReservationsInput from '@/components/admin/lesson_reservations/InputComponent.vue';
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import {LessonReservation} from "@/domains/lesson_reservation/LessonReservation";
import moment from "moment";
import FloorId from "@/domains/floor/value_object/FloorId";

export default defineComponent({
  name: 'LessonReservationNew',
  components: {
    Base,
    LessonReservationsInput,
  },
  data() {
    return {
      errorMessage: null as string | null,
      successMessage: null as string | null,
      lessonReservation: new LessonReservation(null, true,null, null,null,null,null,null,null,
          null,null,null,null,null,null,null,null,null,
          null,null,null,null,null) as LessonReservation,
    }
  },
  created() {
    let startDate = null;
    if (this.$route.query.target_date) {
      startDate = this.$route.query.target_date as string;
    }

    let floorId = null;
    if (this.$route.query.floor_id) {
      floorId = new FloorId(parseInt(this.$route.query.floor_id as string));
    }

    let startTime = null;
    if (this.$route.query.schedule_time) {
      startTime = this.$route.query.schedule_time as string;
    }

    this.lessonReservation = new LessonReservation(
        null,
        true,
        null,
        null,
        null,
        null,
        null,
        null,
        floorId,
        null,
        null,
        startDate,
        startTime,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null);
  },
  methods: {
    changeLessonReservation(lessonReservation: LessonReservation) {
      this.lessonReservation = lessonReservation;
    },
    async saveLessonReservation() {

      if (this.lessonReservation.member && this.lessonReservation.studentId == null) {
        this.errorMessage = "会員番号が間違っています。";
        return;
      }

      if (this.lessonReservation.trainingMenuId1 != null && this.lessonReservation.trainingMenuId2 != null) {
        if (this.lessonReservation.trainingMenuId1.getId() == this.lessonReservation.trainingMenuId2.getId()) {
          this.errorMessage = "同じメニューを選択しないでください。";
          return;
        }
      }

      const response: ResponseInterface = await this.lessonReservation.insert();
      if (response.result) {
        this.successMessage = "登録を完了しました。";
      } else {
        if (response.status == 400) {
          if (response.errorCode == 'exist_coach_schedule') {
            this.errorMessage = "既にスタッフに予定が登録されています。";
          } else if (response.errorCode == 'exist_lesson_reservation') {
            this.errorMessage = "フロアが空いていません。";
          } else if (response.errorCode == 'exist_student_schedule') {
            this.errorMessage = "既に会員に予定が登録されています。";
          }
        } else {
          this.errorMessage = "予約の登録に失敗しました。";
        }
      }
    },
    resultSuccess() {
      this.$router.push({name: 'AdminLessonReservationIndex', query: { target_date: moment(this.lessonReservation.startDate).format("YYYY-MM-DD") }});
    },
  }
});
