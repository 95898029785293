
import { defineComponent } from 'vue';
import ErrorMessage from '@/components/common/ErrorMessageComponent.vue';
import {MyAccount} from "@/domains/MyAccount";

export default defineComponent({
  name: 'SignIn',
  components: {
    ErrorMessage,
  },
  data() {
    return {
      userId: '' as string,
      password: '' as string,
      errorMessage: null as string | null,
    }
  },
  methods: {
    async logIn() {
      const myAccount = new MyAccount();
      if (await myAccount.signInStudent(this.userId, this.password)) {

        if (this.$route.query.floor_id != null && this.$route.query.target_date != null && this.$route.query.schedule_time != null) {
          const floorId = this.$route.query.floor_id as string;
          const targetDate = this.$route.query.target_date as string;
          const scheduleTime = this.$route.query.schedule_time as string;
          this.$router.push({name: 'LessonReservationNew', query: { floor_id: floorId, target_date: targetDate, schedule_time: scheduleTime }});
        } else {
          this.$router.push('/');
        }
      } else {
        this.errorMessage = "ログインに失敗しました。";
      }

    },
  },
});
