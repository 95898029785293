<template>
  <section class="admin-training-menus-input">
    <div class="input-block">
      <span>フロア:</span>
      <select v-model="floorGroupId" required>
        <option v-for="floorGroup in floorGroups.list" :key="floorGroup.id.getId()" :value="floorGroup.id.getId()">{{floorGroup.name}}</option>
      </select>
    </div>
    <div class="input-block">
      <span>メニュー名:</span>
      <input type="text" v-model="name" required/>
    </div>
    <div class="input-block">
      <span>トレーニング時間:</span>
      <select v-model="trainingMinutes" required>
        <option v-for="selectList in trainingMinutesSelects" :key="selectList" :value="selectList">{{selectList}}分</option>
      </select>
    </div>
    <div class="input-block">
      <span>色:</span>
      <div class="color-block">
        <label class="color-item" v-for="colorSelect in colorSelects" :key="colorSelect">
          <input type="radio" :value="colorSelect" v-model="color"/>
          <div class="color-sample" :style="{backgroundColor: colorSelect}"></div>
        </label>
      </div>
    </div>
    <div class="input-block input-textarea-block">
      <span>備考:</span>
      <textarea v-model="note"/>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import FloorGroupsClient from "@/http_clients/admin/FloorGroupsClient";
import {FloorGroups} from "@/domains/floor_group/FloorGroups";
import FloorGroupId from "@/domains/floor_group/value_object/FloorGroupId";
import {TrainingMenu} from "@/domains/training_menu/TrainingMenu";

export default defineComponent({
  name: 'AdminTrainingMenusInputComponent',
  props: {
    trainingMenu: TrainingMenu,
  },
  data() {
    return {
      floorGroupId: null as number | null,
      name: null as string | null,
      trainingMinutes: null as number | null,
      color: null as string | null,
      note: null as string | null,
      floorGroups: new FloorGroups([]) as FloorGroups,
      trainingMinutesSelects: [] as number[],
      colorSelects: [] as string[],
    }
  },
  watch: {
    trainingMenu: function () {
      if (this.trainingMenu != null) {
        if (this.trainingMenu.floorGroupId != null) {
          this.floorGroupId = this.trainingMenu.floorGroupId.getId();
        }
        this.name = this.trainingMenu.name;
        this.trainingMinutes = this.trainingMenu.trainingMinutes;
        this.color = this.trainingMenu.color;
        this.note = this.trainingMenu.note;
      }
    },
    floorGroupId: function () {
      this.setTrainingMenu();
    },
    name: function () {
      this.setTrainingMenu();
    },
    trainingMinutes: function () {
      this.setTrainingMenu();
    },
    color: function () {
      this.setTrainingMenu();
    },
    note: function () {
      this.setTrainingMenu();
    },
  },
  async created() {
    const floorGroupsClient = new FloorGroupsClient();
    this.floorGroups = await floorGroupsClient.getFloorGroups();
    if (this.trainingMenu != null) {
      this.trainingMinutesSelects = this.trainingMenu.getTrainingMinutesSelectList();
      this.colorSelects = this.trainingMenu.getColorSelectList();

      if (this.trainingMenu.color != null) {
        this.color = this.trainingMenu.color;
      } else {
        this.color = this.colorSelects[0];
      }
    }
  },
  methods: {
    setTrainingMenu() {
      let id = null;
      if (this.trainingMenu != null) {
        id = this.trainingMenu.id;
      }
      let floorGroupId = null;
      if (this.floorGroupId != null) {
        floorGroupId = new FloorGroupId(this.floorGroupId);
      }

      const trainingMenu = new TrainingMenu(id,
          floorGroupId,
          null,
          this.name,
          this.trainingMinutes,
          this.color,
          this.note);
      this.$emit('changeTrainingMenu', trainingMenu);
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.input-block {
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  span {
    display: inline-block;
    flex: 0 0 130px;
  }

  .color-sample {
    width: 40px;
    height: 20px;
    border: 1px solid #000000;
    border-radius: 5px;
    margin-right: 20px;
  }

  .color-block {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  .color-item {
    display: flex;
    margin-bottom: 10px;
  }
}

.input-textarea-block {
  align-items: flex-start;
}
</style>
