import StudentUserId from "./value_object/StudentUserId";
import StudentsClient from "@/http_clients/admin/StudentsClient";
import StudentsByStudentClient from "@/http_clients/StudentsClient";
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import StudentId from "@/domains/student/value_object/StudentId";
import LessonReservationId from "@/domains/lesson_reservation/value_object/LessonReservationId";

export class Student {
  public id: StudentId | null;
  public userId: StudentUserId | null;
  public readonly password: string | null;
  public name: string | null;
  public nameKana: string | null;
  public postalCode: string | null;
  public address: string | null;
  public tel1: string | null;
  public tel2: string | null;
  public email: string | null;
  public birthday: string | null;
  public gender: string;
  public business: string | null;
  public note1: string | null;
  public note2: string | null;
  public deliveryMail: boolean;

  public motivationComingStore: string | null;
  public comingStoreCount: number | null;
  public firstComingStoreDate: string | null;
  public lastComingStoreDate: string | null;

  constructor(id: StudentId | null,
              userId: StudentUserId | null,
              password: string | null,
              name: string | null,
              nameKana: string | null,
              postalCode: string | null,
              address: string | null,
              tel1: string | null,
              tel2: string | null,
              email: string | null,
              birthday: string | null,
              gender: string,
              business: string | null,
              note1: string | null,
              note2: string | null,
              deliveryMail: boolean,
              motivationComingStore: string | null,
              comingStoreCount: number | null,
              firstComingStoreDate: string | null,
              lastComingStoreDate: string | null) {
    this.id = id;
    this.userId = userId;
    this.password = password;
    this.name = name;
    this.nameKana = nameKana;
    this.postalCode = postalCode;
    this.address = address;
    this.tel1 = tel1;
    this.tel2 = tel2;
    this.email = email;
    this.birthday = birthday;
    this.gender = gender;
    this.business = business;
    this.note1 = note1;
    this.note2 = note2;
    this.deliveryMail = deliveryMail;
    this.motivationComingStore = motivationComingStore;
    this.comingStoreCount = comingStoreCount;
    this.firstComingStoreDate = firstComingStoreDate;
    this.lastComingStoreDate = lastComingStoreDate;
  }

  public async insert(lessonReservationId = null as LessonReservationId | null): Promise<ResponseInterface> {
    const studentsClient: StudentsClient = new StudentsClient();
    return await studentsClient.insert(this, lessonReservationId);
  }

  public async update(): Promise<ResponseInterface> {
    const studentsClient: StudentsClient = new StudentsClient();
    return await studentsClient.update(this);
  }

  public async delete(): Promise<ResponseInterface> {
    const studentsClient: StudentsClient = new StudentsClient();
    return await studentsClient.delete(this);
  }

  public async updateCurrentStudent(): Promise<ResponseInterface> {
    const studentsByStudentClient: StudentsByStudentClient = new StudentsByStudentClient();
    return await studentsByStudentClient.update(this);
  }

  public getFormData(): FormData {
    const formData: FormData = new FormData();

    if (this.userId != null) {
      formData.append("user_id", this.userId.getId().toString());
    }
    if (this.password != null) {
      formData.append("password", this.password);
    }
    if (this.name != null) {
      formData.append("name", this.name);
    }
    if (this.nameKana != null) {
      formData.append("name_kana", this.nameKana);
    }
    if (this.postalCode != null) {
      formData.append("postal_code", this.postalCode);
    }
    if (this.address != null) {
      formData.append("address", this.address);
    }
    if (this.tel1 != null) {
      formData.append("tel1", this.tel1);
    }
    if (this.tel2 != null) {
      formData.append("tel2", this.tel2);
    }
    if (this.email != null) {
      formData.append("email", this.email);
    }
    if (this.birthday != null) {
      formData.append("birthday", this.birthday);
    }
    if (this.gender != null) {
      formData.append("gender", this.gender);
    }
    if (this.business != null) {
      formData.append("business", this.business);
    }
    if (this.motivationComingStore != null) {
      formData.append("motivation_coming_store", this.motivationComingStore);
    }
    if (this.note1 != null) {
      formData.append("note1", this.note1);
    }
    if (this.note2 != null) {
      formData.append("note2", this.note2);
    }
    if (this.deliveryMail != null) {
      formData.append("delivery_mail", this.deliveryMail? "true": "false");
    }
    return formData;
  }
}