import FloorGroupId from "@/domains/floor_group/value_object/FloorGroupId";

export class FloorGroup {
  public id: FloorGroupId;
  public name: string;
  public color: string;

  constructor(id: FloorGroupId, name: string, color: string) {
    this.id = id;
    this.name = name;
    this.color = color;
  }
}