import StudentUserId from "@/domains/student/value_object/StudentUserId";
import moment from "moment";

export class ReservationHistory {

  public startDate: string | null;
  public startTime: string | null;
  public endTime: string | null;
  public floorName: string | null;
  public coachName: string | null;
  public trainingMenu1Name: string | null;
  public trainingMenu2Name: string | null;
  public studentUserId: StudentUserId | null;
  public studentName: string | null;
  public studentNameKana: string | null;
  public studentTel1: string | null;
  public studentTel2: string | null;
  public studentAge: string | null;

  constructor(startDate: string | null,
              startTime: string | null,
              endTime: string | null,
              floorName: string | null,
              coachName: string | null,
              trainingMenu1Name: string | null,
              trainingMenu2Name: string | null,
              studentUserId: StudentUserId | null,
              studentName: string | null,
              studentNameKana: string | null,
              studentTel1: string | null,
              studentTel2: string | null,
              studentAge: string | null) {
    this.startDate = startDate;
    this.startTime = startTime;
    this.endTime = endTime;
    this.floorName = floorName;
    this.coachName = coachName;
    this.trainingMenu1Name = trainingMenu1Name;
    this.trainingMenu2Name = trainingMenu2Name;
    this.studentUserId = studentUserId;
    this.studentName = studentName;
    this.studentNameKana = studentNameKana;
    this.studentTel1 = studentTel1;
    this.studentTel2 = studentTel2;
    this.studentAge = studentAge;
  }

  public displayStartDate(): string {
    return moment(this.startDate).format('Y月M月D日');
  }
}