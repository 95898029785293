import HttpClientBase from '../HttpClientBase';
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import {AxiosResponse} from "axios";
import {Students} from "@/domains/student/Students";
import {Student} from "@/domains/student/Student";
import StudentUserId from "@/domains/student/value_object/StudentUserId";
import StudentId from "@/domains/student/value_object/StudentId";
import LessonReservationId from "@/domains/lesson_reservation/value_object/LessonReservationId";

export default class StudentsClient extends HttpClientBase {

  public async importCsv(csvFile: File): Promise<ResponseInterface> {
    const formData: FormData = this.getAccessTokenForm();
    formData.append("csv_file", csvFile);

    try {
      await this.postRequest('/admin/students/import_csv', formData);
    } catch (error) {
      return {
        result: false,
        status: error.response.status,
        errorCode: error.response.data.error_code,
      };
    }
    return {
      result: true,
      status: 204,
      errorCode: null,
    };
  }

  public async getList(page= null as number | null): Promise<Students> {
    const param = this.getAccessTokenParam();
    if (page != null) {
      param["page"] = page.toString();
    }
    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/admin/students', param);

    const students: Student[] = [];
    const totalPage = response.data.meta.total_page;
    const currentPage = response.data.meta.current_page;

    response.data.students.forEach((studentResponse) => {

      students.push(new Student(
        new StudentId(studentResponse.id),
        new StudentUserId(studentResponse.user_id),
        null,
        studentResponse.name,
        studentResponse.name_kana,
        studentResponse.postal_code,
        studentResponse.address,
        studentResponse.tel1,
        studentResponse.tel2,
        studentResponse.email,
        studentResponse.birthday,
        studentResponse.gender,
        studentResponse.business,
        studentResponse.note1,
        studentResponse.note2,
        studentResponse.delivery_mail,
        studentResponse.motivation_coming_store,
        studentResponse.coming_store_count,
        studentResponse.first_coming_store_date,
        studentResponse.last_coming_store_date,
      ));
    });

    return new Students(students, totalPage, currentPage);
  }

  public async getSearchStudents(studentSearchParam= null as string | null): Promise<Students> {
    const param = this.getAccessTokenParam();
    if (studentSearchParam != null) {
      param["student_search_param"] = studentSearchParam;
    }
    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/admin/students/search_students', param);

    const students: Student[] = [];

    response.data.students.forEach((studentResponse) => {

      students.push(new Student(
        new StudentId(studentResponse.id),
        new StudentUserId(studentResponse.user_id),
        null,
        studentResponse.name,
        studentResponse.name_kana,
        studentResponse.postal_code,
        studentResponse.address,
        studentResponse.tel1,
        studentResponse.tel2,
        studentResponse.email,
        studentResponse.birthday,
        studentResponse.gender,
        studentResponse.business,
        studentResponse.note1,
        studentResponse.note2,
        studentResponse.delivery_mail,
        studentResponse.motivation_coming_store,
        studentResponse.coming_store_count,
        studentResponse.first_coming_store_date,
        studentResponse.last_coming_store_date,
      ));
    });

    return new Students(students);
  }

  public async findStudentUserId(studentUserId: StudentUserId): Promise<Student> {
    const param = this.getAccessTokenParam();
    if (studentUserId != null) {
      param["student_user_id"] = studentUserId.getId().toString();
    }
    const response: AxiosResponse<ShowResponseInterface> = await this.getRequest('/admin/students/find_student_user_id', param);

    const studentResponse = response.data.student;

    return new Student(
      new StudentId(studentResponse.id),
      new StudentUserId(studentResponse.user_id),
      null,
      studentResponse.name,
      studentResponse.name_kana,
      studentResponse.postal_code,
      studentResponse.address,
      studentResponse.tel1,
      studentResponse.tel2,
      studentResponse.email,
      studentResponse.birthday,
      studentResponse.gender,
      studentResponse.business,
      studentResponse.note1,
      studentResponse.note2,
      studentResponse.delivery_mail,
      studentResponse.motivation_coming_store,
      studentResponse.coming_store_count,
      studentResponse.first_coming_store_date,
      studentResponse.last_coming_store_date,
    );
  }

  public async getData(studentId: StudentId): Promise<Student> {
    const param = this.getAccessTokenParam();
    const response: AxiosResponse<ShowResponseInterface> = await this.getRequest('/admin/students/' + studentId.getId(), param);

    const studentResponse = response.data.student;

    return new Student(
      new StudentId(studentResponse.id),
      new StudentUserId(studentResponse.user_id),
      null,
      studentResponse.name,
      studentResponse.name_kana,
      studentResponse.postal_code,
      studentResponse.address,
      studentResponse.tel1,
      studentResponse.tel2,
      studentResponse.email,
      studentResponse.birthday,
      studentResponse.gender,
      studentResponse.business,
      studentResponse.note1,
      studentResponse.note2,
      studentResponse.delivery_mail,
      studentResponse.motivation_coming_store,
      studentResponse.coming_store_count,
      studentResponse.first_coming_store_date,
      studentResponse.last_coming_store_date,
    );
  }

  public async insert(student: Student, lessonReservationId = null as LessonReservationId | null): Promise<ResponseInterface> {
    const formData: FormData = this.getAccessTokenForm();
    student.getFormData().forEach((data, key) => {
      formData.append("student[" + key + "]", data);
    });
    if (lessonReservationId != null) {
      formData.append("lesson_reservation_id", lessonReservationId.getId().toString());
    }

    try {
      await this.postRequest('/admin/students', formData);
    } catch (error) {
      return {
        result: false,
        status: error.response.status,
        errorCode: error.response.data.error_code,
      };
    }
    return {
      result: true,
      status: 204,
      errorCode: null,
    };
  }

  public async update(student: Student): Promise<ResponseInterface> {
    const formData: FormData = this.getAccessTokenForm();
    student.getFormData().forEach((data, key) => {
      formData.append("student[" + key + "]", data);
    });

    try {
      await this.putRequest('/admin/students/' + student.id?.getId(), formData);
    } catch (error) {
      return {
        result: false,
        status: error.response.status,
        errorCode: error.response.data.error_code,
      };
    }
    return {
      result: true,
      status: 204,
      errorCode: null,
    };
  }

  public async delete(student: Student): Promise<ResponseInterface> {
    const formData: FormData = this.getAccessTokenForm();

    try {
      await this.deleteRequest('/admin/students/' + student.id?.getId(), formData);
    } catch (error) {
      return {
        result: false,
        status: error.response.status,
        errorCode: error.response.data.error_code,
      };
    }
    return {
      result: true,
      status: 204,
      errorCode: null,
    };
  }
}

export interface IndexResponseInterface {
  meta: {
    total_page: number,
    current_page: number,
  };
  students: StudentResponseInterface[];
}

export interface ShowResponseInterface {
  student: StudentResponseInterface;
}

export interface StudentResponseInterface {
  id: number;
  user_id: string;
  name: string;
  name_kana: string;
  postal_code: string;
  address: string;
  tel1: string;
  tel2: string;
  email: string;
  birthday: string;
  gender: string;
  business: string;
  note1: string;
  note2: string;
  delivery_mail: boolean;
  motivation_coming_store: string;
  coming_store_count: number;
  first_coming_store_date: string;
  last_coming_store_date: string;
  is_delete: boolean;
}
