import {ResponseInterface} from "@/http_clients/ResponseInterface";
import CoachScheduleId from "@/domains/coach_schedule/value_object/CoachScheduleId";
import CoachId from "@/domains/coach/value_object/CoachId";
import CoachSchedulesClient from "@/http_clients/admin/CoachSchedulesClient";
import moment from 'moment';
import FloorGroupId from "@/domains/floor_group/value_object/FloorGroupId";

export class CoachSchedule {

  public id: CoachScheduleId | null;
  public coachId: CoachId | null;
  public coachName: string | null;
  public title: string | null;
  public startDate: string | null;
  public startTime: string | null;
  public endTime: string | null;
  public endRepeatDate: string | null;
  public holiday: boolean;
  public repeat: boolean;
  public repeatMonday: boolean;
  public repeatTuesday: boolean;
  public repeatWednesday: boolean;
  public repeatThursday: boolean;
  public repeatFriday: boolean;
  public repeatSaturday: boolean;
  public repeatSunday: boolean;
  public note: string | null;
  public floorGroupId: FloorGroupId | null;

  constructor(id: CoachScheduleId | null,
              coachId: CoachId | null,
              coachName: string | null,
              title: string | null,
              startDate: string | null,
              startTime: string | null,
              endTime: string | null,
              endRepeatDate: string | null,
              holiday: boolean | null,
              repeat: boolean | null,
              repeatMonday: boolean | null,
              repeatTuesday: boolean | null,
              repeatWednesday: boolean | null,
              repeatThursday: boolean | null,
              repeatFriday: boolean | null,
              repeatSaturday: boolean | null,
              repeatSunday: boolean | null,
              note: string | null,
              floorGroupId: FloorGroupId | null = null) {
    this.id = id;
    this.coachId = coachId;
    this.coachName = coachName;
    this.title = title;
    this.startDate = startDate;
    this.startTime = startTime;
    this.endTime = endTime;
    this.endRepeatDate = endRepeatDate;
    this.holiday = holiday || false;
    this.repeat = repeat || false;
    this.repeatMonday = repeatMonday || false;
    this.repeatTuesday = repeatTuesday || false;
    this.repeatWednesday = repeatWednesday || false;
    this.repeatThursday = repeatThursday || false;
    this.repeatFriday = repeatFriday || false;
    this.repeatSaturday = repeatSaturday || false;
    this.repeatSunday = repeatSunday || false;
    this.note = note;
    this.floorGroupId = floorGroupId;
  }

  public getTitleName() {
    if (this.title == null) {
      return "";
    }
    if (this.title === 'rest') {
      return "休憩";
    }
    if (this.title === 'meeting') {
      return "ミーティング";
    }
    if (this.title === 'go_out') {
      return "外出";
    }
    if (this.title === 'business_trip') {
      return "出張";
    }
    if (this.title === 'disabled') {
      return "使用不可";
    }
    if (this.title === 'confirmed') {
      return "要確認";
    }
    if (this.title === 'training') {
      return "研修";
    }
    if (this.title === 'photographing') {
      return "撮影";
    }
    if (this.title === 'seminar') {
      return "セミナー";
    }
    if (this.title === 'tentative_schedule') {
      return "仮予定";
    }
    if (this.title === 'overtime') {
      return "勤務時間外";
    }

    return "";
  }

  public withinRangeStartTime(scheduleTime: string, timeInterval: number): boolean {
    const scheduleTimeStartMoment = moment(scheduleTime, 'HH:mm');
    const scheduleTimeEndMoment = moment(scheduleTime, 'HH:mm').add(timeInterval, 'minute');
    const startTimeMoment = moment(this.startTime, 'HH:mm');

    return scheduleTimeEndMoment.isAfter(startTimeMoment) && startTimeMoment.isSameOrAfter(scheduleTimeStartMoment);
  }

  public calendarHeight(baseHeight: number): number {
    const startTimeMoment = moment(this.startTime, 'HH:mm');
    const endTimeMoment = moment(this.endTime, 'HH:mm');
    const diff = endTimeMoment.diff(startTimeMoment, 'minute');
    // 30分刻みで計算 後ろの1とか2は枠線のピクセル
    return diff / 30 * (baseHeight + 1) - 2;
  }
  public calendarTop(scheduleTime: string, baseHeight: number): number {
    const scheduleTimeStartMoment = moment(scheduleTime, 'HH:mm');
    const startTimeMoment = moment(this.startTime, 'HH:mm');
    const diff = startTimeMoment.diff(scheduleTimeStartMoment, 'minute');
    // 30分刻みで計算
    return baseHeight * diff / 30;
  }

  public displayStartDate(): string {
    return moment(this.startDate).format('Y年M月D日(ddd)');
  }

  public noteFirstLine(): string {
    if (this.note == null) {
      return "";
    }
    return this.note.split("\n")[0];
  }

  public validateStartTime(): boolean {
    if (this.holiday) {
      return false;
    }
    const startTimeMoment = moment(this.startTime, 'HH:mm');
    return !moment('9:00', 'HH:mm').isSameOrBefore(startTimeMoment);
  }

  public validateEndTime(): boolean {
    if (this.holiday) {
      return false;
    }
    const endTimeMoment = moment(this.endTime, 'HH:mm');
    return !moment('22:00', 'HH:mm').isSameOrAfter(endTimeMoment);
  }

  public validateStartTimeAndEndTime(): boolean {
    if (this.holiday) {
      return false;
    }
    const startTimeMoment = moment(this.startTime, 'HH:mm');
    const endTimeMoment = moment(this.endTime, 'HH:mm');
    return startTimeMoment.isSameOrAfter(endTimeMoment);
  }

  public async insert(): Promise<ResponseInterface> {
    const coachSchedulesClient: CoachSchedulesClient = new CoachSchedulesClient();
    return await coachSchedulesClient.insert(this);
  }

  public async update(): Promise<ResponseInterface> {
    const coachSchedulesClient: CoachSchedulesClient = new CoachSchedulesClient();
    return await coachSchedulesClient.update(this);
  }

  public async delete(endRepeatDate: string | null): Promise<ResponseInterface> {
    const coachSchedulesClient: CoachSchedulesClient = new CoachSchedulesClient();
    return await coachSchedulesClient.delete(this, endRepeatDate);
  }

  public getFormData(): FormData {
    const formData: FormData = new FormData();

    if (this.coachId != null) {
      formData.append("coach_id", this.coachId.getId().toString());
    }
    if (this.title != null) {
      formData.append("title", this.title);
    }
    if (this.startDate != null) {
      formData.append("start_date", moment(this.startDate).format());
    }
    if (this.startTime != null) {
      formData.append("start_time", moment(this.startTime, 'HH:mm').format("HH:mm:ssZ"));
    }
    if (this.endTime != null) {
      formData.append("end_time", moment(this.endTime, 'HH:mm').format("HH:mm:ssZ"));
    }
    if (this.endRepeatDate != null) {
      formData.append("end_repeat_date", this.endRepeatDate);
    }
    if (this.holiday != null) {
      formData.append("holiday", this.holiday? "true": "false");
    }
    if (this.repeat != null) {
      formData.append("repeat", this.repeat? "true": "false");
    }
    if (this.repeatMonday != null) {
      formData.append("repeat_monday", this.repeatMonday? "true": "false");
    }
    if (this.repeatTuesday != null) {
      formData.append("repeat_tuesday", this.repeatTuesday? "true": "false");
    }
    if (this.repeatWednesday != null) {
      formData.append("repeat_wednesday", this.repeatWednesday? "true": "false");
    }
    if (this.repeatThursday != null) {
      formData.append("repeat_thursday", this.repeatThursday? "true": "false");
    }
    if (this.repeatFriday != null) {
      formData.append("repeat_friday", this.repeatFriday? "true": "false");
    }
    if (this.repeatSaturday != null) {
      formData.append("repeat_saturday", this.repeatSaturday? "true": "false");
    }
    if (this.repeatSunday != null) {
      formData.append("repeat_sunday", this.repeatSunday? "true": "false");
    }
    if (this.note != null) {
      formData.append("note", this.note);
    }
    return formData;
  }
}
