
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'PagerComponent',
  props: {
    totalPage: Number,
    currentPage: Number,
  },
  data() {
    return {
      displayPages: [] as number[],
    }
  },
  watch: {
    totalPage: function () {
      let totalPage = this.totalPage || 1;
      let currentPage = this.currentPage || 1;
      this.setDisplayPage(totalPage, currentPage);
    },
    currentPage: function () {
      let totalPage = this.totalPage || 1;
      let currentPage = this.currentPage || 1;
      this.setDisplayPage(totalPage, currentPage);
    }
  },
  created() {
    let totalPage = this.totalPage || 1;
    let currentPage = this.currentPage || 1;
    this.setDisplayPage(totalPage, currentPage);
  },
  methods: {
    setDisplayPage(totalPage: number, currentPage: number) {
      if (totalPage === 1) {
        this.displayPages = [];
        return;
      }

      let displayFirstPage = currentPage - 2;

      if (displayFirstPage < 2) {
        displayFirstPage = 2;
      }

      let displayLastPage = currentPage + 2;
      if (displayLastPage > totalPage - 1) {
        displayLastPage = totalPage - 1;
      }

      if (displayLastPage - displayFirstPage + 1 <= 0) {
        this.displayPages = [];
        return;
      }
      this.displayPages = [...Array(displayLastPage - displayFirstPage + 1)].map((_, i) => i + displayFirstPage)
    },
    selectPage(page: number) {
      this.$emit('selectPage', page);
    },
  }
});
