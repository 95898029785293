import {FloorGroup} from "@/domains/floor_group/FloorGroup";

export class FloorGroups {
  public list: FloorGroup[];

  constructor(list: FloorGroup[]) {
    this.list = list;
  }

  public isPresent(): boolean {
    return !this.isEmpty();
  }

  public isEmpty(): boolean {
    return this.list.length == 0;
  }
}