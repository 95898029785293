<template>
  <section class="base">
    <ConfirmMessage :confirmMessage="confirmMessage" @resultConfirm="resultConfirm"/>
    <SuccessMessage :successMessage="successMessage" @resultSuccess="resultSuccess"/>
    <div class="menu-background-block" v-show="showMenu" @click="cancelMenu()">
      <Menu />
    </div>
    <div class="main-contents-block">
      <div class="menu-block">
        <font-awesome-icon icon="bars" size="3x" @click="clickMenu()" />
      </div>
      <div class="contents-block">
        <ErrorMessage :errorMessage="errorMessage" />
        <slot></slot>
      </div>
    </div>
    <div v-if="loading != null && loading" class="loading-background">
      <div class="loading-dialog">
        <font-awesome-icon icon="spinner" size="6x" pulse />
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import ErrorMessage from "@/components/common/ErrorMessageComponent.vue";
import Menu from "@/components/admin/common/MenuComponent.vue";
import ConfirmMessage from "@/components/common/ConfirmMessageComponent.vue";
import SuccessMessage from "@/components/common/SuccessMessageComponent.vue";

export default defineComponent({
  name: 'BaseComponent',
  components: {
    ErrorMessage,
    ConfirmMessage,
    SuccessMessage,
    Menu,
  },
  props: {
    errorMessage: String,
    confirmMessage: String,
    successMessage: String,
    loading: Boolean,
  },
  data() {
    return {
      showMenu: false as boolean,
    }
  },
  methods: {
    resultConfirm(result: boolean) {
      this.$emit('resultConfirm', result);
    },
    resultSuccess(result: boolean) {
      this.$emit('resultSuccess', result);
    },
    clickMenu() {
      this.showMenu = true;
    },
    cancelMenu() {
      this.showMenu = false;
    },
    clickNothing(e: PointerEvent) {
      e.stopPropagation();
    },
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.base {
  height: 100%;
}

.main-contents-block {
  display: flex;
  align-items: stretch;
  height: 100%;
}

.menu-background-block {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}

.menu-block {
  margin: 10px;
  svg {
    width: 25px;
    cursor: pointer;
  }
}

.contents-block {
  flex: 1 1 100%;
  padding: 10px 20px;
}

.loading-background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
  top: 0;
}

.loading-dialog {
  background-color: #ffffff;
  margin: 40px;
  padding: 50px;
  border-radius: 10px;
  color: #263165;
}

</style>
