import HttpClientBase from '../HttpClientBase';
import {AxiosResponse} from "axios";
import moment from 'moment';
import StudentUserId from "@/domains/student/value_object/StudentUserId";
import {ReservationHistory} from "@/domains/reservation_history/ReservationHistory";
import {ReservationHistories} from "@/domains/reservation_history/ReservationHistories";

export default class ReservationHistoriesClient extends HttpClientBase {

  public async getList(targetYearMonth: string, page= null as number | null): Promise<ReservationHistories> {
    const param = this.getAccessTokenParam();
    if (page != null) {
      param["page"] = page.toString();
    }
    param["target_date"] = targetYearMonth + '-01';

    const response: AxiosResponse<IndexResponseInterface> = await this.getRequest('/admin/reservation_histories', param);
    const reservationHistories: ReservationHistory[] = [];
    const totalPage = response.data.meta.total_page;
    const currentPage = response.data.meta.current_page;

    response.data.lesson_reservations.forEach((reservationHistoryResponse) => {

      let studentUserId = null;
      if (reservationHistoryResponse.student_user_id != null) {
        studentUserId = new StudentUserId(reservationHistoryResponse.student_user_id);
      }

      reservationHistories.push(new ReservationHistory(
        reservationHistoryResponse.start_date,
        moment(reservationHistoryResponse.start_time).format("HH:mm"),
        moment(reservationHistoryResponse.end_time).format("HH:mm"),
        reservationHistoryResponse.floor_name,
        reservationHistoryResponse.coach_name,
        reservationHistoryResponse.training_menu1_name,
        reservationHistoryResponse.training_menu2_name,
        studentUserId,
        reservationHistoryResponse.student_name,
        reservationHistoryResponse.student_name_kana,
        reservationHistoryResponse.student_tel1,
        reservationHistoryResponse.student_tel2,
        reservationHistoryResponse.student_age,
      ));
    });

    return new ReservationHistories(reservationHistories, totalPage, currentPage);
  }

  public async downloadCsv(targetYearMonth: string): Promise<Blob> {
    const params = this.getAccessTokenParam();
    params["target_date"] = targetYearMonth + '-01';
    const response: AxiosResponse<Blob> = await this.getBlobRequest('/admin/reservation_histories/download_csv', params);
    return response.data;
  }
}

export interface IndexResponseInterface {
  meta: {
    total_page: number,
    current_page: number,
  };
  lesson_reservations: ReservationHistoryResponseInterface[];
}

export interface ReservationHistoryResponseInterface {
  start_date: string;
  start_time: string ;
  end_time: string;
  floor_name: string;
  coach_name: string;
  training_menu1_name: string;
  training_menu2_name: string;
  student_user_id: string;
  student_name: string;
  student_name_kana: string;
  student_tel1: string;
  student_tel2: string;
  student_age: string;
}
