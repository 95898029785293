import CoachSessionsClient, {CoachSignInResponseInterface} from "../http_clients/coaches/SessionsClient";
import StudentSessionsClient, {StudentSignInResponseInterface} from "../http_clients/students/SessionsClient";
import StudentsClient from "@/http_clients/StudentsClient";
import {Student} from "@/domains/student/Student";

export class MyAccount {
  public async signInCoach(userId: string, password: string): Promise<boolean> {
    if (userId == null || password == null) {
      return false;
    }
    const client = new CoachSessionsClient();
    try {
      const response: CoachSignInResponseInterface | null = await client.signIn(userId, password);

      if (response == null) {
        return false;
      }
      sessionStorage.setItem("uid", response.headers["uid"]);
      sessionStorage.setItem("access_token", response.headers["access-token"]);
      sessionStorage.setItem("client", response.headers["client"]);
    }catch (e) {
      return false;
    }

    return true;
  }

  public async signOutCoach(): Promise<boolean> {
    const client = new CoachSessionsClient();
    await client.signOut();

    sessionStorage.removeItem("uid");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("client");
    return true;
  }

  public async signInStudent(userId: string, password: string): Promise<boolean> {
    if (userId == null || password == null) {
      return false;
    }
    const client = new StudentSessionsClient();
    try {
      const response: StudentSignInResponseInterface | null = await client.signIn(userId, password);

      if (response == null) {
        return false;
      }
      sessionStorage.setItem("uid", response.headers["uid"]);
      sessionStorage.setItem("access_token", response.headers["access-token"]);
      sessionStorage.setItem("client", response.headers["client"]);
    }catch (e) {
      return false;
    }

    return true;
  }

  public async signOutStudent(): Promise<boolean> {
    const client = new StudentSessionsClient();
    await client.signOut();

    sessionStorage.removeItem("uid");
    sessionStorage.removeItem("access_token");
    sessionStorage.removeItem("client");
    return true;
  }

  public async getCurrentStudent(): Promise<Student | null> {
    if (sessionStorage.getItem("uid") == null
      || sessionStorage.getItem("access_token") == null
      || sessionStorage.getItem("client") == null) {
      return null;
    }
    const client = new StudentsClient();
    return await client.getCurrentStudent();
  }
}