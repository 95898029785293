<template>
  <Base :loading="loading" @setLoginStudent="setLoginStudent">
    <section class="lesson-reservations-index">
      <FutureOnlyCalendar :targetDate="targetDate" @selectTargetDate="changeTargetDate"/>
      <div class="floor-schedule-block">
        <div class="floor-schedule" :style="{width: (40 + 64 * floors.list.length) + 'px'}">
          <div class="time-block">
            <div class="header">時間</div>
            <div v-for="scheduleTime in scheduleTimes" :key="scheduleTime" class="schedule-body">{{scheduleTime}}</div>
          </div>
          <div v-for="floorSchedule in getFloorSchedules()" :key="floorSchedule.floor.id.getId()" class="floor-block">
            <div class="header" :style="{backgroundColor: floorSchedule.floor.color}">{{floorSchedule.floor.name}}</div>
            <div v-for="schedule in floorSchedule.schedules" :key="schedule.scheduleTime" class="schedule-body">
              <div v-if="displayLessonReservations(schedule.lessonReservations, schedule)" class="exist-reservation">×</div>
              <div v-else-if="schedule.coachNothing" class="coach-nothing">×</div>
              <div class="possible-reservation" v-else @click="clickPossibleReservation(floorSchedule.floor, schedule.scheduleTime)">◎</div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <div class="select-login-background" v-if="showSelectLogin" @click="cancel()">
      <div class="select-login-dialog" @click="clickNothing">
        <div class="dialog-button-block">
          <button type="button" @click="clickLoginReservation()" >ログインして予約</button>
          <button type="button" @click="clickGuestReservation()" >ゲストとして予約</button>
        </div>
      </div>
    </div>
  </Base>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Base from '@/components/common/BaseComponent.vue';
import FutureOnlyCalendar from '@/components/common/FutureOnlyCalendarComponent.vue';
import {LessonReservations} from "@/domains/lesson_reservation/LessonReservations";
import LessonReservationsClient from "@/http_clients/LessonReservationsClient";
import {CoachSchedules} from "@/domains/coach_schedule/CoachSchedules";
import CoachSchedulesClient from "@/http_clients/CoachSchedulesClient";
import {Floors} from "@/domains/floor/Floors";
import FloorsClient from "@/http_clients/FloorsClient";
import moment from 'moment';
import {Coaches} from "@/domains/coach/Coaches";
import CoachesClient from "@/http_clients/CoachesClient";
import {Floor} from "@/domains/floor/Floor";
import LessonReservationId from "@/domains/lesson_reservation/value_object/LessonReservationId";
import {LessonReservation} from "@/domains/lesson_reservation/LessonReservation";
import {Student} from "@/domains/student/Student";

export default defineComponent({
  name: 'Index',
  components: {
    Base,
    FutureOnlyCalendar,
  },
  data() {
    return {
      student: null as Student | null,
      startTime: "11:00" as string,
      endTime: "22:00" as string,
      timeInterval: 30 as number,
      calenderHeight: 40 as number,
      scheduleTimes: [] as string[],
      targetDate: moment().format("YYYY-MM-DD") as string,
      floors: new Floors([]) as Floors,
      coaches: new Coaches([]) as Coaches,
      coachSchedules: new CoachSchedules([]) as CoachSchedules,
      lessonReservations: new LessonReservations([]) as LessonReservations,
      allLessonReservations: new LessonReservations([]) as LessonReservations,
      loading: false as boolean,
      editLessonReservationId: null as LessonReservationId | null,
      showSelectLogin: false as boolean,
      selectFloor: null as Floor | null,
      selectScheduleTime: null as string | null,
    }
  },
  watch: {
    async $route() {
      this.loading = true;
      if (this.$router.currentRoute.value.path != "/lesson_reservations") {
        return;
      }
      if (this.$route.query.target_date) {
        this.targetDate = this.$route.query.target_date as string;
      } else {
        this.targetDate = moment().format("YYYY-MM-DD");
      }
      if (this.$route.query.lesson_reservation_id) {
        this.editLessonReservationId = new LessonReservationId(parseInt(this.$route.query.lesson_reservation_id as string));
      } else {
        this.editLessonReservationId = null;
      }
      await this.setSchedule();
      this.loading = false;
    }
  },
  async created() {
    if (this.$route.query.target_date) {
      this.targetDate = this.$route.query.target_date as string;
    }
    if (this.$route.query.lesson_reservation_id) {
      this.editLessonReservationId = new LessonReservationId(parseInt(this.$route.query.lesson_reservation_id as string));
    } else {
      this.editLessonReservationId = null;
    }
    await this.setSchedule();

    this.scheduleTimes = this.createScheduleTimes();

    const floorsClient = new FloorsClient();
    this.floors = await floorsClient.getStudentFloors();

    const coachesClient = new CoachesClient();
    this.coaches = await coachesClient.getAllCoaches();
  },
  methods: {
    setLoginStudent(student: Student) {
      this.student = student;
    },
    async setSchedule() {
      const lessonReservationsClient = new LessonReservationsClient();
      this.lessonReservations = await lessonReservationsClient.getTargetDateSchedules(this.targetDate);
      this.allLessonReservations = await lessonReservationsClient.getTargetDateAllSchedules(this.targetDate);

      const coachSchedulesClient = new CoachSchedulesClient();
      this.coachSchedules = await coachSchedulesClient.getTargetDateSchedules(this.targetDate);
    },
    changeTargetDate(targetDate: string) {
      this.targetDate = targetDate;
      let query = null;
      if (this.editLessonReservationId != null) {
        query = {lesson_reservation_id: this.editLessonReservationId.getId(), target_date: this.targetDate};
      } else {
        query = {target_date: this.targetDate};
      }
      this.$router.push({query: query });
    },
    createScheduleTimes() {
      let timeMoment = moment(this.startTime, 'HH:mm');
      let endTimeMoment = moment(this.endTime, 'HH:mm');
      let times = [];

      while (timeMoment.isBefore(endTimeMoment)) {
        times.push(timeMoment.format('HH:mm'));
        timeMoment.add(this.timeInterval, 'minute');
      }
      return times;
    },
    getFloorSchedules(): FloorSchedule[] {
      return this.floors.list.map((floor) => {
        const floorLessonReservations = this.lessonReservations.filterFloor(floor.id);
        const floorGroupLessonReservations = this.allLessonReservations.filterFloorGroup(floor.floorGroupId);
        const floorGroupCoaches = this.coaches.filterFloorGroup(floor.floorGroupId);
        const floorGroupCoachSchedules = this.coachSchedules.filterFloorGroup(floor.floorGroupId);

        const schedules: Schedule[] = this.scheduleTimes.map((scheduleTime) => {
          const lessonReservations = floorLessonReservations.filterScheduleTime(scheduleTime, this.timeInterval);
          const floorGroupAndTimeLessonReservations = floorGroupLessonReservations.filterScheduleTime(scheduleTime, this.timeInterval);
          const coachSchedules = floorGroupCoachSchedules.filterScheduleTime(scheduleTime, this.timeInterval);

          return {
            scheduleTime: scheduleTime,
            lessonReservations: lessonReservations,
            coachNothing: !floorGroupCoaches.existFreeSchedule(coachSchedules, floorGroupAndTimeLessonReservations, this.editLessonReservationId),
          };
        });

        return {
          floor: floor,
          schedules: schedules,
        };
      });
    },
    displayLessonReservations(lessonReservations: LessonReservations, schedule: Schedule) {

      let result = false;

      lessonReservations.list.forEach((lessonReservation: LessonReservation) => {
        if (this.editLessonReservationId != null && lessonReservation.id != null && this.editLessonReservationId.getId() === lessonReservation.id.getId()) {
          return;
        }
        if (lessonReservation.startTime == null || lessonReservation.endTime == null) {
          return;
        }

        if (lessonReservation.startTime <= schedule.scheduleTime && schedule.scheduleTime < lessonReservation.endTime) {
          result = true;
        }
      });
      return result;
    },
    clickPossibleReservation(floor: Floor, scheduleTime: string) {

      if (this.student == null) {
        this.selectFloor = floor;
        this.selectScheduleTime = scheduleTime;
        this.showSelectLogin = true;
        return;
      }

      if (this.editLessonReservationId == null) {
        this.$router.push({name: 'LessonReservationNew', query: { floor_id: floor.id.getId(), target_date: this.targetDate, schedule_time: scheduleTime }});
      } else {
        this.$router.push({name: 'LessonReservationEdit', params: {lessonReservationId: this.editLessonReservationId.getId()}, query: { floor_id: floor.id.getId(), target_date: this.targetDate, schedule_time: scheduleTime }});
      }
    },
    clickLoginReservation() {
      if (this.selectFloor != null && this.selectScheduleTime != null) {
        this.$router.push({name: 'SignIn', query: { floor_id: this.selectFloor.id.getId(), target_date: this.targetDate, schedule_time: this.selectScheduleTime }});
      }
    },
    clickGuestReservation() {
      if (this.selectFloor != null && this.selectScheduleTime != null) {
        this.$router.push({name: 'LessonReservationNew', query: { floor_id: this.selectFloor.id.getId(), target_date: this.targetDate, schedule_time: this.selectScheduleTime }});
      }
    },
    cancel() {
      this.showSelectLogin = false;
    },
    clickNothing(e: PointerEvent) {
      e.stopPropagation();
    },
  }
});

export interface FloorSchedule {
  floor: Floor;
  schedules: Schedule[];
}

export interface Schedule {
  scheduleTime: string;
  lessonReservations: LessonReservations;
  coachNothing: boolean;
}

</script>

<style lang="scss" scoped>
.floor-schedule-block {
  overflow-x: auto;
}

.floor-schedule {
  display: flex;
  text-align: center;
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
  margin-top: 20px;
  margin-left: auto;
  margin-right: auto;
  overflow-x: auto;

  .header {
    line-height: 30px;
    border-top: 1px solid #000000;
    border-left: 1px solid #000000;
    font-size: 11px;
  }

  .schedule-body {
    height: 40px;
    border-top: 1px solid #000000;
    border-left: 1px solid #000000;
  }

  .time-block {
    flex: 0 0 40px;
    background-color: #EBEBEB;
    font-size: 12px;
  }

  .floor-block {
    flex: 0 0 64px;

    .header {
      color: #ffffff;
    }
  }
}

.exist-reservation {
  line-height: 40px;
  font-size: 26px;
  background-color: #dddddd;
}

.coach-nothing {
  line-height: 40px;
  font-size: 26px;
  background-color: #dddddd;
}

.possible-reservation {
  color: #FF2F92;
  font-size: 26px;
  cursor: pointer;
}

.select-login-background {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
}

.select-login-dialog {
  background-color: #ffffff;
  flex: 0 0 80%;
  border-radius: 5px;
  padding: 5px;
}

.dialog-button-block {
  display: flex;
  flex-direction: column;
  align-items: center;

  button {
    margin: 10px;
  }
}
</style>
