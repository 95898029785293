import {CoachSchedule} from "@/domains/coach_schedule/CoachSchedule";
import moment from "moment";
import FloorGroupId from "@/domains/floor_group/value_object/FloorGroupId";
import CoachId from "@/domains/coach/value_object/CoachId";

export class CoachSchedules {
  public list: CoachSchedule[];
  public totalPage: number;
  public currentPage: number;

  constructor(list: CoachSchedule[], totalPage = 1, currentPage= 1) {
    this.list = list;
    this.totalPage = totalPage;
    this.currentPage = currentPage;
  }

  public isPresent(): boolean {
    return !this.isEmpty();
  }

  public isEmpty(): boolean {
    return this.list.length == 0;
  }

  public filterFloorGroup(floorGroupId: FloorGroupId): CoachSchedules {
    const coachSchedules = this.list.filter((coachSchedule) => {
      if (coachSchedule.floorGroupId == null) {
        return false;
      }
      return coachSchedule.floorGroupId.getId() == floorGroupId.getId();
    });
    return new CoachSchedules(coachSchedules);
  }

  public filterCoach(coachId: CoachId): CoachSchedules {
    const coachSchedules = this.list.filter((coachSchedule) => {
      if (coachSchedule.coachId == null) {
        return false;
      }
      return coachSchedule.coachId.getId() == coachId.getId();
    });
    return new CoachSchedules(coachSchedules);
  }

  public existHoliday(): boolean {
    const holidayCoachSchedules = this.list.filter((coachSchedule) => {
      return coachSchedule.holiday;
    });
    return holidayCoachSchedules.length != 0;
  }

  public filterScheduleTime(scheduleTime: string, timeInterval: number): CoachSchedules {
    const coachSchedules = this.list.filter((coachSchedule) => {

      const scheduleTimeStartMoment = moment(scheduleTime, 'HH:mm');
      const scheduleTimeEndMoment = moment(scheduleTime, 'HH:mm').add(timeInterval, 'minute');
      const startTimeMoment = moment(coachSchedule.startTime, 'HH:mm');
      const endTimeMoment = moment(coachSchedule.endTime, 'HH:mm');

      return scheduleTimeEndMoment.isAfter(startTimeMoment) && endTimeMoment.isAfter(scheduleTimeStartMoment);
    });
    return new CoachSchedules(coachSchedules);
  }
}