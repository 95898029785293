
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SuccessMessageComponent',
  props: {
    successMessage: String,
  },
  methods: {
    ok() {
      this.$emit('resultSuccess', true);
    },
    clickNothing(e: PointerEvent) {
      e.stopPropagation();
    }
  }
});
