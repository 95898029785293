
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import CoachSchedulesInput from '@/components/admin/coach_schedules/InputComponent.vue';
import {CoachSchedule} from "@/domains/coach_schedule/CoachSchedule";
import CoachSchedulesClient from "@/http_clients/admin/CoachSchedulesClient";
import CoachScheduleId from "@/domains/coach_schedule/value_object/CoachScheduleId";

export default defineComponent({
  name: 'CoachScheduleEdit',
  components: {
    Base,
    CoachSchedulesInput,
  },
  data() {
    return {
      errorMessage: null as string | null,
      successMessage: null as string | null,
      deleteConfirm: false as boolean,
      endRepeatDate: null as string | null,
      allDelete: true as boolean,
      repeat: false as boolean,
      coachSchedule: new CoachSchedule(null, null, null,null,null,null,null,null,null,
          null,null,null,null,null,null,null,null,
          null) as CoachSchedule,
      targetDate: null as string | null,
      basePage: null as string | null,
    }
  },
  async created() {
    const coachSchedulesClient = new CoachSchedulesClient();
    this.coachSchedule = await coachSchedulesClient.getData(new CoachScheduleId(parseInt(this.$route.params.coachScheduleId as string)));
    this.repeat = this.coachSchedule.repeat;

    if (this.$route.query.target_date) {
      this.targetDate = this.$route.query.target_date as string;
    }
    if (this.$route.query.base_page) {
      this.basePage = this.$route.query.base_page as string;
    }

  },
  methods: {
    changeCoachSchedule(coachSchedule: CoachSchedule) {
      this.coachSchedule = coachSchedule;
    },
    async saveCoachSchedule() {
      if (this.coachSchedule.validateStartTime()) {
        this.errorMessage = "開始時間は9:00以降を指定してください。";
        return;
      }
      if (this.coachSchedule.validateEndTime()) {
        this.errorMessage = "終了時間は22:00以前を指定してください。";
        return;
      }
      if (this.coachSchedule.validateStartTimeAndEndTime()) {
        this.errorMessage = "終了時間は開始時間より後の時間を指定してください。";
        return;
      }

      const response: ResponseInterface = await this.coachSchedule.update();
      if (response.result) {
        this.successMessage = "更新を完了しました。";
      } else {
        if (response.status == 400) {
          if (response.errorCode == 'exist_coach_schedule') {
            this.errorMessage = "既にスタッフに予定が登録されています。";
          } else if (response.errorCode == 'exist_lesson_reservation') {
            this.errorMessage = "既にスタッフにレッスンが登録されています。";
          }
        } else {
          this.errorMessage = "予定の更新に失敗しました。";
        }
      }
    },
    confirmDeleteCoachSchedule() {
      this.deleteConfirm = true;
    },
    async deleteCoachSchedule() {
      let endRepeatDate = null;
      if (!this.allDelete) {
        endRepeatDate = this.endRepeatDate;
      }
      const response: ResponseInterface = await this.coachSchedule.delete(endRepeatDate);
      this.deleteConfirm = false;
      if (response.result) {
        this.successMessage = "削除を完了しました。";
      } else {
        this.errorMessage = "予定の削除に失敗しました。";
      }
    },
    resultSuccess() {
      if (this.basePage != null && this.basePage == 'index') {
        // トップページより遷移してきた場合
        this.$router.push({name: 'AdminIndex', query: { target_date: this.targetDate }});
      } else {
        this.$router.push('/admin/coach_schedules');
      }
    },
    cancel() {
      this.deleteConfirm = false;
    },
    clickNothing(e: PointerEvent) {
      e.stopPropagation();
    }
  }
});
