<template>
  <Base :errorMessage="errorMessage" :successMessage="successMessage" @resultSuccess="resultSuccess" >
    <section class="admin-training-menus-new">
      <div class="header">
        <h1>トレーニングメニュー登録</h1>
      </div>
      <form @submit.prevent="saveTrainingMenu">
        <TrainingMenusInput :trainingMenu="trainingMenu" @changeTrainingMenu="changeTrainingMenu"/>
        <div class="button-block">
          <button type="submit" class="approval-button" >登録</button>
        </div>
      </form>
    </section>
  </Base>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import TrainingMenusInput from '@/components/admin/training_menus/InputComponent.vue';
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import {TrainingMenu} from "@/domains/training_menu/TrainingMenu";

export default defineComponent({
  name: 'TrainingMenuNew',
  components: {
    Base,
    TrainingMenusInput,
  },
  data() {
    return {
      errorMessage: null as string | null,
      successMessage: null as string | null,
      trainingMenu: new TrainingMenu(null, null,null,null,null,null,null) as TrainingMenu,
    }
  },
  methods: {
    changeTrainingMenu(trainingMenu: TrainingMenu) {
      this.trainingMenu = trainingMenu;
    },
    async saveTrainingMenu() {
      const response: ResponseInterface = await this.trainingMenu.insert();
      if (response.result) {
        this.successMessage = "登録を完了しました。";
      } else {
        this.errorMessage = "トレーニングメニューの登録処理に失敗しました。";
      }
    },
    resultSuccess() {
      this.$router.push('/admin/training_menus');
    },
  }
});
</script>

<style lang="scss" scoped>
.admin-training-menus-new {
  max-width: 600px;
}

.button-block {
  margin-top: 60px;

  display: flex;
  justify-content: center;
}
</style>
