
import { defineComponent } from 'vue';
import {LessonReservation} from "@/domains/lesson_reservation/LessonReservation";
import FloorsClient from "@/http_clients/admin/FloorsClient";
import StudentId from "@/domains/student/value_object/StudentId";
import FloorId from "@/domains/floor/value_object/FloorId";
import CoachId from "@/domains/coach/value_object/CoachId";
import TrainingMenuId from "@/domains/training_menu/value_object/TrainingMenuId";
import StudentUserId from "@/domains/student/value_object/StudentUserId";
import CoachesClient from "@/http_clients/admin/CoachesClient";
import {Coaches} from "@/domains/coach/Coaches";
import TrainingMenusClient from "@/http_clients/admin/TrainingMenusClient";
import {TrainingMenus} from "@/domains/training_menu/TrainingMenus";
import moment from "moment";
import StudentsClient from "@/http_clients/admin/StudentsClient";
import CoachSchedulesClient from "@/http_clients/admin/CoachSchedulesClient";
import {Students} from "@/domains/student/Students";
import {Student} from "@/domains/student/Student";
import {CoachSchedules} from "@/domains/coach_schedule/CoachSchedules";
import {LessonReservations} from "@/domains/lesson_reservation/LessonReservations";
import LessonReservationsClient from "@/http_clients/admin/LessonReservationsClient";
import {Floor} from "@/domains/floor/Floor";

export default defineComponent({
  name: 'AdminLessonReservationsInputComponent',
  props: {
    lessonReservation: LessonReservation,
  },
  data() {
    return {
      member: true as boolean,
      studentUserId: null as string | null,
      studentId: null as number | null,
      studentName: null as string | null,
      name: null as string | null,
      tel: null as string | null,
      age: null as string | null,
      floorId: null as number | null,
      startDate: null as string | null,
      startTime: null as string | null,
      endTime: null as string | null,
      coachId: null as number | null,
      trainingMenuId1: null as number | null,
      trainingMenuId2: null as number | null,
      note: null as string | null,
      targetFloor: null as Floor | null,
      coaches: new Coaches([]) as Coaches,
      trainingMenus: new TrainingMenus([]) as TrainingMenus,
      timeInterval: null as number | null,
      showStudentSearchDialog: false as boolean,
      studentSearchParam: null as string | null,
      students: new Students([]) as Students,
      notHolidayCoachSchedules: new CoachSchedules([]) as CoachSchedules,
      targetCoaches: new Coaches([]) as Coaches,
      targetDateLessonReservations: new LessonReservations([]) as LessonReservations,
    }
  },
  watch: {
    lessonReservation: function () {
      this.setInputData();
    },
    studentId: function () {
      this.setLessonReservation();
    },
    name: function () {
      this.setLessonReservation();
    },
    tel: function () {
      this.setLessonReservation();
    },
    age: function () {
      this.setLessonReservation();
    },
    coachId: function () {
      this.setLessonReservation();
    },
    endTime: function () {
      this.setLessonReservation();
    },
    trainingMenuId1: function () {
      this.setLessonReservation();
      this.targetCoaches = this.getTargetCoaches();
    },
    note: function () {
      this.setLessonReservation();
    },
  },
  async created() {
    if (this.lessonReservation != null && this.lessonReservation.floorId != null) {
      this.targetFloor = await this.getTargetFloor();

      const coachesClient = new CoachesClient();
      this.coaches = await coachesClient.getFloorCoaches(this.lessonReservation.floorId);

      const trainingMenusClient = new TrainingMenusClient();
      this.trainingMenus = await trainingMenusClient.getFloorTrainingMenus(this.lessonReservation.floorId);
      if (this.lessonReservation.startTime != null) {
        this.trainingMenus = this.trainingMenus.filterEndTimeOver(this.lessonReservation.startTime);
      }

      const coachSchedulesClient = new CoachSchedulesClient();
      const holidayCoachSchedule = await coachSchedulesClient.getTargetDateHolidaySchedules(this.lessonReservation.startDate);
      this.coaches = this.coaches.filterNotHoliday(holidayCoachSchedule);

      this.notHolidayCoachSchedules = await coachSchedulesClient.getTargetDateNotHolidaySchedules(this.lessonReservation.startDate);

      this.setInputData();
      this.targetDateLessonReservations = await this.getTargetDateLessonReservations(this.lessonReservation.startDate);
      this.targetCoaches = this.getTargetCoaches();

      if (this.member) {
        await this.changeStudentUserId();
      }
    }
  },
  methods: {
    getFloorName() {
      if (this.targetFloor == null || this.targetFloor.name == null) {
        return null;
      }
      return this.targetFloor.name;
    },
    async changeStudentUserId() {
      if (this.studentUserId == null || this.studentUserId == '') {
        this.studentId = null;
        this.studentName = null;
        return;
      }

      const studentsClient = new StudentsClient();
      try {
        const student = await studentsClient.findStudentUserId(new StudentUserId(this.studentUserId));
        if (student.id != null) {
          this.studentId = student.id.getId();
        } else {
          this.studentId = null;
        }
        this.studentName = student.name;

      } catch (e) {
        this.studentId = null;
        this.studentName = "会員が存在しません。";
      }
    },
    setInputData() {
      if (this.lessonReservation != null) {

        if (this.lessonReservation.member != null) {
          this.member = this.lessonReservation.member;
        }

        if (this.member) {
          if (this.lessonReservation.studentUserId != null) {
            this.studentUserId = this.lessonReservation.studentUserId.getId();
          }
          if (this.lessonReservation.studentId != null) {
            this.studentId = this.lessonReservation.studentId.getId();
          }
        } else {
          this.name = this.lessonReservation.studentName;
          this.tel = this.lessonReservation.studentTel;
          this.age = this.lessonReservation.studentAge;
        }

        if (this.lessonReservation.floorId != null) {
          this.floorId = this.lessonReservation.floorId.getId();
        }

        this.startDate = this.lessonReservation.startDate;
        this.startTime = this.lessonReservation.startTime;
        this.endTime = this.lessonReservation.endTime;

        if (this.lessonReservation.coachId != null) {
          this.coachId = this.lessonReservation.coachId.getId();
        }
        if (this.lessonReservation.trainingMenuId1 != null) {
          this.trainingMenuId1 = this.lessonReservation.trainingMenuId1.getId();
        }
        if (this.lessonReservation.trainingMenuId2 != null) {
          this.trainingMenuId2 = this.lessonReservation.trainingMenuId2.getId();
        }
        this.note = this.lessonReservation.note;

        this.timeInterval = this.calculateTimeInterval();
        if (this.timeInterval != null) {
          this.endTime = moment(this.startTime, 'HH:mm').add(this.timeInterval, 'minute').format('HH:mm');
        }
      }
    },
    setLessonReservation() {
      let id = null;
      if (this.lessonReservation != null) {
        id = this.lessonReservation.id;
      }
      let studentUserId = null;
      if (this.studentUserId != null) {
        studentUserId = new StudentUserId(this.studentUserId);
      }
      let studentId = null;
      if (this.studentId != null) {
        studentId = new StudentId(this.studentId);
      }
      let floorId = null;
      if (this.floorId != null) {
        floorId = new FloorId(this.floorId);
      }
      let coachId = null;
      if (this.coachId != null) {
        coachId = new CoachId(this.coachId);
      }
      let trainingMenuId1 = null;
      if (this.trainingMenuId1 != null) {
        trainingMenuId1 = new TrainingMenuId(this.trainingMenuId1);
      }
      let trainingMenuId2 = null;
      if (this.trainingMenuId2 != null) {
        trainingMenuId2 = new TrainingMenuId(this.trainingMenuId2);
      }

      this.timeInterval = this.calculateTimeInterval();
      if (this.timeInterval != null) {
        this.endTime = moment(this.startTime, 'HH:mm').add(this.timeInterval, 'minute').format('HH:mm');
      }

      const lessonReservation = new LessonReservation(
          id,
          this.member,
          studentUserId,
          studentId,
          this.name,
          null,
          this.tel,
          this.age,
          floorId,
          null,
          null,
          this.startDate,
          this.startTime,
          this.endTime,
          coachId,
          null,
          trainingMenuId1,
          null,
          null,
          trainingMenuId2,
          null,
          null,
          this.note);
      this.$emit('changeLessonReservation', lessonReservation);
    },
    calculateTimeInterval() {
      if (this.trainingMenus == null) {
        return null;
      }

      let timeInterval = null;
      if (this.trainingMenuId1 != null) {
        const trainingMenu = this.trainingMenus.find(new TrainingMenuId(this.trainingMenuId1));
        if (trainingMenu != null) {
          timeInterval = trainingMenu.trainingMinutes;
        }
      }
      if (this.trainingMenuId2 != null) {
        const trainingMenu = this.trainingMenus.find(new TrainingMenuId(this.trainingMenuId2));
        if (trainingMenu != null) {
          if (timeInterval == null) {
            timeInterval = trainingMenu.trainingMinutes;
          } else if (trainingMenu.trainingMinutes != null && timeInterval < trainingMenu.trainingMinutes) {
            timeInterval = trainingMenu.trainingMinutes;
          }
        }
      }
      return timeInterval;
    },
    showStudentSearchButton() {
      this.studentSearchParam = null;
      this.students = new Students([]);
      this.showStudentSearchDialog = true;
    },
    selectStudent(student: Student) {
      if (student.userId != null) {
        this.studentUserId = student.userId.getId();
      } else {
        this.studentUserId = null;
      }
      if (student.id != null) {
        this.studentId = student.id.getId();
      } else {
        this.studentId = null;
      }
      this.studentName = student.name;
      this.showStudentSearchDialog = false;
    },
    cancel() {
      this.showStudentSearchDialog = false;
    },
    clickNothing(e: PointerEvent) {
      e.stopPropagation();
    },
    async searchStudents() {
      const studentsClient = new StudentsClient();
      this.students = await studentsClient.getSearchStudents(this.studentSearchParam);
    },
    getTargetCoaches() {
      let targetEndTime = this.endTime;
      if (targetEndTime == null) {
        targetEndTime = moment(this.startTime, 'HH:mm').add(30, 'minute').format('HH:mm');
      }
      let targetCoaches = this.coaches.list.filter((coach) => {
        const targetCoachSchedules = this.notHolidayCoachSchedules.list.filter((coachSchedule) => {
          if (coach.id == null || coachSchedule.coachId == null) {
            return false;
          }
          return (moment(targetEndTime, 'HH:mm').isAfter(moment(coachSchedule.startTime, 'HH:mm')) &&
              moment(this.startTime, 'HH:mm').isBefore(moment(coachSchedule.endTime, 'HH:mm')) &&
              coach.id.getId() == coachSchedule.coachId.getId());
        });
        return targetCoachSchedules.length == 0;
      });

      targetCoaches = targetCoaches.filter((coach) => {
        const targetLessonReservations = this.targetDateLessonReservations.list.filter((lessonReservation) => {
          if (coach.id == null || lessonReservation.coachId == null) {
            return false;
          }
          if (this.lessonReservation != null && this.lessonReservation.id != null && lessonReservation.id != null &&
              this.lessonReservation.id.getId() == lessonReservation.id.getId()) {
            return false;
          }
          return (moment(targetEndTime, 'HH:mm').isAfter(moment(lessonReservation.startTime, 'HH:mm')) &&
              moment(this.startTime, 'HH:mm').isBefore(moment(lessonReservation.endTime, 'HH:mm')) &&
              coach.id.getId() == lessonReservation.coachId.getId());
        });
        return targetLessonReservations.length == 0;
      });
      return new Coaches(targetCoaches);
    },
    async getTargetDateLessonReservations(targetDate= null as string | null) {
      if (this.targetFloor == null) {
        return new LessonReservations([]);
      }
      const lessonReservationsClient = new LessonReservationsClient();
      return await lessonReservationsClient.getTargetDateFloorGroupSchedules(targetDate, this.targetFloor.floorGroupId);
    },
    async getTargetFloor() {
      const floorsClient = new FloorsClient();
      const floors = await floorsClient.getFloors();
      if (floors == null) {
        return null;
      }
      if (this.lessonReservation == null || this.lessonReservation.floorId == null) {
        return null;
      }

      return floors.find(this.lessonReservation.floorId);
    }
  }
});
