<template>
  <section class="admin-coach-schedules-input">
    <div class="input-block">
      <span>タイトル:</span>
      <select v-model="title" required>
        <option></option>
        <option value="rest">休憩</option>
        <option value="meeting">ミーティング</option>
        <option value="go_out">外出</option>
        <option value="business_trip">出張</option>
        <option value="disabled">使用不可</option>
        <option value="confirmed">要確認</option>
        <option value="training">研修</option>
        <option value="photographing">撮影</option>
        <option value="seminar">セミナー</option>
        <option value="tentative_schedule">仮予定</option>
        <option value="overtime">勤務時間外</option>
      </select>
    </div>
    <div class="input-block">
      <span>スタッフ:</span>
      <select v-model="coachId" required>
        <option v-for="coach in coaches.list" :key="coach.id.getId()" :value="coach.id.getId()">{{coach.name}}</option>
      </select>
    </div>
    <div class="input-block">
      <span>日付:</span>
      <input type="date" v-model="startDate" required/>
    </div>
    <div class="input-block">
      <span>休日:</span>
      <label>
        <input type="checkbox" v-model="holiday"/>
      </label>
    </div>
    <div class="input-block">
      <span>開始時間:</span>
      <input type="time" v-model="startTime" required :disabled="holiday" min="09:00:00" max="22:00:00"/>
    </div>
    <div class="input-block">
      <span>終了時間:</span>
      <input type="time" v-model="endTime" required :disabled="holiday" min="09:00:00" max="22:00:00"/>
    </div>
    <div class="input-block">
      <span>繰り返し:</span>
      <div>
        <div>
          <label>
            <input type="radio" :value="false" v-model="repeat"/>1回のみ
          </label>
          <label>
            <input type="radio" :value="true" v-model="repeat"/>定期
          </label>
        </div>

        <div class="repeat-day-of-week">
          <label>
            <input type="checkbox" v-model="repeatMonday" :disabled="!repeat"/>月曜
          </label>
          <label>
            <input type="checkbox" v-model="repeatTuesday" :disabled="!repeat"/>火曜
          </label>
          <label>
            <input type="checkbox" v-model="repeatWednesday" :disabled="!repeat"/>水曜
          </label>
          <label>
            <input type="checkbox" v-model="repeatThursday" :disabled="!repeat"/>木曜
          </label>
          <label>
            <input type="checkbox" v-model="repeatFriday" :disabled="!repeat"/>金曜
          </label>
          <label>
            <input type="checkbox" v-model="repeatSaturday" :disabled="!repeat"/>土曜
          </label>
          <label>
            <input type="checkbox" v-model="repeatSunday" :disabled="!repeat"/>日曜
          </label>
        </div>
      </div>
    </div>
    <div class="input-block input-textarea-block">
      <span>備考:</span>
      <textarea v-model="note"/>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import {CoachSchedule} from "@/domains/coach_schedule/CoachSchedule";
import {Coaches} from "@/domains/coach/Coaches";
import CoachesClient from "@/http_clients/admin/CoachesClient";
import CoachId from "@/domains/coach/value_object/CoachId";

export default defineComponent({
  name: 'AdminCoachSchedulesInputComponent',
  props: {
    coachSchedule: CoachSchedule,
  },
  data() {
    return {
      title: null as string | null,
      coachId: null as number | null,
      startDate: null as string | null,
      startTime: null as string | null,
      endTime: null as string | null,
      holiday: null as boolean | null,
      repeat: false as boolean,
      repeatMonday: false as boolean,
      repeatTuesday: false as boolean,
      repeatWednesday: false as boolean,
      repeatThursday: false as boolean,
      repeatFriday: false as boolean,
      repeatSaturday: false as boolean,
      repeatSunday: false as boolean,
      note: null as string | null,
      coaches: new Coaches([]) as Coaches,
    }
  },
  watch: {
    coachSchedule: function () {
      if (this.coachSchedule!= null) {
        this.title = this.coachSchedule.title;
        if (this.coachSchedule.coachId != null) {
          this.coachId = this.coachSchedule.coachId.getId();
        }
        this.startDate = this.coachSchedule.startDate;
        this.startTime = this.coachSchedule.startTime;
        this.endTime = this.coachSchedule.endTime;
        this.holiday = this.coachSchedule.holiday;
        this.repeat = this.coachSchedule.repeat;
        this.repeatMonday = this.coachSchedule.repeatMonday;
        this.repeatTuesday = this.coachSchedule.repeatTuesday;
        this.repeatWednesday = this.coachSchedule.repeatWednesday;
        this.repeatThursday = this.coachSchedule.repeatThursday;
        this.repeatFriday = this.coachSchedule.repeatFriday;
        this.repeatSaturday = this.coachSchedule.repeatSaturday;
        this.repeatSunday = this.coachSchedule.repeatSunday;
        this.note = this.coachSchedule.note;
      }
    },
    title: function () {
      this.setCoachSchedule();
    },
    coachId: function () {
      this.setCoachSchedule();
    },
    startDate: function () {
      this.setCoachSchedule();
    },
    startTime: function () {
      this.setCoachSchedule();
    },
    endTime: function () {
      this.setCoachSchedule();
    },
    holiday: function () {
      this.setCoachSchedule();
    },
    repeat: function () {
      this.setCoachSchedule();
    },
    repeatMonday: function () {
      this.setCoachSchedule();
    },
    repeatTuesday: function () {
      this.setCoachSchedule();
    },
    repeatWednesday: function () {
      this.setCoachSchedule();
    },
    repeatThursday: function () {
      this.setCoachSchedule();
    },
    repeatFriday: function () {
      this.setCoachSchedule();
    },
    repeatSaturday: function () {
      this.setCoachSchedule();
    },
    repeatSunday: function () {
      this.setCoachSchedule();
    },
    note: function () {
      this.setCoachSchedule();
    },
  },
  async created() {
    const coachesClient = new CoachesClient();
    this.coaches = await coachesClient.getAllCoaches();
  },
  methods: {
    setCoachSchedule() {
      let id = null;
      if (this.coachSchedule != null) {
        id = this.coachSchedule.id;
      }
      let coachId = null;
      if (this.coachId != null) {
        coachId = new CoachId(this.coachId);
      }
      let endRepeatDate = null;
      if (this.coachSchedule != null) {
        endRepeatDate = this.coachSchedule.endRepeatDate;
      }

      const coachSchedule = new CoachSchedule(
          id,
          coachId,
          null,
          this.title,
          this.startDate,
          this.startTime,
          this.endTime,
          endRepeatDate,
          this.holiday,
          this.repeat,
          this.repeatMonday,
          this.repeatTuesday,
          this.repeatWednesday,
          this.repeatThursday,
          this.repeatFriday,
          this.repeatSaturday,
          this.repeatSunday,
          this.note,
      );
      this.$emit('changeCoachSchedule', coachSchedule);
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.input-block {
  margin-bottom: 20px;
  display: flex;
  align-items: center;

  span {
    display: inline-block;
    flex: 0 0 130px;
  }

  .repeat-day-of-week {
    margin-top: 10px;

    label {
      margin-right: 10px;
    }
  }
}

.input-textarea-block {
  align-items: flex-start;
}
</style>
