<template>
  <Base :loading="loading" >
    <section class="lesson-reservations-index">
      <div class="header">
        <h1>予約状況</h1>
      </div>

      <FutureOnlyCalendar :targetDate="targetDate" @selectTargetDate="changeTargetDate"/>

      <div class="floor-schedule">
        <div class="time-block">
          <div class="header">時間</div>
          <div v-for="scheduleTime in scheduleTimes" :key="scheduleTime" class="schedule-body">{{scheduleTime}}</div>
        </div>
        <div v-for="floorSchedule in getFloorSchedules()" :key="floorSchedule.floor.id.getId()" class="floor-block">
          <div class="header" :style="{backgroundColor: floorSchedule.floor.color}">{{floorSchedule.floor.name}}</div>
          <div v-for="schedule in floorSchedule.schedules" :key="schedule.scheduleTime" class="schedule-body">
            <div v-for="lessonReservation in schedule.lessonReservations.list" :key="lessonReservation.id">
              <div v-if="displayLessonReservation(lessonReservation, schedule)"
                   class="exist-reservation" :style="{height: lessonReservation.calendarHeight(calenderHeight) + 'px', backgroundColor: lessonReservation.trainingMenu1Color}">

                {{lessonReservation.startTime}}～{{lessonReservation.endTime}}<br />
                会員：{{lessonReservation.studentName}}<br />
                スタッフ：{{lessonReservation.coachName}}
              </div>
            </div>
            <div v-if="schedule.coachNothing" class="coach-nothing">担当スタッフ無し</div>
            <div class="possible-reservation" v-else @click="clickPossibleReservation(floorSchedule.floor, schedule.scheduleTime)">◎</div>
          </div>
        </div>
      </div>
    </section>
  </Base>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import FutureOnlyCalendar from '@/components/common/FutureOnlyCalendarComponent.vue';
import {LessonReservations} from "@/domains/lesson_reservation/LessonReservations";
import LessonReservationsClient from "@/http_clients/admin/LessonReservationsClient";
import {CoachSchedules} from "@/domains/coach_schedule/CoachSchedules";
import CoachSchedulesClient from "@/http_clients/admin/CoachSchedulesClient";
import {Floors} from "@/domains/floor/Floors";
import FloorsClient from "@/http_clients/admin/FloorsClient";
import moment from 'moment';
import {Coaches} from "@/domains/coach/Coaches";
import CoachesClient from "@/http_clients/admin/CoachesClient";
import {Floor} from "@/domains/floor/Floor";
import LessonReservationId from "@/domains/lesson_reservation/value_object/LessonReservationId";
import {LessonReservation} from "@/domains/lesson_reservation/LessonReservation";

export default defineComponent({
  name: 'LessonReservationIndex',
  components: {
    Base,
    FutureOnlyCalendar,
  },
  data() {
    return {
      startTime: "9:00" as string,
      endTime: "22:00" as string,
      timeInterval: 30 as number,
      calenderHeight: 40 as number,
      scheduleTimes: [] as string[],
      targetDate: moment().format("YYYY-MM-DD") as string,
      floors: new Floors([]) as Floors,
      coaches: new Coaches([]) as Coaches,
      coachSchedules: new CoachSchedules([]) as CoachSchedules,
      lessonReservations: new LessonReservations([]) as LessonReservations,
      loading: false as boolean,
      editLessonReservationId: null as LessonReservationId | null,
    }
  },
  watch: {
    async $route() {
      this.loading = true;
      if (this.$router.currentRoute.value.path != "/admin/lesson_reservations") {
        return;
      }
      if (this.$route.query.target_date) {
        this.targetDate = this.$route.query.target_date as string;
      } else {
        this.targetDate = moment().format("YYYY-MM-DD");
      }
      if (this.$route.query.lesson_reservation_id) {
        this.editLessonReservationId = new LessonReservationId(parseInt(this.$route.query.lesson_reservation_id as string));
      } else {
        this.editLessonReservationId = null;
      }
      await this.setSchedule();
      this.loading = false;
    }
  },
  async created() {
    if (this.$route.query.target_date) {
      this.targetDate = this.$route.query.target_date as string;
    }
    if (this.$route.query.lesson_reservation_id) {
      this.editLessonReservationId = new LessonReservationId(parseInt(this.$route.query.lesson_reservation_id as string));
    } else {
      this.editLessonReservationId = null;
    }
    await this.setSchedule();

    this.scheduleTimes = this.createScheduleTimes();

    const floorsClient = new FloorsClient();
    this.floors = await floorsClient.getFloors();

    const coachesClient = new CoachesClient();
    this.coaches = await coachesClient.getAllCoaches();
  },
  methods: {
    async setSchedule() {
      const lessonReservationsClient = new LessonReservationsClient();
      this.lessonReservations = await lessonReservationsClient.getTargetDateSchedules(this.targetDate);

      const coachSchedulesClient = new CoachSchedulesClient();
      this.coachSchedules = await coachSchedulesClient.getTargetDateSchedules(this.targetDate);
    },
    changeTargetDate(targetDate: string) {
      this.targetDate = targetDate;
      let query = null;
      if (this.editLessonReservationId != null) {
        query = {lesson_reservation_id: this.editLessonReservationId.getId(), target_date: this.targetDate};
      } else {
        query = {target_date: this.targetDate};
      }
      this.$router.push({query: query });
    },
    createScheduleTimes() {
      let timeMoment = moment(this.startTime, 'HH:mm');
      let endTimeMoment = moment(this.endTime, 'HH:mm');
      let times = [];

      while (timeMoment.isBefore(endTimeMoment)) {
        times.push(timeMoment.format('HH:mm'));
        timeMoment.add(this.timeInterval, 'minute');
      }
      return times;
    },
    getFloorSchedules(): FloorSchedule[] {
      return this.floors.list.map((floor) => {
        const floorLessonReservations = this.lessonReservations.filterFloor(floor.id);
        const floorGroupLessonReservations = this.lessonReservations.filterFloorGroup(floor.floorGroupId);
        const floorGroupCoaches = this.coaches.filterFloorGroup(floor.floorGroupId);
        const floorGroupCoachSchedules = this.coachSchedules.filterFloorGroup(floor.floorGroupId);

        const schedules: Schedule[] = this.scheduleTimes.map((scheduleTime) => {
          const lessonReservations = floorLessonReservations.filterScheduleTime(scheduleTime, this.timeInterval);
          const floorGroupAndTimeLessonReservations = floorGroupLessonReservations.filterScheduleTime(scheduleTime, this.timeInterval);
          const coachSchedules = floorGroupCoachSchedules.filterScheduleTime(scheduleTime, this.timeInterval);

          return {
            scheduleTime: scheduleTime,
            lessonReservations: lessonReservations,
            coachNothing: !floorGroupCoaches.existFreeSchedule(coachSchedules, floorGroupAndTimeLessonReservations, this.editLessonReservationId),
          };
        });

        return {
          floor: floor,
          schedules: schedules,
        };
      });
    },
    displayLessonReservation(lessonReservation: LessonReservation, schedule: Schedule) {

      if (this.editLessonReservationId != null && lessonReservation.id != null && this.editLessonReservationId.getId() === lessonReservation.id.getId()) {
        return false;
      }
      return lessonReservation.startTime === schedule.scheduleTime;
    },
    clickPossibleReservation(floor: Floor, scheduleTime: string) {
      if (this.editLessonReservationId == null) {
        this.$router.push({name: 'AdminLessonReservationNew', query: { floor_id: floor.id.getId(), target_date: this.targetDate, schedule_time: scheduleTime }});
      } else {
        this.$router.push({name: 'AdminLessonReservationEdit', params: {lessonReservationId: this.editLessonReservationId.getId()}, query: { floor_id: floor.id.getId(), target_date: this.targetDate, schedule_time: scheduleTime }});
      }
    }
  }
});

export interface FloorSchedule {
  floor: Floor;
  schedules: Schedule[];
}

export interface Schedule {
  scheduleTime: string;
  lessonReservations: LessonReservations;
  coachNothing: boolean;
}

</script>

<style lang="scss" scoped>
.floor-schedule {
  display: flex;
  text-align: center;
  border-right: 1px solid #000000;
  border-bottom: 1px solid #000000;
  margin-top: 20px;
  width: calc(130px * 12 + 50px);

  .header {
    line-height: 30px;
    border-top: 1px solid #000000;
    border-left: 1px solid #000000;
  }

  .schedule-body {
    height: 40px;
    border-top: 1px solid #000000;
    border-left: 1px solid #000000;
    position: relative;
  }

  .time-block {
    flex: 0 0 50px;
    background-color: #EBEBEB;
  }

  .floor-block {
    flex: 0 0 130px;

    .header {
      color: #ffffff;
    }
  }
}

.exist-reservation {
  position: absolute;
  width: 100%;
  text-align: left;
  font-size: 11px;
  line-height: 13px;
  padding-top: 1px;
  background-color: #dddddd;
  height: 39px;
  z-index: 2;
}

.coach-nothing {
  text-align: left;
  font-size: 11px;
  line-height: 13px;
  padding-top: 1px;
  background-color: #dddddd;
  height: 39px;
}

.possible-reservation {
  color: #FF2F92;
  font-size: 26px;
  cursor: pointer;
}
</style>
