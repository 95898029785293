<template>
  <Base :errorMessage="errorMessage" :successMessage="successMessage" @resultSuccess="resultSuccess" >
    <section class="student-index">
      <div class="header">
        <h1>会員アカウント一覧</h1>
        <div class="button-block">
          <router-link class="new-button" :to="{ name: 'AdminStudentNew'}">新規登録</router-link>
          <label class="load-csv-button" >
            CSV読み込み
            <button type="file" @change="importCsvFile" class="load-csv-file" />
          </label>
        </div>
      </div>

      <div class="list-block" v-if="students.isPresent()">
        <table>
          <thead>
          <tr>
            <th>
              会員No
            </th>
            <th>
              氏名
            </th>
            <th>
              フリガナ
            </th>
            <th>
              住所
            </th>
            <th>
              電話番号1
            </th>
            <th>
              電話番号2
            </th>
            <th>
              性別
            </th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="student in students.list" :key="student.id.getId()">
            <td>
              <router-link :to="{ name: 'AdminStudentEdit', params: {studentId: student.id.getId()}}">
                {{ student.userId.getId() }}
              </router-link>
            </td>
            <td>
              {{ student.name }}
            </td>
            <td>
              {{ student.nameKana }}
            </td>
            <td>
              {{ student.address }}
            </td>
            <td>
              {{ student.tel1 }}
            </td>
            <td>
              {{ student.tel2 }}
            </td>
            <td>
              <span v-if="student.gender === 'male'">男性</span>
              <span v-if="student.gender === 'female'">女性</span>
            </td>
          </tr>
          </tbody>
        </table>
        <Pager :totalPage="students.totalPage" :currentPage="students.currentPage" @selectPage="changePage" class="pager"></Pager>
      </div>
      <div v-else>
        データが登録されていません。
      </div>
    </section>

    <div class="confirm-message-background" @click="cancel" v-show="loadCsvConfirm">
      <div class="confirm-message-dialog" @click="clickNothing">
        <div class="message">
          会員のデータとして登録するCSVデータをセットしてください。<br />
          既に同じ会員Noが登録されている場合は、CSVのデータで上書きします。
        </div>
        <div class="delete-select-block">
          <button type="file" @change="loadCsvFile"/>
        </div>
        <div class="button-block">
          <button type="button" >削除</button>
          <button type="button" @click="cancel">キャンセル</button>
        </div>
      </div>
    </div>
  </Base>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import Pager from "@/components/common/PagerComponent.vue";
import {Students} from "@/domains/student/Students";
import StudentsClient from "@/http_clients/admin/StudentsClient";
import {ResponseInterface} from "@/http_clients/ResponseInterface";

interface HTMLEvent<T extends EventTarget> extends Event {
  target: T;
}

export default defineComponent({
  name: 'StudentIndex',
  components: {
    Base,
    Pager
  },
  data() {
    return {
      errorMessage: '' as string,
      successMessage: '' as string,
      loadCsvConfirm: false as boolean,
      students: new Students([]) as Students,
    }
  },
  watch: {
    async $route() {
      let page = null;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page as string);
      }
      const studentsClient = new StudentsClient();
      this.students = await studentsClient.getList(page);
    }
  },
  created() {
    this.getStudents();
  },
  methods: {
    async changePage(page: number) {
      this.$router.push({query: { page: page } });
    },
    async importCsvFile(e: HTMLEvent<HTMLInputElement>) {
      if (e.target == null || e.target.files == null) {
        this.errorMessage = "ファイルを選択してください";
        return;
      }
      let file = e.target.files[0];
      const studentsClient = new StudentsClient();
      const response: ResponseInterface = await studentsClient.importCsv(file);
      if (response.result) {
        this.successMessage = "CSVのインポートを完了しました。";
      } else if (response.errorCode === "csv_import_error") {
        this.errorMessage = "会員Noが重複しているため、一部CSVのインポートが出来ませんでした。";
      } else {
        this.errorMessage = "CSVのインポートに失敗しました。";
      }
      this.getStudents();
    },
    cancel() {
      this.loadCsvConfirm = false;
    },
    clickNothing(e: PointerEvent) {
      e.stopPropagation();
    },
    resultSuccess() {
      this.successMessage = "";
    },
    async getStudents() {
      let page = null;
      if (this.$route.query.page) {
        page = parseInt(this.$route.query.page as string);
      }
      const studentsClient = new StudentsClient();
      this.students = await studentsClient.getList(page);
    }
  }
});
</script>

<style lang="scss" scoped>
.header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.button-block {
  display: flex;
  align-items: flex-end;
}

.new-button {
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 40px;
  text-decoration: none;
  color: #000000;
  margin-right: 20px;
}

.load-csv-button {
  background-color: #ffffff;
  border: 1px solid #000000;
  border-radius: 5px;
  cursor: pointer;
  padding: 5px 20px;
  text-decoration: none;
  color: #000000;
}

.load-csv-file {
  display: none;
}

.pager {
  width: 100%;
  margin-top: 20px;
}

.confirm-message-background {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.confirm-message-dialog {
  background-color: #ffffff;
  flex: 0 1 600px;
  margin: 40px;
  padding: 20px;
  border-radius: 10px;
}
</style>
