import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axiosPlugin from './plugins/axios'
import moment from 'moment';
import VCalendar from 'v-calendar';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { faSpinner, faBars } from '@fortawesome/free-solid-svg-icons'

moment.locale("ja", {
  weekdays: ["日曜日", "月曜日", "火曜日", "水曜日", "木曜日", "金曜日", "土曜日"],
  weekdaysShort: ["日", "月", "火", "水", "木", "金", "土"]
});

library.add(faSpinner, faBars)

createApp(App).use(store).use(router).use(axiosPlugin, router).use(VCalendar, {}).component('font-awesome-icon', FontAwesomeIcon ).mount('#app')
