import CoachUserId from "./value_object/CoachUserId";
import FloorGroupId from "@/domains/floor_group/value_object/FloorGroupId";
import CoachesClient from "@/http_clients/admin/CoachesClient";
import CoachId from "@/domains/coach/value_object/CoachId";
import {ResponseInterface} from "@/http_clients/ResponseInterface";

export class Coach {
  public id: CoachId | null;
  public userId: CoachUserId | null;
  public readonly password: string | null;
  public name: string | null;
  public nameKana: string | null;
  public floorGroupId: FloorGroupId | null;
  public floorGroupName: string | null;
  public floorGroupColor: string | null;
  public note: string | null;

  constructor(id: CoachId | null,
              userId: CoachUserId | null,
              password: string | null,
              name: string | null,
              nameKana: string | null,
              floorGroupId: FloorGroupId | null,
              floorGroupName: string | null,
              floorGroupColor: string | null,
              note: string | null) {
    this.id = id;
    this.userId = userId;
    this.password = password;
    this.name = name;
    this.nameKana = nameKana;
    this.floorGroupId = floorGroupId;
    this.floorGroupName = floorGroupName;
    this.floorGroupColor = floorGroupColor;
    this.note = note;
  }

  public async insert(): Promise<ResponseInterface> {
    const coachesClient: CoachesClient = new CoachesClient();
    return await coachesClient.insert(this);
  }

  public async update(): Promise<ResponseInterface> {
    const coachesClient: CoachesClient = new CoachesClient();
    return await coachesClient.update(this);
  }

  public async delete(): Promise<ResponseInterface> {
    const coachesClient: CoachesClient = new CoachesClient();
    return await coachesClient.delete(this);
  }

  public getFormData(): FormData {
    const formData: FormData = new FormData();

    if (this.userId != null) {
      formData.append("user_id", this.userId.getId().toString());
    }
    if (this.password != null) {
      formData.append("password", this.password);
    }
    if (this.name != null) {
      formData.append("name", this.name);
    }
    if (this.nameKana != null) {
      formData.append("name_kana", this.nameKana);
    }
    if (this.note != null) {
      formData.append("note", this.note);
    }
    if (this.floorGroupId != null) {
      formData.append("floor_group_id", this.floorGroupId.getId().toString());
    }
    return formData;
  }
}