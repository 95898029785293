<template>
  <section class="success-message" v-if="successMessage != null && successMessage !== ''">
    <div class="success-message-background" @click="ok">
      <div class="success-message-dialog" @click="clickNothing">
        <div class="message">
          {{ successMessage }}
        </div>
        <div class="button-block">
          <button type="button" @click="ok" >OK</button>
        </div>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  name: 'SuccessMessageComponent',
  props: {
    successMessage: String,
  },
  methods: {
    ok() {
      this.$emit('resultSuccess', true);
    },
    clickNothing(e: PointerEvent) {
      e.stopPropagation();
    }
  }
});
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
.success-message-background {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10;
}

.success-message-dialog {
  background-color: #ffffff;
  flex: 0 1 600px;
  margin: 40px;
  padding: 20px;
  border-radius: 10px;
}

.button-block {
  margin-top: 60px;

  display: flex;
  justify-content: center;
}
</style>
