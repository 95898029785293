import {ResponseInterface} from "@/http_clients/ResponseInterface";
import CoachId from "@/domains/coach/value_object/CoachId";
import moment from 'moment';
import LessonReservationId from "@/domains/lesson_reservation/value_object/LessonReservationId";
import StudentId from "@/domains/student/value_object/StudentId";
import FloorId from "@/domains/floor/value_object/FloorId";
import TrainingMenuId from "@/domains/training_menu/value_object/TrainingMenuId";
import LessonReservationsClient from "@/http_clients/admin/LessonReservationsClient";
import LessonReservationsByStudentClient from "@/http_clients/LessonReservationsClient";
import StudentUserId from "@/domains/student/value_object/StudentUserId";
import FloorGroupId from "@/domains/floor_group/value_object/FloorGroupId";

export class LessonReservation {

  public id: LessonReservationId | null;
  public member: boolean;
  public studentUserId: StudentUserId | null;
  public studentId: StudentId | null;
  public studentName: string | null;
  public studentNameKana: string | null;
  public studentTel: string | null;
  public studentAge: string | null;
  public floorId: FloorId | null;
  public floorName: string | null;
  public floorGroupId: FloorGroupId | null;
  public startDate: string | null;
  public startTime: string | null;
  public endTime: string | null;
  public coachId: CoachId | null;
  public coachName: string | null;
  public trainingMenuId1: TrainingMenuId | null;
  public trainingMenu1Name: string | null;
  public trainingMenu1Color: string | null;
  public trainingMenuId2: TrainingMenuId | null;
  public trainingMenu2Name: string | null;
  public trainingMenu2Color: string | null;

  public note: string | null;

  constructor(id: LessonReservationId | null,
              member: boolean,
              studentUserId: StudentUserId | null,
              studentId: StudentId | null,
              studentName: string | null,
              studentNameKana: string | null,
              studentTel: string | null,
              studentAge: string | null,
              floorId: FloorId | null,
              floorName: string | null,
              floorGroupId: FloorGroupId | null,
              startDate: string | null,
              startTime: string | null,
              endTime: string | null,
              coachId: CoachId | null,
              coachName: string | null,
              trainingMenuId1: TrainingMenuId | null,
              trainingMenu1Name: string | null,
              trainingMenu1Color: string | null,
              trainingMenuId2: TrainingMenuId | null,
              trainingMenu2Name: string | null,
              trainingMenu2Color: string | null,
              note: string | null) {
    this.id = id;
    this.member = member;
    this.studentUserId = studentUserId;
    this.studentId = studentId;
    this.studentName = studentName;
    this.studentNameKana = studentNameKana;
    this.studentTel = studentTel;
    this.studentAge = studentAge;
    this.floorId = floorId;
    this.floorName = floorName;
    this.floorGroupId = floorGroupId;
    this.startDate = startDate;
    this.startTime = startTime;
    this.endTime = endTime;
    this.coachId = coachId;
    this.coachName = coachName;
    this.trainingMenuId1 = trainingMenuId1;
    this.trainingMenu1Name = trainingMenu1Name;
    this.trainingMenu1Color = trainingMenu1Color;
    this.trainingMenuId2 = trainingMenuId2;
    this.trainingMenu2Name = trainingMenu2Name;
    this.trainingMenu2Color = trainingMenu2Color;
    this.note = note;
  }

  public calendarHeight(baseHeight: number): number {
    const startTimeMoment = moment(this.startTime, 'HH:mm');
    const endTimeMoment = moment(this.endTime, 'HH:mm');
    const diff = endTimeMoment.diff(startTimeMoment, 'minute');
    // 30分刻みで計算 後ろの1とか2は枠線のピクセル
    return diff / 30 * (baseHeight + 1) - 2;
  }

  public displayStartDate(): string {
    return moment(this.startDate).format('M月D日(ddd)');
  }
  public checkStudentEditReservation(): boolean {
    return moment().isBefore(moment(this.startDate));
  }

  public async insert(): Promise<ResponseInterface> {
    const lessonReservationsClient: LessonReservationsClient = new LessonReservationsClient();
    return await lessonReservationsClient.insert(this);
  }

  public async update(): Promise<ResponseInterface> {
    const lessonReservationsClient: LessonReservationsClient = new LessonReservationsClient();
    return await lessonReservationsClient.update(this);
  }

  public async delete(): Promise<ResponseInterface> {
    const lessonReservationsClient: LessonReservationsClient = new LessonReservationsClient();
    return await lessonReservationsClient.delete(this);
  }

  public async insertByStudent(): Promise<ResponseInterface> {
    const lessonReservationsClient: LessonReservationsByStudentClient = new LessonReservationsByStudentClient();
    return await lessonReservationsClient.insert(this);
  }

  public async updateByStudent(): Promise<ResponseInterface> {
    const lessonReservationsClient: LessonReservationsByStudentClient = new LessonReservationsByStudentClient();
    return await lessonReservationsClient.update(this);
  }

  public async deleteByStudent(): Promise<ResponseInterface> {
    const lessonReservationsClient: LessonReservationsByStudentClient = new LessonReservationsByStudentClient();
    return await lessonReservationsClient.delete(this);
  }

  public getFormData(): FormData {
    const formData: FormData = new FormData();

    if (this.member) {
      if (this.studentId != null) {
        formData.append("student_id", this.studentId.getId().toString());
      }
    } else {
      if (this.studentName != null) {
        formData.append("student_name", this.studentName);
      }
      if (this.studentTel != null) {
        formData.append("student_tel", this.studentTel);
      }
      if (this.studentAge != null) {
        formData.append("student_age", this.studentAge);
      }
    }
    if (this.floorId != null) {
      formData.append("floor_id", this.floorId.getId().toString());
    }
    if (this.startDate != null) {
      formData.append("start_date", moment(this.startDate).format());
    }
    if (this.startTime != null) {
      formData.append("start_time", moment(this.startTime, 'HH:mm').format("HH:mm:ssZ"));
    }
    if (this.endTime != null) {
      formData.append("end_time", moment(this.endTime, 'HH:mm').format("HH:mm:ssZ"));
    }
    if (this.coachId != null) {
      formData.append("coach_id", this.coachId.getId().toString());
    }
    if (this.trainingMenuId1 != null) {
      formData.append("training_menu_id1", this.trainingMenuId1.getId().toString());
    }
    if (this.trainingMenuId2 != null) {
      formData.append("training_menu_id2", this.trainingMenuId2.getId().toString());
    }
    if (this.note != null) {
      formData.append("note", this.note);
    }
    return formData;
  }
}
