<template>
  <Base :errorMessage="errorMessage" :confirmMessage="confirmMessage" :successMessage="successMessage"
        @resultConfirm="resultConfirm" @resultSuccess="resultSuccess" >
    <section class="admin-training-menus-edit">
      <div class="header">
        <h1>トレーニングメニュー編集</h1>
      </div>
      <form @submit.prevent="saveTrainingMenu">
        <TrainingMenusInput :trainingMenu="trainingMenu" @changeTrainingMenu="changeTrainingMenu"/>
        <div class="button-block">
          <button type="submit" class="approval-button" >更新</button>
          <button type="button" class="approval-button" @click="deleteTrainingMenu" >削除</button>
        </div>
      </form>
    </section>
  </Base>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import Base from '@/components/admin/common/BaseComponent.vue';
import {ResponseInterface} from "@/http_clients/ResponseInterface";
import {TrainingMenu} from "@/domains/training_menu/TrainingMenu";
import TrainingMenusInput from "@/components/admin/training_menus/InputComponent.vue";
import TrainingMenusClient from "@/http_clients/admin/TrainingMenusClient";
import TrainingMenuId from "@/domains/training_menu/value_object/TrainingMenuId";

export default defineComponent({
  name: 'TrainingMenuEdit',
  components: {
    Base,
    TrainingMenusInput,
  },
  data() {
    return {
      errorMessage: null as string | null,
      confirmMessage: null as string | null,
      successMessage: null as string | null,
      trainingMenu: new TrainingMenu(null, null,null,null,null,null,null) as TrainingMenu,
    }
  },
  async created() {
    const trainingMenusClient = new TrainingMenusClient();
    this.trainingMenu = await trainingMenusClient.getData(new TrainingMenuId(parseInt(this.$route.params.trainingMenuId as string)));
  },
  methods: {
    changeTrainingMenu(trainingMenu: TrainingMenu) {
      this.trainingMenu = trainingMenu;
    },
    async saveTrainingMenu() {
      const response: ResponseInterface = await this.trainingMenu.update();
      if (response.result) {
        this.successMessage = "更新を完了しました。";
      } else {
        this.errorMessage = "トレーニングメニューの更新に失敗しました。";
      }
    },
    deleteTrainingMenu() {
      this.confirmMessage = "削除してもよろしいですか？";
    },
    async resultConfirm(result: boolean) {
      if (result) {
        const response: ResponseInterface = await this.trainingMenu.delete();
        this.confirmMessage = null;
        if (response.result) {
          this.successMessage = "削除を完了しました。";
        } else {
          this.errorMessage = "トレーニングメニューの削除に失敗しました。";
        }
      } else {
        this.confirmMessage = null;
      }
    },
    resultSuccess() {
      this.$router.push('/admin/training_menus');
    },
  }
});
</script>

<style lang="scss" scoped>
.admin-training-menus-edit {
  max-width: 600px;
}

.button-block {
  margin-top: 60px;

  display: flex;
  justify-content: center;

  button {
    margin: 0 40px;
  }
}
</style>
